.assignment-salesText {
    margin-top: 2rem;
    margin-bottom: 0.8rem;
    font-family: Montserrat;
}

.assignment-salesText h1 {
    font-size: 3rem;
    line-height: 120%;
    margin-bottom: 14px;
    font-weight: 700;
}

.assignment-salesText p {
    font-size: 1.3rem;
}

.category_assignemtnDes p {
    margin: 36px 0 0 0 !important;
}

.city_categoryyBtn_container {
    display: flex;
    gap: 8px;
    align-items: center;
    margin-right: 10px;
}

.cityCategory_Btn {
    display: flex;
    align-items: center;
    gap: 5px;
    outline: none;
    border: 1px solid #02549e !important;
    color: #02549e !important;
    font-size: 16px !important;
    font-weight: 600 !important;
    cursor: pointer;
    background-color: transparent !important;
    padding: 0.5rem 0.9rem !important;
    font-family: "Montserrat" !important;
    text-transform: capitalize !important;
    border-radius: 8px !important;
    line-height: 1.5 !important;
    letter-spacing: .012rem !important;
}

.cityCategoryFilter_Btn {
    background-color: #02549e !important;
    color: #fff !important;
    padding: 0.5rem 0.8rem !important;
    ;
}



@media(max-width:768px) {
    .assignment-salesText h1 {
        font-size: 2rem;
    }

    .assignment-salesText p {
        font-size: 14px;
    }

}


@media(max-width:800px) {
    .category_resultAndBtn {
        flex-direction: column-reverse;
        align-items: flex-start !important;
        gap: 15px;
    }

    .city_categoryyBtn_container {
        justify-content: space-around !important;
        width: 100%;
        margin-right: 0;
    }

    .cityCategory_Btn {
        padding: 7px 42px !important;
        letter-spacing: normal !important;
        font-family: inherit !important;
        gap: 2px !important;
    }

    .cityCategoryFilter_Btn {
        padding: 7px 37px !important;
    }
}

@media only screen and (min-width: 800px) and (max-width: 1100px) {
    .cityCategory_results {
        font-size: 20px !important;
    }

}