/* main.css */
/* Add this to your main.css or a relevant CSS file */

.flex-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    /* Align items at the start */
    gap: 10px;
    /* Space between cards */
}

.card-stats {
    border: 1px solid black;
    border-radius: 8px;
    padding: .5rem 1.5rem;
    flex: 1;
    /* Allows the card to grow */
    margin: 5px;
    height: unset !important;
    /* Margin around each card */
}

.me-5 {
    margin-right: 10% !important;
}


.br-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 1rem;
}


.br-filterconntainer {
    display: flex;
    align-items: center;
    justify-content: center;
}

.br-datebtn {
    display: flex;
    gap: 0.5rem;
    margin-right: 0.5rem;
}

.br-datebtn button {
    border-radius: 6px !important;
    padding: 0.625rem !important;
}

.br-Statusbuttons {
    display: flex;
    gap: 1rem;
    flex-wrap: wrap;
}

.Activebr-StatusBtn {
    padding: 0.75rem 1rem;
    border-radius: 42px;
    border: 1px solid #02549E;
    background: #02549E;
    color: #fff;
    font-family: "Montserrat";
    font-size: 1rem;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: -0.32px;
}

.br_statusBtn {
    padding: 0.75rem 1rem;
    border-radius: 42px;
    border: 1px solid #D9D9D9;
    background: #FFF;
    color: #02549E;
    font-family: "Montserrat";
    font-size: 1rem;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: -0.32px;
}

.br_statusBtn:hover {
    border: 1px solid #02549E;
    background: #02549E;
    color: #fff;
}

.br_ActivestatusBtn {
    padding: 0.75rem 1rem;
    border-radius: 42px;
    border: 1px solid #02549E;
    background: #02549E;
    color: #fff;
    font-family: "Montserrat";
    font-size: 1rem;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: -0.32px;
}

.br_ActivestatusBtn:hover {
    border: 1px solid #02549E;
    background: #02549E;
    color: #fff;
}

.br_card {
    padding: 1.25rem;
    border-radius: 12px;
    background: #F5F5F5;
    font-family: "Montserrat";
    max-width: 32%;
    min-width: 350px;
    width: 100%;
    min-height: 35.063rem;
    max-height: 35.063rem;
    height: 100%;
}

.br-Id {
    border-radius: 100px;
    background: #02549E;
    padding: 4px 10px;
    color: #FFF;
    border: none;
    outline: none;
    font-size: 0..75rem;
    font-weight: 700;
}

.br-cardProjectname {
    color: #02549E;
    font-size: 1.5rem;
    font-weight: 700;
    margin-top: 0.8rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.br-cardProjectname:hover {
    text-decoration: underline;
    cursor: pointer;
}

.br-cardPrice {
    color: #121212;
    font-size: 1.5rem;
    font-weight: 700;
}

.br-cardArea {
    color: #121212;
    font-size: 1rem;
    font-weight: 500;
}

.br-highlightText {
    color: #02549E;
    font-size: 0.75rem;
    font-weight: 600;
}

.br-cardMessage {
    display: flex;
    height: 7.688rem;
    padding: 10px;
    border-radius: 6px;
    background: #FFF;
    overflow-y: auto;
}

.br-cardMessage::-webkit-scrollbar {
    width: 2px;
    background-color: rgb(255, 255, 255);
}

.br-cardMessage::-webkit-scrollbar-thumb {
    background-color: #929292;
    border-radius: 6px;
}

.br-cardMessage p {
    color: #676767;
    font-size: 0.75rem;
    font-weight: 400;
}

.br-dltandPending {
    display: flex;
    gap: 1rem;
    font-family: Montserrat;
}

.br-dltBtn {
    outline: none;
    max-width: 38%;
    width: 100%;
    border-radius: 6px;
    border: 1px solid #000;
    background: #FFF;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.75rem;
    color: #000;
    font-size: 1rem;
    font-weight: 600;
    padding: 10px;
}

.br-dltBtn:hover {
    background: #e9e9e9;
}

.br-StatusBtnP {
    max-width: 60%;
    width: 100%;
}

.br-requestStatusBtn {
    color: white;
    display: flex;
    justify-content: space-between;
    font-family: Montserrat;
    font: 1rem;
    border-radius: 6px;
    padding: 10px;
    width: 100%;
}

.br-CardContainer {
    display: flex;
    justify-content: flex-start;
    gap: 1rem;
    margin-top: 1rem;
    flex-wrap: wrap;
    margin-bottom: 1rem;
}

.br-searchInputAndTitle {
    width: 360px;
}


@media(max-width: 1200px) {
    .br-container {
        flex-direction: column;
        align-items: flex-start;
    }

    .br-filterconntainer {
        margin-top: 1.5rem;
        width: 100%;
    }

    .br-filterconntainer {
        justify-content: flex-start;
    }
}

/* Responsive adjustments for smaller screens */
@media (max-width: 768px) {
    .pageheight {
        margin: 0 !important;
    }

    .mobileresponsive_img {
        width: 20px !important;
    }

    .fontmobile13 {
        font-size: 13px;
    }

    .me-5 {
        margin-right: 0 !important;
    }

    .card-stats {
        flex-basis: calc(50% - 20px);
        /* Adjust the card width to be half of the container width minus the gap */
        max-width: calc(50%) !important;
        margin: 0;
        font-size: 13px;
        /* Ensure card-stats does not grow beyond calculated width */
    }

    .br-mobile4remMarginTop {
        margin-top: 4rem;
    }

    .br-page-content {
        max-width: 100%;
        width: 100%;
        left: 0 !important;
        margin-left: auto !important;
        margin-right: auto !important;
    }

    .br-filterconntainer {
        flex-direction: column-reverse;
        align-items: flex-start;
        gap: 0.7rem;
        margin-top: 1.5rem;
        width: 100%;
    }

    .br-CardContainer {
        gap: 0rem;
        justify-content: center;
    }

    .br_card {
        min-width: 96%;
        max-width: 96%;
        width: 96%;
        margin-bottom: 1rem;
    }

    .br-datebtn button {
        border-color: rgb(2, 84, 158);
    }

    .br_serachSelect {
        width: 20%;
    }

    .br_serachSelect {
        width: 80%;
    }

    .br-searchInputAndTitle {
        width: 100%;
    }
}


@media (max-width: 578px) {

    .br-page-content {
        max-width: 100%;
    }

    .br-Statusbuttons {
        gap: 0.4rem;
    }

    .br_statusBtn {
        font-size: .8rem;
    }

    .Activebr-StatusBtn {
        font-size: .8rem;
    }

    .br_ActivestatusBtn {
        font-size: .8rem;
    }
}