.Custom_categoryTabs .css-heg063-MuiTabs-flexContainer {
    justify-content: flex-start !important;
}

.Custom_categoryTabs .css-k008qs {
    justify-content: flex-start !important;
}

.Custom_categoryTabs .css-1q2h7u5 {
    text-transform: capitalize !important;
}



.Custom_categoryTabs .css-1q2h7u5.Mui-selected {
    font-weight: bold !important;
    background-color: transparent !important;
    color: #015aad !important;
}


.Custom_categoryTabs .css-1h9z7r5-MuiButtonBase-root-MuiTab-root {
    text-transform: capitalize !important;
}

.Custom_categoryTabs .css-1h9z7r5-MuiButtonBase-root-MuiTab-root.Mui-selected {
    color: #015aad !important;
    font-weight: 800 !important;
    background-color: transparent !important;
}

@media (max-width: 800px) {
    .Custom_categoryTabs .css-1h9z7r5-MuiButtonBase-root-MuiTab-root {
        margin-right: 0rem !important;
        margin-bottom: 0rem !important;
        border-radius: 0px !important;
    }
    .Custom_categoryTabs .MuiTabs-scrollButtons  {
        margin-right: 0rem !important;
        margin-bottom: 0rem !important;
        border-radius: 0px !important;
    }

    .Custom_categoryTabs .Custom_categoryTabs .css-1q2h7u5.Mui-selected {
        font-weight: bold !important;
        background-color: transparent !important;
        color: #015aad !important;
    }

    .Custom_categoryTabs .css-1q2h7u5 {
        text-transform: capitalize !important;
        margin-right: 0rem !important;
        margin-bottom: 0rem !important;
        border-radius: 0px !important;
    }
    .Custom_categoryTabs .css-1q0ig8p {
        margin-right: 0rem !important;
        margin-bottom: 0rem !important;
    }


}