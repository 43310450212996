
.no__FoundMain{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding-top: 30px;
    padding-bottom: 30px;
}

.no__Found__img img{
    width:100%;
    height:180px;
}

.no__FoundText{
    padding-top: 15px;
}

.no__FoundText p{
    font-weight: bold;
    text-align: center;
}

.no__FoundButton{
    background-color: #02549e;
    color: white;
    font-weight: 600;
    border: none;
    border-radius: 30px;
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 5px;
    padding-bottom: 5px;
}
.no__FoundButton:hover{
    background-color: #034c8d;
}
