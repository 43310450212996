.pageheightborder {
    min-height: 75vh;
    border-radius: 20px;
    border: 1px solid #dedede;
}

.pageheightborder {
    min-height: 75vh;
    border-radius: 20px;
}

.table>:not(caption)>*>* {
    padding: 6px .5rem;
}

.headingtitlelist {
    padding: 20px;
}

.headingtitlelister {
    padding: 0px;
    /* padding-left: 0px !important; */
}

.listingImg {
    width: 150px;
    height: 100px;
    border-radius: 5px;
}

.dashboard_listing_margin {
    margin-top: 1rem;
}

.custom-select-Dashboard {
    border-radius: 5px;
    padding: 10px;
    font-family: 'Montserrat';
    border-color: #dedede;
}

.ButtonRadius {
    border-radius: 5px;
    font-size: 13px;
}

.DropDownMenuFont {
    font-size: 14px;
    min-width: 80px;
}

.webvisibility {
    display: none;
}

.ListingcardButton {
    background: #fff;
    color: #000;
    padding: 5px 25px;
    border-radius: 18px;
    border: 1px solid #000;
}

.ListingcardButton:hover {
    background: black;
    color: white;
}

.listing_status_btn .btn.show {
    background: black !important;
    color: white;
}

.font10 {
    font-size: 11px !important;
}

td,
th {
    text-align: center;
    align-items: center;
}

.displayBlock {
    display: block !important;
}

.webvisibility_None {
    display: none;
}

.webvisibility_at_1130 {
    display: none;
}

.listing_btns {
    justify-content: center;
}

.mob_favAndViews {
    display: none;
}

.listing_pkgAllCard {
    gap: 32px;
}

.listing_card_pkg {
    border-radius: 12px;
    background: #F6F6F6;
    padding: 16px 20px;
    display: flex;
    width: calc((100% - 32px) /2);
}

.listPkg_name {
    color: #7E7E7E;
    font-size: 16px;
    font-weight: 700;
    line-height: normal;
    margin-bottom: .5rem !important;
}

.listPkg_exp {
    color: #676767;
    font-size: 14px;
    font-weight: 600;
    line-height: normal;
    text-transform: capitalize;
    margin: 0;
}

.Bump_modal_body {
    padding: 40px;
}

.BumpAdd_heading {
    font-size: 32px;
}

.bumpAdd_closeIcon {
    background-color: #F5F5F5;
    border-radius: 50%;
    padding: 0.5rem;
    color: black;
    height: fit-content;
    cursor: pointer;
    display: flex;
    align-items: center;
}

.bumpAdd_closeIcon:hover {
    background-color: #eceaea;
}

.BumpAdd_modal .modal-dialog {
    max-width: 650px !important;
}


.payNow_btn {
    border-radius: 10px;
    background: #02549E;
    padding: 12px 0;
    width: 230px;
    text-align: center;
    color: #ffff;
    font-size: 16px;
    font-weight: 600;
    outline: none;
    border: none;
}

.cardPay_head {
    font-size: 14px;
    font-weight: 400;
    color: #344054;
    margin-bottom: 0.2rem !important;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.Pay_text_field {
    width: calc((100% - 32px)/2);
}


.Pay_textAll_field {
    column-gap: 32px;
    row-gap: 16px;
    margin-top: 2rem;
}

.PayLater_cursorNone {
    cursor: not-allowed !important;
}

.myListingStatus_mbl {
    display: none !important;
}

.mob_listingMessages {
    display: none;
}

.userPkgsListing_btn {
    gap: 15px;
}

.no_data_Text {
    font-size: 20px;
}

.no_data_Text a {
    color: #02549E;
    text-decoration: underline;
}

.no_data_btn {
    outline: none;
    border: none;
    background-color: #02549E;
    color: #fff;
    border-radius: 4px;
    cursor: pointer;
    padding: 15px 32px;
}

.PricingChangeModal_Chip {
    border: none;
    outline: none;
    border-radius: 16px;
    background: var(--Blue-50, #EFF8FF);
    padding: 2px 8px;
    color: var(--Blue-700, #175CD3);
    text-align: center;
    font-family: Montserrat;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px;
}

.PayModal_StripeInput {
    border: 1px solid #e6e6e6;
    padding: 10px;
    border-radius: 4px;
    display: block;
    outline: none;
    width: 100%;
    color: #4A4A4A;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.03), 0px 3px 6px rgba(0, 0, 0, 0.02);;
}

.PayModal_StripeInput::placeholder{
    color: #aab7c4;
}

.PayModal_StripeInput:focus{
    transition: background 0.15s ease, border 0.15s ease, box-shadow 0.15s ease, color 0.15s ease;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.03), 0px 3px 6px rgba(0, 0, 0, 0.02), 0 0 0 3px hsla(210, 96%, 45%, 25%), 0 1px 1px 0 rgba(0, 0, 0, 0.08);;
    border-color: #0570de;
}


@media (max-width: 1130px) {
    .dashboard_listing_margin {
        margin-top: 1rem;
    }

    .webvisibility_at_1130 {
        display: flex;
    }

    .margin_Top_at_1130 {
        margin-top: 3rem;
    }

    .mobile_width_100 {
        width: 100%;
    }

    .Mobile-justify-content-between {
        display: flex;
        justify-content: space-between;
        width: 100%;
    }
}

@media (max-width:1090px) {
    .listing_pkgAllCard {
        gap: 16px;
    }

    .listing_btns {
        gap: 12px;
    }

    .listing_card_pkg {
        width: 100%
    }
}

@media (max-width:1070px) {
    .listing_btns_mobileView {
        margin: 0 auto !important;
    }
}

@media (max-width:990px) {
    .edit_icon_mobile {
        display: none;
    }

    .mobile_remMarginTop {
        margin-top: 4rem;
    }

    .FavAndView_mobNone {
        display: none;
    }

    .mob_favAndViews {
        display: block;
        border-top: 1px solid #d6d6d6;
        border-bottom: 1px solid #d6d6d6;
        margin: 1rem 0rem 0.7rem 0rem;
        width: 100%;
        padding: 7px;
        text-align: center;
    }

    .myListingStatus_web {
        display: none !important;
    }

    .myListingStatus_mbl {
        display: flex !important;
    }

    .mob_listingMessages {
        display: block;
    }
}

@media (max-width:800px) {
    .no_data_Text {
        font-size: 14px;
    }

    .no_data_btn {
        font-size: 14px;
        padding: 10px 20px;
    }
}


@media (max-width:786px) {
    .my_listing_mobContent {
        left: 0 !important;
        width: 100% !important;
        margin: 0 !important;
    }

    .BumpAdd_modal {
        padding: 0 !important;
    }

    .BumpAdd_heading {
        font-size: 24px;
    }

    .BumpAdd_modal .modal-dialog {
        max-width: 100% !important;
        margin-left: 0 !important;
        margin-right: 0 !important;
    }

    .Bump_modal_body {
        min-height: 100vh;
    }

    .mobile_listing_MarginTop {
        margin-top: 4rem;
    }
}

@media (max-width: 768px) {

    .dashboard_listing_margin {
        margin-top: 1rem;
    }

    .mobileresponsive {
        font-size: 13px !important;
    }

    .webvisibility {
        display: flex;
        justify-content: center;
    }

    .webvisibility_None {
        display: flex;
        justify-content: flex-start;
    }

    .pageheightborder {
        border: none;
    }

    .headingtitlelist {
        display: none;
    }

    .page-content {
        padding: 1px 12px;
    }

    .myListing_main_hedaing {
        margin-top: 4rem !important;
    }
}

@media (max-width: 525px) {
    .dashboard_listing_margin {
        margin-top: 0.2rem;
    }

    .btnpadding {
        padding: 5px 24px !important;
    }

    .mobileresponsive {
        justify-content: center;
    }



    /* .mt-4 {
        margin-top: 10px !important;
    } */

    /* p {
        margin-bottom: 10px !important;
    } */

    .flex-column {
        word-break: normal !important;
    }

    .listing-container {
        min-height: 8vh !important
    }

    .listingImg {
        width: 105px;
        height: 90px;
    }

    .btnand_TotalPrice {
        margin-top: 1.5rem;
        flex-direction: column !important;
        gap: 10px;
    }

    .payNow_btn {
        width: 80% !important;
    }

    .BumpAdd_Text {
        font-weight: 600 !important;
    }

    .totalPrice_pkg {
        font-size: 20px !important;
        font-weight: 700 !important;
    }

    .Pay_text_field {
        width: 100%;
    }

    .cardNumPay {
        width: 100% !important;
    }

    .Pay_textAll_field {
        gap: 16px;
        margin-top: 1rem;
    }

    .Bump_modal_body {
        padding: 48px 16px;
    }

    .creditPay_btn {
        width: 230px !important;
    }
}

@media (max-width: 440px) {
    .dashboard_listing_margin {
        margin-top: 0.2rem;
    }

    .flex-column-dir {
        flex-direction: column !important;
        width: 65% !important;
        word-break: break-all !important;
    }
}

@media (max-width: 320px) {
    h2 {
        font-size: 17px !important;
    }

    .listing-container {
        margin: -10px 0 -24px 0px !important;
        min-height: 15vh !important
    }

    .filter-conntainer {
        margin-bottom: 20px !important;
    }

    .mb-4 {
        margin-bottom: 10px !important;
    }

    .btnpadding {
        padding: 5px !important;
    }
}