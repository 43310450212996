/* RESET RULES
–––––––––––––––––––––––––––––––––––––––––––––––––– */
@import url("https://fonts.googleapis.com/css?family=Montserrat:400,700&display=swap");

:root {
  --page-header-bgColor: white;
  --page-header-bgColor-hover: #0253a0;
  --page-header-txtColor: #676767;
  --page-header-headingColor: #7889a4;
  --page-header-width: 270px;
  --page-content-bgColor: #fff;
  --page-content-txtColor: black;
  --page-content-blockColor: #fff;
  --white: #fff;
  --black: #333;
  --blue: #fff;
  --red: #ec1848;
  --border-radius: 4px;
  --box-shadow: 0 0 10px -2px rgba(0, 0, 0, 0.075);
  --switch-bgLightModeColor: #87cefa;
  --switch-sunColor: gold;
  --switch-moonColor: #f4f4f4;
  --switch-bgDarkModeColor: #1f1f27;
  font-family: Montserrat;

  --font-montserrat: Montserrat;
  --font-size-3xs: 10px;
  --font-size-2xs: 11px;
  --font-size-sm: 14px;
  /* Colors */
  --color-white: #fff;
  --color-darkslateblue-100: #02549e;
  --color-darkslateblue-200: #0253a0;
  --color-indigo: #6c35a3;
  --color-crimson: #ca0024;
  --color-darkslategray: #333;
  --color-gray-100: #8b8b8b;
  --color-black: #000;
  --color-red: #ff0000;
  --color-gainsboro: #e2e2e2;

  /* Paddings */
  --padding-3xs: 10px;
  --padding-9xs: 4px;

  /* border radiuses */
  --br-8xs: 5px;
  --br-81xl: 100px;
}

ul {
  list-style: none;
}

a,
button {
  color: inherit;
}

a {
  text-decoration: none;
}

.color-Active {
  font-weight: bold;
  color: #02c252;
}

.text-shadow-black {
  text-shadow: 0 0 #000;
}

.bg-Active {
  font-weight: bold;
  background-color: #e4fff0;
  color: #121212;
  cursor: pointer;
}

.bg-Pending {
  color: #121212;
  font-weight: bold;
  background-color: #fffce3;
  cursor: pointer;
}

.color-Pending {
  color: #cab600;
  font-weight: bold;
}

.bg-Suspend {
  color: #121212;
  background-color: #ff7890;
  font-weight: bold;
  cursor: pointer;
}

.color-Suspend {
  color: #ff4d6d;
  font-weight: bold;
}

.card-bg-primary {
  background: deepskyblue;
  color: white;
  font-weight: bold;
}

.heading {
  height: 0;
  font-weight: bold;
  color: black;
}

/* HEADER STYLES
–––––––––––––––––––––––––––––––––––––––––––––––––– */
.page-header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  /* bottom: 0; */
  /* overflow: auto; */
  padding-top: 20px;
  width: var(--page-header-width);
  color: var(--page-header-txtColor);
  background: var(--page-header-bgColor);
  border: 2px solid #d9d9d9;
  min-height: 100vh;
  border-top: none;
}

.custom-cursor {
  cursor: pointer;
}

/*In case you prefer an absolutely positioned header that covers the full page height, add these styles*/
/*body {
  position: relative;
}

.page-header {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
}*/

/*remove these styles*/
/*.page-header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: auto;
}*/

.page-header nav {
  display: flex;
  flex-direction: column;
  /* min-height: 100%; */
  height: 100vh;
  overflow-y: auto;
  overflow-x: hidden;
}


.page-header nav::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 4px rgba(129, 129, 129, 0.3);
	border-radius: 4px;
	background-color: #F5F5F5;
}

.page-header nav::-webkit-scrollbar
{
	width: 8px;
	background-color: #F5F5F5;
}

.page-header nav::-webkit-scrollbar-thumb
{
	border-radius: 8px;
	-webkit-box-shadow: inset 0 0 4px rgba(116, 116, 116, 0.3);
	background-color: #b6b6b6;
}

.page-header .logo {
  display: flex;
  justify-content: center;
  height: 82px;
}

.page-header .logo svg {
  max-width: 120px;
  fill: var(--white);
}

.page-header .toggle-mob-menu {
  display: none;
  margin-left: 5px;
  padding: 4px;
  background: var(--page-content-blockColor);
  border-radius: var(--border-radius);
}

.form-control:focus {
  box-shadow: none;
  border-color: #ced4da;
  /* Set the border color to the default color or your desired color */
}

.page-header .toggle-mob-menu svg {
  fill: var(--black);
  transition: transform 0.2s;
}

.page-header .admin-menu {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  margin-top: 20px;
}

.disable-link {
  outline: none;
  width: 96%;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  /* margin-top: 20px; */
  /* display: flex; */
  flex-direction: row;
  width: 96%;
  background: #0000002e !important;
  color: black !important;
  padding: 12px 15px;
  align-items: center;
}

.page-header .admin-menu li>* {
  width: 96%;
  padding: 12px 15px;
}

.page-header .admin-menu .switcher {
  display: inline-block;
  width: auto;
}

.page-header .admin-menu .menu-heading h3 {
  text-transform: uppercase;
  letter-spacing: 0.15em;
  font-size: 12px;
  margin-top: 12px;
  color: var(--page-header-headingColor);
}

.page-header .admin-menu svg {
  width: 20px;
  height: 20px;
  fill: var(--page-header-txtColor);
  margin-right: 10px;
}

.page-header .admin-menu a,
.page-header .admin-menu button {
  display: flex;
  align-items: center;
  font-size: 0.9rem;
}

.page-header .admin-menu a:hover,
.page-header .admin-menu a:focus {
  background: var(--page-header-bgColor-hover);
  color: var(--blue);
  outline: none;
  width: 96%;
  border-radius: 8px;
}

.page-header .admin-menu a.active {
  background: var(--page-header-bgColor-hover);
  color: var(--blue);
  outline: none;
  width: 96%;
  border-radius: 8px;
}

.page-header .admin-menu a:hover svg,
.page-header .admin-menu a:focus svg,
.page-header .admin-menu button:hover svg,
.page-header .admin-menu button:focus svg {
  fill: var(--blue);
}

.page-header .admin-menu a.active svg {
  fill: white;
  /* Set the SVG fill color to white on active */
}

.activeSideBar_listingsa{
  background: var(--page-header-bgColor-hover);
  color: var(--blue);
  outline: none;
  width: 96%;
  border-radius: 8px;
}

.activeSideBar_listingsa svg{
  fill: white !important;
}

.lineHight20px {
  line-height: 20px;
}

/* PAGE CONTENT STYLES
–––––––––––––––––––––––––––––––––––––––––––––––––– */
.page-content {
  position: relative;
  font-family: "Montserrat";
  left: var(--page-header-width);
  width: calc(100% - var(--page-header-width));
  padding-left: 25px;
  padding-right: 25px;
  color: var(--page-content-txtColor);
  background: var(--page-content-bgColor);
}

.search-and-user {
  display: grid;
  grid-template-columns: 1fr auto;
  grid-column-gap: 50px;
  align-items: center;
  background: var(--page-content-bgColor);
  margin-bottom: 30px;
}

.search-and-user form {
  position: relative;
  width: 35%;
  color: #dedede;
  border: 2px solid #dedede;
  height: 3rem;
}

.navheight {
  height: 65px;
  border-bottom: 2px solid #dedede;
}

.search-and-user [type="search"] {
  width: 100%;
  height: 50px;
  font-size: 1.5rem;
  padding-left: 15px;
  background: var(--page-content-blockColor);
  color: var(--black);
  border-radius: var(--border-radius);
  border: 2px solid #d9d9d9;
}

.search-and-user ::-moz-placeholder {
  color: var(--page-content-txtColor);
}

.search-and-user :-ms-input-placeholder {
  color: var(--page-content-txtColor);
}

.search-and-user ::placeholder {
  color: var(--page-content-txtColor);
}

.search-and-user form svg {
  width: 26px;
  height: 26px;
  fill: var(--page-content-txtColor);
}

.search-and-user form button {
  position: absolute;
  top: 50%;
  right: 15px;
  transform: translateY(-50%);
}

.search-and-user {
  display: flex;
  align-items: center;
}

.search-and-user .greeting {
  margin: 0 10px 0 20px;
}

.search-and-user .svg {
  width: 30px;
  height: 30px;
}

.search-and-user .notifications {
  position: relative;
}

.react-bootstrap-table th,
.react-bootstrap-table td {
  border-color: transparent !important;
  /* Set border color to transparent */
}

.react-bootstrap-table table {
  border: 1px solid #ccc;
  /* Add a 1px solid border with color #ccc */
}

.search-and-user .badge {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: -10px;
  right: -3px;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  font-size: 10px;
  color: var(--white);
  background: var(--red);
}

.page-content .grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 30px;
}

.page-content .grid>article {
  display: flex;
  height: 300px;
  background: var(--page-content-blockColor);
  border-radius: var(--border-radius);
  box-shadow: var(--box-shadow);
  border: 2px solid #dedede;
}

.page-content .grid>article:first-child,
.page-content .grid>article:last-child {
  grid-column: 1 / -1;
}

.brodernone {
  border: none;
}

.admin-profile {
  margin: 0 2rem;
}

.imgbackground {
  background: none !important;
}

.mobile4remMarginTop {
  margin-top: 8px !important;
}

.listing-container {
  /* border: 1px solid #dedede; */
  display: flex;
  justify-content: space-between;
  min-height: 15vh;
  align-items: center;
  border-left-color: transparent;
  border-right-color: transparent;
}

.listing-containers {
  /* border: 1px solid #dedede; */
  display: flex;
  justify-content: space-between;
  min-height: 11vh;
  align-items: center;
  border-left-color: transparent;
  border-right-color: transparent;
}

.searchbox {
  border: 1px solid #dedede;
  border-radius: 8px;
}

.filter-conntainer {
  padding: 20px;
  display: flex;
  justify-content: flex-end;
}

.headingtitle {
  line-height: 15px;
}

.react-bootstrap-table-pagination-total {
  padding: 20px;
  padding-bottom: 0;
  display: flex;
}

.react-bootstrap-table-pagination {
  margin-top: 15px;
}

.footer {
  margin-top: 5vh;
  /* display: table-cell; */
  position: absolute;
  width: 150%;
  left: -62vh;
  margin-left: 5rem;
}

.pageheight {
  min-height: 100vh;
}

.custom-table .selection-cell-header {
  width: 3px;
}

.custom-table th,
.custom-table td,
.custom-table tr {
  white-space: nowrap;
  font-size: 17px;
}

/* Adjust font size for other elements if needed */
.custom-table .pagination {
  font-size: 12px;
}

.pagination {
  padding: 0 20px;
  /* Adjust the padding value as needed */
}

.custom-table {
  min-height: 50vh;
}

.custom-table-container {
  overflow-x: auto;

  /* Adds horizontal scrolling when needed */
}

.mobilevisibility_at_990 {
  display: none;
}

@media (max-width: 768px) {
  :root {
    --page-header-bgColor: white;
    --page-header-bgColor-hover: #0253a0;
    --page-header-txtColor: #676767;
    --page-header-headingColor: #7889a4;
    --page-header-width: 0;
    --page-content-bgColor: #fff;
    --page-content-txtColor: black;
    --page-content-blockColor: #fff;
    --white: #fff;
    --black: #333;
    --blue: #fff;
    --red: #ec1848;
    --border-radius: 4px;
    --box-shadow: 0 0 10px -2px rgba(0, 0, 0, 0.075);
    --switch-bgLightModeColor: #87cefa;
    --switch-sunColor: gold;
    --switch-moonColor: #f4f4f4;
    --switch-bgDarkModeColor: #1f1f27;
    font-family: Montserrat;
    margin-top: 4rem;
  }

  .mobileTopMargin {
    margin-top: 1rem;
  }

  .mobile4remMarginTop {
    margin-top: 4rem !important;
  }

  .not_found_Text_mobile {
    font-size: 13px !important;
    text-wrap: balance !important;
  }

  .not_found_img_mobile {
    height: 120px !important;
  }

  h3 {
    font-size: 18px !important;
  }

  .mobilevisibility {
    display: none;
    width: 0;
    height: 0;
  }

  .font13 {
    font-size: 13px !important;
    font-family: Montserrat;
  }

  .Mobilefont13 {
    font-size: 13px !important;
    font-family: Montserrat;
  }

  .Mobilefont17 {
    font-size: 17px !important;
    font-family: Montserrat;
  }

  .column {
    float: unset;
    width: 60% !important;
    padding: unset;
  }

  .page-content {
    position: relative;
    font-family: "Montserrat";
    left: var(--page-header-width);
    width: calc(100% - var(--page-header-width));
    padding: 12px;
    color: var(--page-content-txtColor);
    background: var(--page-content-bgColor);
  }

  .filter-conntainer {
    all: unset;
    width: 100%;
  }

  .searchbox {
    border: 1px solid #dedede;
    border-radius: 8px;
    /* margin-bottom: 20px; */
    display: flex;
  }

  .footer {
    margin-top: 5vh;
    display: unset;
    position: unset;
    width: unset;
    left: unset;
    margin-left: unset;
    font-size: small;
    font-family: Montserrat;
  }

  .font-family-regular {
    font-family: Montserrat;
  }
}

@media (max-width: 990px) {
  .tab_justifyContent_between_at_990 {
    display: flex;
    justify-content: space-between;
  }

  .mobilevisibility_at_990 {
    display: flex;
  }

  .tabvisibility {
    display: none !important;
  }

  .FieldWidth {
    width: 50% !important;
  }

  .FieldWidth100 {
    width: 100% !important;
  }

  .d_none_990{
    display: none;
  }
}

@media (max-width: 820px) {
  .FieldWidth {
    width: 50% !important;
  }

  .FieldWidth100 {
    width: 100% !important;
  }
}

@media (max-width: 768px) {
  .pageheight {
    margin: 0 15% !important;
  }
}

@media (max-width: 525px) {
  .pageheight {
    margin: 0 !important;
  }
}

@media (max-width: 320px) {
  .pageheight {
    margin: 0 !important;
  }
}

/* @media screen and (width: 280px) and (height: 653px) {
  body {
    font-size: 14px;
    /* Set the desired font size for this screen size */
/* } */

/* .font12 {
    font-size: 13px !important;
  } */
/* .pageheight { */
/* margin: 0 !important; */
/* } */
/* } */