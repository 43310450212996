.press_main {
  background-color: #fafafa;
}

.Press_para {
  color: #f4f9ff;
  text-align: center;
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 170%;
}

.pressCard {
  display: flex;
  width: 1354px;
  padding: 20px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  border-radius: 20px;
  background: #fff;
}

.pressCard h6{
  color: #02549e;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.pressCard p {
  color: #000 !important;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
