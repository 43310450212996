.aligning {
  align-items: center;
  justify-content: center;
  padding-top: 40px;
}

.centered {
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
}

.contactheading {
  font-weight: 700;
  font-size: 32px;
  padding-top: 30px;
}

.imgclass1 {
  width: 162px;
  height: 80px;
}

.fieldandbuttons {
  padding-top: 30px;
  padding-bottom: 30px;
}

.stflot1 {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  padding-right: 2px;
  font-size: 14px;
  font-weight: 500;
  text-decoration: underline;
}

.stflot2 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  font-size: 14px;
  font-weight: 500;
}

.terms {
  text-decoration: none;
  color: #1f58ea;
  font-weight: 600;
  font-size: 16px;
}

.cacc {
  display: none;
}

.pass_input {
  height: auto !important;
}

.signUp_numberInput {
  padding: 6px 12px !important;
  width: 100%;
  border: 1px solid #cacaca;
}

.signUp_numberInput:hover {
  border: 1px solid black;
}

.signUp_numberInput:focus-within {
  border: 2px solid rgb(52, 123, 255) !important;
}

.pass_suggestion_box {
  position: absolute;
  top: 45px;
  left: 0;
  background-color: #ececec;
  border-radius: 8px;
  padding: 12px;
  z-index: 2;
}

.pass_suggestion_box h6 {
  color: #222;
  font-size: 0.90rem;
  font-family: Montserrat;
}

.valid_passw {
  font-size: 0.75rem;
  color: green;
  font-weight: 600;
  font-family: Montserrat;
  margin: 0 0 5px 0;
  display: flex;
  gap: 8px;
}

.valid_passw:before {
  content: "✔";
  color: green;
}

.invalid_passw {
  font-size: 0.75rem;
  color: red;
  font-weight: 600;
  font-family: Montserrat;
  margin: 0 0 5px 0;
  display: flex;
  gap: 8px;
}

.invalid_passw:before {
  content: "✖";
  color: red;
}


.SignUp_img img {
  height: 970px;
  min-height: 100vh;
}

.userRole-BtnContainer {
  background-color: #F8F8F8;
  padding: 10px;
}


.InActive_realtorStep {
  color: #A7A7A7;
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.active_realtorStep {
  color: #000;
}


.inActive_realtorStepCircle {
  color: var(--primary, #0253A0);
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  border: 1px solid var(--primary, #0253A0);
  border-radius: 50%;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.active_realtorStepCircle {
  background-color: #0253A0;
  color: #fff;
}


.steps_realtorSignUp{
  color: #333;
  font-size: 18px;
  font-weight: 600;
}

.steps_realtorSignUp span{
  color: #676767;
  font-weight: 400;
  font-size: 16px;
}

.signUP_prevBtn{
  color: #F6941C;
  background-color: #fff;
  border: 1px solid #F6941C;
}

@media (max-width: 800px) {
  .cacc1 {
    display: none;
  }

  .cacc {
    display: block;
  }

  .aligning {
    padding-top: 0px !important;
  }

  .signup_topPadding {
    padding-top: 40px !important;
  }
}

@media (max-width: 600px) {
  .signUp_numberInput {
    width: 300px;
  }
}