.parentagent {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding-top: 40px;
  padding-bottom: 40px;
  position: relative;
}

.AdvisorAgent_link{
  display: flex;
  justify-content: flex-end;
}

.AdvisorAgent_link button{
  background-color: #02549E;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 600;
  color: white;
  border: none;
  outline: none;
  padding: 10px;
  border-radius: 10px;
}

.agenthead {
  font-size: 32px;
  font-weight: 600;
  color: #121212;
}
.searchiconfilled {
  color: #02549e;
  height: 34px;
  width: 34px;
}
.columflex {
  display: flex;
  flex-direction: column;
  padding-top: 15px;
}
.agentimg {
  width: 100%;
  height: 309px;
}
.agentborder {
  border: 1px solid #cccccc;
  border-radius: 8px;
  padding: 10px;
  margin-bottom: 9px;
}
.buttonadjustments {
  display: flex;
  align-items: center;
  justify-content: center;
}

.agenttexthead {
  font-weight: 600;
  font-size: 24px;
}
.agenttextbody {
  font-weight: 400;
  font-size: 15px;
}
.allagentbutton {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 50px;
}
.fitneeds {
  font-size: 20px;
  font-weight: 600;
}

.agent_allcards {
  width: 100%;
}



@media (max-width: 768px) {
  .AdvisorAgent_link{
    margin-right: 20px
  }
  .agent_picHide {
    display: none;
  }
  .agent_SearchField {
    width: 90%;
  }
  .css-j37eqo-MuiPaper-root {
    width: 100% !important;
  }
  .agenthead {
    font-size: 24px;
  }
  .agent_heading {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    padding-left: 2rem;
  }
  .columflex {
    padding: 15px;
  }
}

@media (max-width: 540px) {
  .columflex {
    display: flex;
    flex-direction: row;
  }
  .agent_textHide {
    display: none;
  }
  .agentborder {
    border: none;
    padding: 0px;
    width: 50%;
  }
  .agent_whitegreenbutton {
    font-size: 14px;
    padding: 9px 20px;
  }
}

@media (max-width: 320px) {
  .agent_heading {
    padding-left: 1rem;
  }
  .agent_whitegreenbutton {
    font-size: 12px;
    padding: 7px 10px;
  }
  .cardimage {
    height: 86px;
    width: 86px;
    border-radius: 12px !important;
  }
}
