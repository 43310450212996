.submitheading {
  font-size: 24px;
  font-weight: 700;
  padding-top: 30px;
}

.margin_auto_at_760 {
  margin: 0;
}

.fd-col {
  display: flex;
  flex-direction: column;
}

.respDesc {
  font-size: 14px;
  font-weight: 100;
  color: #a1a1a1;
}

.borderzzz {
  border-radius: 8px;
  align-items: center;
  justify-content: center;
  display: flex;
}

.file-upload {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  padding: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 0.25rem;
  color: #333;
  font-weight: bold;
  cursor: pointer;
}

.locaicon {
  padding-right: 2px;
  color: #838383;
}

.file-upload svg {
  fill: #333;
  height: 1.25rem;
  width: 1.25rem;
}

.randomimages {
  width: 100%;
  height: 200px;
  border-radius: 8px;
  border: 2px solid #838383;
}

.cardwidth {
  width: 15rem;
}

.randimages {
  width: 100%;
  height: 140px;
  border-radius: 8px;
  border: 2px solid #838383;
}

.randomimages2 {
  width: 100%;
  height: 120px;
  border-radius: 8px;
  border: 2px solid #838383;
}

.overlayw {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.col-lg-3:hover .overlayw {
  opacity: 1;
}

.uploadfile {
  font-size: 14px;
}

.uploadfiletxt {
  font-size: 12px;
}

.row-with-gap {
  gap: 20px;
  /* Adjust the value as needed */
}

.boxupload {
  border: 1px solid #e8e8e8;
  border-radius: 12px;
  height: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.boxupload2 {
  border: 1px solid #e8e8e8;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.imgupload {
  display: flex;
  align-items: center;
  justify-content: center;
}

.cardsims {
  background-color: #0253a0;
  padding: 2px 12px 2px 12px;
  color: #ffffff;
  font-size: 14px;
  border-radius: 12px;
  border: none;
}

.sdfe {
  background-color: #f4f4f4;
  border: 1px solid #e2e2e2;
  border-radius: 4px;
  padding: 2px;
  font-size: 12px;
}

.pbb-2 {
  /* padding-bottom: 10px; */
  padding-left: 8px;
  color: #c6c6c6;
  font-size: 14px;
}

.borderoncard {
  border: 1px solid #e8e8e8;
  border-radius: 12px;
}

.cardsime {
  background-color: #f6941c;
  padding: 2px 12px 2px 12px;
  color: #ffffff;
  font-size: 14px;
  border-radius: 12px;
  border: none;
}

.mainstyling {
  background-color: #676767;
  color: #ffffff;
  padding: 3px 16px 3px 16px;
  border-radius: 12px;
}

.FiUpload {
  font-size: 35px;
  color: #02549e;
}

.bin {
  background-color: #dd0e0e;
  color: #ffffff;
  padding: 4px;
  border-radius: 50%;
}

.padderzzz {
  padding: 10px;
}

.infohead {
  font-size: 20px;
  font-weight: 700;
}

.fieldheadings {
  font-weight: 500;
  font-size: 16px;
  padding-bottom: 12px;
}

.textinfield {
  font-weight: 600;
  font-size: 14px;
  color: #838383;
}

.infoheading {
  font-size: 20px;
  font-weight: 700;
  padding-top: 50px;
}

.sumitbutton {
  display: flex;
  align-items: center;
  justify-content: center;
}

.waiter {
  width: 19%;
}

.custom-select .MuiSelect-icon {
  fill: #7E7E7E;
  width: 24px;
  height: 24px;
}


@media (max-width: 900px) {
  .submitheading {
    display: none !important;
  }

  .submit_mobContainer {
    display: flex !important;
    width: 96% !important;
    flex-direction: column !important;
    justify-content: flex-end !important;
    align-items: center !important;
  }

  .main_headings {
    width: 100% !important;
    display: flex !important;
    flex-direction: column !important;
    align-items: flex-start !important;
    justify-content: left !important;
  }
}

.mob_filedsettings {
  margin-bottom: 20px !important;
}


@media (max-width: 768px) {
  .margin_auto_at_760 {
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .infoheading {
    text-align: left !important;
  }

  .submit_mobContainer {
    display: flex !important;
    flex-direction: column !important;
    justify-content: flex-end !important;
    align-items: center !important;
  }

  .mediaInfo {
    margin-top: 60px !important;
  }

  .SubmitFooter {
    margin-top: 3rem !important;
  }

  .boxupload {
    height: 250px !important;
  }

  .mob_ImageBox {
    width: 50% !important;
  }

  .mob-ImgField {
    padding-right: 0px !important;
  }
}

@media (max-width: 600px) {
  .comWidth_Field {
    width: 100% !important;
  }
}


@media (max-width: 512px) {
  .mob_ImageBox {
    width: 70% !important;
    left: 50% !important;
    top: 50% !important;
  }
}

@media (max-width: 360px) {

  .submit_mobContainer {
    width: 327px !important;
  }

  .mob_filedsettings {
    width: 280px !important;
  }

  .mob_ImageBox {
    width: 70% !important;
    left: 50% !important;
    top: 50% !important;
  }

}

@media (max-width: 280px) {
  .submit_mobContainer {
    width: 275px !important;
  }

  .mob_filedsettings {
    width: 240px !important;
  }

  .boxupload {
    width: 240px !important;
    padding: 0px !important;
    margin: 0px !important;
  }

  .mob_ImageBox {
    width: 80% !important;
    left: 50% !important;
    top: 50% !important;
  }
}