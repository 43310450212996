.MortgageMain {
  background: #fafafa;
}

.MortgaegMain_image {
  width: 100%;
  position: relative;
  background-color: black;
}

.MortgaegMain_image img {
  width: 100%;
  opacity: 0.5;
}

.mortgageImage_HeadingMain {
  width: 100%;
  position: absolute;
  text-align: center;
  margin: auto;
  top: 50%;
 transform:  translatey(-50%);
  color: #fff;
  font-family: Montserrat;
}
.mortgageImage_heading h1 {
  clear: both;
  font-size: 3.5rem;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
}

.mortgage_lang {
  width: 100%;
  text-align: center;
  display: flex;
  justify-content: center;
}

.mortgageImage_heading h3 {
  width: 75%;
  font-size: 2rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.mortgageImage_language {
  background-color: rgba(217, 217, 217, 0.8);
  width: fit-content;
  padding-left: 5px;
  padding-right: 5px;
  color: #02549e;
  font-size: 2rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.mortgageImage_Des h5 {
  color: #fff;
  text-align: center;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.MortgageAgent_directory {
  color: #000;
  text-align: center;
  font-family: Montserrat;
  font-size: 3rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.DropDownAndAgent {
  display: flex;
  justify-content: space-between;
}

.Spoken_dropDown {
  width: 25%;
  height: auto;
  border-radius: 8px;
  background: #fff;
}

.mortgageAgent_find {
  background-color: #02549e;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 600;
  color: white;
  border: none;
  outline: none;
  padding: 10px;
  border-radius: 10px;
}
.mortgage_agentUnselectBtn {
  background-color: #fff;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 600;
  color: #02549e;
  border: 1px solid #02549e;
  outline: none;
  padding: 10px;
  border-radius: 10px;
}

.mortgage_Cards {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.mortgage_card {
  margin-top: 2.5rem;
  width: 45%;
  height: 115px;
  border-radius: 20px;
  background: #ffffff;
  padding-left: 15px;
  padding-right: 15px;
  display: flex;
  align-items: center;
  cursor: pointer;
  position: relative;
}

.mortgageCard_1 {
  display: flex;
  justify-content: left;
  width: 72%;
}

.mortgage_cardImage {
  width: 60px;
  height: 60px;
  border-radius: 64px;
  display: flex;
  align-items: center;
}

.mortgage_cardImage img {
  width: 100%;
  height: 100%;
  border-radius: 64px;
}

.agent_information {
  font-family: Montserrat;
  padding-left: 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.mortgageagent_name {
  margin: 0 !important;
  color: #000;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.mortgageagent_address {
  margin: 0 !important;
  font-size: 1rem;
  font-weight: 400;
  line-height: normal;
}

.mortgageagent_language {
  margin: 0 !important;
  color: #676767;
  font-size: 0.75rem;
}

.mortgage_chip {
  padding: 5px;
  border-radius: 6px;
  background: #bde0fe;
  height: fit-content;
  margin-left: 0rem;
}

.mortgage_chip p {
  margin: 0px !important;
  color: #02549e;
  font-family: Montserrat;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.mortgageIcons {
  width: 22%;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.mortgagemessageIcon {
  width: 50px;
  height: 50px;
  background-color: #f1f1f1;
  border-radius: 64px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.mortgage_icons {
  color: #02549e;
  font-size: 22px;
}

.mortgagekeyIcon {
  position: absolute;
  top: 40px;
  right: 10px;
}

.mortgageRight_icon {
  color: #676767;
  font-size: 40px;
}

.mortagage_better {
  display: block;
}

.mortgage_Search {
  display: flex;
  float: right;
  margin-right: 3rem;
}

.mortgage_Search input {
  background-color: transparent;
  width: 155px;
  outline: none;
  border: none;
  border-bottom: 1px solid white;
  color: white;
  font-family: Montserrat;
}

.mortgage_searchIcon {
  font-size: 24px;
  font-weight: bold;
}

.no_Found__img {
  width: 280px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.no_Found__img img {
  width: 100%;
  height: 100%;
}

@media (max-width: 1200px) {
  .mortgageImage_heading h1 {
    font-size: 2.8rem;
  }

  .mortgageImage_heading h3 {
    font-size: 1.2rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  .mortgageImage_language {
    font-size: 1.2rem;
    margin: 0px !important;
  }

  .mortgageImage_Des h5 {
    font-size: 1rem;
  }

  .mortgagemessageIcon {
    width: 35px;
    height: 35px;
  }

  .mortgage_icons {
    font-size: 20px;
  }
  .mortgageRight_icon {
    font-size: 25px;
  }

  .mortgageCard_1 {
    display: flex;
    justify-content: left;
    width: 70%;
  }

  .mortgageagent_name {
    font-size: 0.9rem;
  }

  .mortgageagent_address {
    font-size: 0.7rem;
  }

  .mortgageagent_language {
    font-size: 0.6rem;
  }
  .mortgage_chip p {
    font-size: 8px;
  }
}

@media (max-width: 950px) {

  .mortgageImage_heading h1 {
    font-size: 2rem;
  }

  .mortgageImage_heading h3 {
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  .mortgageImage_language {
    font-size: 1rem;
    margin: 0px !important;
  }
  .mortgageImage_Des {
    margin: 5px !important;
  }
  .mortgageImage_Des h5 {
    font-size: 0.8rem;
  }
  .mortgage_Search {
    display: none;
  }
  .Spoken_dropDown {
    width: 50%;
  }

  .mortgage_card {
    height: auto !important;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }

  .mortgageCard_1 {
    display: flex;
    justify-content: left;
    margin-top: 1rem;
    width: 100%;
  }
  .mortgage_cardImage {
    width: 36px;
    height: 36px;
  }
  .mortgageIcons {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    margin-top: 0.7rem;
    margin-bottom: 1rem;
  }
  .mortgagemessageIcon {
    margin-left: 2rem !important;
  }

  .mortgageCallIcon {
    margin-left: 0.5rem !important;
  }

  .agent_information {
    width: 62% !important;
  }

  .mortgageagent_name {
    font-size: 16px;
  }

  .mortgageagent_address {
    font-size: 14px;
  }

  .mortgageagent_language {
    font-size: 11px;
  }

  .mortgagekeyIcon {
    position: absolute;
    top: 50px;
    right: 12px;
  }
}

@media (max-width: 800px) {
  .MortgaegMain_image img {
    height: 156px !important;
  }
  
  .mortgageImage_heading h1 {
    font-size: 24px !important;
  }
  .mortgageImage_heading h3 {
    width: 95% !important;
    font-size: 14px !important;
  }
  .mortgageImage_language h3 {
    font-size: 14px !important;
  }
  .mortgageImage_Des h5 {
    font-size: 14px !important;
  }
  .mortagage_better {
    display: inline;
  }
  .MortgageAgent_directory {
    font-size: 24px !important;
  }
}

@media(max-width:768px){
  .MortgageBtn_dropDown {
    flex-direction: column;
    align-items: center;
    gap: 20px;
  }
  .Spoken_dropDown {
    width: 80%;
  }
}

@media (max-width: 700px) {
  .mortgage_card {
    width: 100%;
  }
  .agent_information {
    width: 65% !important;
  }
}

@media (max-width: 420px) {
  .MortgaegMain_image img {
    height: 162px !important;
  }
  .Spoken_dropDown {
    width: 100%;
  }
  .mortgageAgent_find {
    font-size: 14px !important;
  }
  .mortgage_agentUnselectBtn {
    font-size: 14px !important;
  }
  .agent_information {
    width: 50% !important;
  }
}
@media (max-width: 280px) {
  .MortgaegMain_image img {
    height: 220px !important;
  }
  .Spoken_dropDown {
    width: 100%;
  }
  .mortgageAgent_find {
    display: none;
  }
  .agent_information {
    width: 45% !important;
  }
}
