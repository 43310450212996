.margins {
  margin-left: 20px;
}

.textcolor {
  color: #676767;
  text-decoration: none;
}
.section-width {
  width: 90%;
  margin: 2.5rem !important;
}
.mar {
  margin-left: 100px;
}
.ft-11 {
  font-weight: 700;
  font-size: 34px;
}

.bold-fonts {
  font-family: inherit !important;
}
.footer-links {
  font-size: 16px !important;
}
.fit-content {
  width: fit-content;
}
.footermid-container {
  width: fit-content;
  display: flex;
  flex-flow: row;
  justify-content: space-between;
}

.widhts {
  width: 100px;
}

.flex {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.icon_setting{
  color: #676767;
  font-size: 20px;
margin-right: 0.7rem;
}


.copy_right{
color: #676767;
font-family: "Montserrat";
font-size: 16px;
font-style: normal;
font-weight: 500;
line-height: 18px; 
}

@media(max-width: 1260px){
  .mobile_div {
    text-align: start !important;
    width: 40% !important;
    margin-left: 0px !important;
    margin-bottom: 0px !important;
    padding: 15px 35px !important;
  }
}


@media (max-width: 768px) {
  .mobile_div {
    width: 95% !important;
    margin-left: 0px !important;
    margin-bottom: 0px !important;
    padding: 15px 35px !important;
  }

  .mobile_div h5 {
    text-align: left;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

  .mobile_div p {
    text-align: left;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
  }

  .mobile_div hr{
    margin: 0px !important;
  }
}
