.Blog_detailsImg {
    width: 100%;
}

.Blog_detailsImg img {
    width: 100%;
    border-radius: 30px;
    height: 100%;
    max-height: 450px;
}

.blog_details_viewsChip {
    padding: 8px 10px;
    border-radius: 32px;
    background: #F5F5F5;
    font-family: Montserrat;
    display: flex;
    justify-content: center;
    align-items: center;
}

.blog_details_viewsChip span {
    margin-left: 5px;
    font-size: 20px;
    font-weight: 500;
    line-height: normal;
    color: #676767
}

.blog_detailsTitle h2 {
    color: #222;
    font-family: Montserrat;
    font-size: 36px;
    font-weight: 700;
    line-height: 130%;
}

.blog_detailsDescription p {
    color: #7e7e7e;
    font-family: Montserrat;
    font-size: 16px;
    font-weight: 400;
    line-height: 140%;
}

.blog_detailsDescription a{
    color: #02549e;
}

.blog_detailsDescription a:hover{
    text-decoration: underline;
}

.relatedArticle__card {
    border-bottom: 1px solid #d9d9d9;
    padding: 15px 0;
    width: 100%;
}

.realtedArticles__Heading {
    color: #000;
    font-family: Montserrat;
    font-size: 24px;
    font-weight: 700;
    line-height: 130%;
}


.relatedArticle_content {
    color: #000;
    font-family: Montserrat;
    font-size: 20px;
    font-weight: 600;
    line-height: 140%;
    margin-bottom: 0 !important;
    margin-left: 10px;
    width: calc(100% - 160px);
}

.relatedArticle_content:hover{
    cursor: pointer;
    text-decoration: underline;
}

.relatedArticle_img{
    border-radius: 10px;
    width: 150px;
    height: 100px;
}


@media(max-width: 1100px) {
    .relatedArticle_img {
        width: 100px;
        height: 81px
    }

    .relatedArticle_content {
        font-size: 16px;
    }
}

@media(max-width: 900px) {
    .relatedArticle_content {
        font-size: 14px;
    }

}


@media(max-width: 800px) {
    .relatedArticle_content {
        font-size: 12px;
    }

}

@media(max-width: 768px) {
    .ralted_articel_container {
        display: none;
    }

    .blog_details_viewsChip {
        padding: 5px 7px;
    }

    .blog_details_viewsChip svg {
        width: 24px !important;
    }

    .blog_details_viewsChip img {
        width: 24px !important;
    }

    .blog_details_viewsChip span {
        font-size: 16px;
    }

    .Articlechip_margin {
        margin-left: 10px !important;
    }

    .blog_detailsTitle h2 {
        font-size: 24px;
        margin-top: 20px !important;
    }

    .blog_detailsDescription p{
        font-size: 14px;
        margin-top: 15px !important;
    }
}