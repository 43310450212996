.child1 {
  /* background-color: red; */
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 40px;
}

.giveMeEllipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  /* number of lines to show */
  line-height: X;
  /* fallback */
  max-height: X*N;
  /* fallback */
}

.imgheight {
  height: 100px;
  width: 100%;
}

.imageofpopup {
  border-radius: 12px;
}

.padleft {
  padding-left: 4px;
}

.textcompare {
  padding: 12px;
  padding-left: 30px;
  font-size: 22px;
  font-weight: 900;
  display: flex;
  font-family: "Montserrat";
  align-items: center;
  /* justify-content: center; */
}

.seconddrwaerback {
  background-color: #ffffff;
}

.modaldisplay {
  display: flex;
  /* align-items: flex-end; */
  justify-content: flex-end;
  background-color: #02549e;
  color: #ffffff;
}

.recordheading {
  font-size: 20px;
  font-weight: 700;
}

.modalcardpara {
  font-size: 12px;
  font-weight: 700;
}

.modalcardpara2 {
  font-size: 18px;
  font-weight: 900;
}

.cardinmodal {
  display: flex;
  flex-direction: column;
  position: relative;
}

.greycard {
  background-color: #d9d9d9;
  padding-left: 8px;
  border-radius: 4px;
}

.cardonpic {
  position: relative;
  top: -45px;
  background: white;
  border-radius: 13px;
  padding: 9px;
}

.child2x {
  /* background-color: red; */
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 60px;
}

.cursorpointer {
  cursor: pointer;
}

.popuppadding {
  padding-left: 10px;
  font-size: 50px;
}

.child2xs {
  /* background-color: red; */
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 60px;

}

.childx1 {
  /* background-color: rgba(255, 255, 255, 0.87); */
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 25px;
  font-family: Montserrat;
}

.maindata {
  width: 100%;
  height: 25vh;

}

.posted {
  display: flex;
  flex-direction: row;
  height: 25px;
}

.card-type-buttons {
  display: flex;
  /* flex-direction: row; */
  justify-content: flex-start;
}

.price {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  height: 25px;
}

.redcolor {
  font-weight: 600;
  font-size: 20px;
  color: red;
  margin-right: 4px;
}

.regis {
  font-weight: 400;
  font-size: 15px;
}

.cname {
  font-size: 13px !important;
}

.cardgreen {
  font-weight: 700;
  font-size: 16px;
  color: #02549e;
  margin-right: 4px;
}

.cardprice {
  font-weight: 600;
  font-size: 14px;
  color: #ca0024;
  margin-right: 4px;
  text-decoration: line-through;
}

.cardprice2 {
  font-weight: 600;
  font-size: 14px;
  color: #ca0024;
  margin-right: 4px;
}

.cardprice1 {
  font-weight: 600;
  font-size: 11px;
  color: #ca0024;
  margin-right: 4px;
  text-decoration: line-through;
}

.closing {
  color: #ca0024;
  font-size: 15px;
  font-weight: 400;
}

.cardpara2 {
  font-size: 11px !important;
  font-weight: 400;

  font-family: Montserrat;
}

.cardpara {
  color: #02549e;
  font-size: 16px;
  font-weight: 400;
  font-family: Montserrat;
}

.cardpr {
  font-weight: 600;
  font-size: 11px;
  color: black;

}

.paddin {
  padding-left: 10px;
  padding-right: 0;
}

.dividercolor {
  color: grey;
  margin-right: 2px;
  margin-left: 2px;
}

.viewmore {
  color: #02549e;
  font-size: 12px;
  /* margin-bottom: 13px; */
  height: 10px;
}

.dated {
  font-size: 11px;
}

.divider {
  color: grey;
  margin-right: 2px;
  margin-left: 2px;
  font-size: 14px;
}

.faviconcolorred {
  color: #ca0024;
}

.faviconcolorgreen {
  color: #02549e;
}

.btngrey {
  background-color: rgb(219, 219, 219);
}

.ImageOverlaysetting {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 12px;
  width: 100%;
  padding: 0 10px;
}

.ImageOverlaysetting svg {
  font-size: 18px;
  margin-right: 2px;
  margin-left: 8px;
}

.ButtonOverlaySetting {
  margin-right: 32px;
  margin-bottom: 400px;
}

.listwidth {
  width: 100%;
  padding: 0px;
}

.btndecor {
  padding: 4px;
  width: 64px;
  /* height: 63px; */
  background-color: #ffffff;
  color: #02549e;
  font-weight: bold;
}

.iconmodification {
  margin-left: 20px;
  margin-right: 20px;
}

.carousel-image-container {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
}

.aaligner {
  display: flex;
  align-items: flex-end;
}

.aaligner2 {
  display: flex;
  align-items: flex-start;
}

.settinger {
  padding-left: 50px;
  padding-right: 50px;
}

.carousel-image-container img {
  max-width: 100%;
  height: auto;
}