.orderSummary_mobileViewContainer {
    display: none;
}

.mob_messageShow {
    display: none;
    text-align: center;
    width: 100%;
}


.Order_PricingTableContainer {
    width: 100%;
    overflow-x: auto;
    min-height: 69vh;
    overflow-x: auto;
}

.Order_PricingTable {
    min-width: 880px;
    width: 100%;
    border-collapse: collapse;
}

.Order_PricingTable th {
    text-align: left;
    padding: 0.5rem 0;
}

.Order_PricingTable td {
    text-align: left;
    padding: 1rem 0;
    color: #7e7e7e;
}

.Order_PricingTable th {
    border-bottom: 1px solid #d9d9d9;
}

.order_summary_checkBox .css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root {
    padding: 0 8px 0 0 !important;
}

.order_summary_checkBox .css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root .css-i4bv87-MuiSvgIcon-root {
    padding: 0 !important;
    width: 1.2rem !important;
    height: 1.2rem !important;
}

.orderSummaryMob_heading {
    font-size: 24px !important;
    font-weight: 700;
    color: #000;
}

.OrderSumm_MobBillCard {
    display: flex;
    flex-direction: column;
    gap: 18px;
    padding: 28px 0px;
    border-bottom: 1px solid #9A9A9A;

}

.OrderSumm_MobBillCard span {
    color: #7E7E7E;
    font-size: 16px;
    font-weight: 500;
}

.TotalOrderBill_mbl {
    position: fixed;
    bottom: 0;
    z-index: 2;
    border-top: 1px solid #7e7e7e;
    background-color: #fff;
    padding: 20px 32px;
    width: 80%;
    text-align: center;
}

.TotalOrderBill_mbl button {
    background-color: #02549E;
    color: #FFF;
    text-align: center;
    font-family: Montserrat;
    font-size: 16px;
    font-weight: 600;
    line-height: normal;
    width: 70%;
    padding: 1rem 0;
}

.WebPayNow_btn{
    background-color: #02549E;
    color: #FFF;
    text-align: center;
    font-family: Montserrat;
    font-size: 16px;
    font-weight: 500;
    line-height: normal;
    width: 210px;
    padding: 0.8rem 0;
    outline: none;
    border: none;
    border-radius: 6px;
}

.WebPayNow_btn:hover{
    background-color: #004a8b;
}

@media (max-width: 1200px) {
    .orderSummary_headingSearch {
        flex-direction: row !important;
    }
}

@media (max-width: 1050px) {
    .orderSummary_favMobNone {
        display: none !important;
    }

    .mob_orderMassViewFav {
        display: block;
        border-top: 1px solid #d6d6d6;
        border-bottom: 1px solid #d6d6d6;
        margin: 1rem 0rem 0.7rem 0rem;
        width: 100%;
        padding: 7px;
        text-align: center;
    }

    .viewSummary_btn {
        margin: 0 auto !important;
    }

    .mob_messageShow {
        display: block;
    }
}



@media (max-width: 900px) {
    .orderSummary_headingSearch {
        flex-direction: column !important;
        gap: 20px;
    }
}

@media (max-width: 768px) {
    .orderSummary_card {
        gap: 1rem !important;
    }

    .orderSummary_webViewContainer {
        display: none !important;
    }

    .orderSummary_mobileViewContainer {
        display: block;
    }
}

@media (max-width: 580px) {
    .TotalOrderBill_mbl {
        width: 95%;
    }
}

@media (max-width: 430px) {
    .TotalOrderBill_mbl button{
        width: 100%;
    }
}