.cards__Grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}
.car__13 {
  border: 1px solid #cccccc;
  padding: 0.5rem !important;
  margin: 0.5rem !important;
  height: 14rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  width: 20rem;
  cursor: pointer;
}
.text__Grey{
  color: #757575 !important;
}
.Message_Badge{
  background-color: #02549e;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #FFFFFF;
  padding: 1px;
  width: 1rem !important;
  margin-bottom: -0.6rem !important;
  z-index: 1;
  position: relative;
  font-size: 8px;
  border-radius: 50%;
  font-weight: 900;
}
.left__Badge{
  margin-right: 0.5rem !important;
}
.active-div{
  background-color: #CCCCCC !important;
}