.childcompare_img{
    width: 100%;
    height: 35vh;
}

.cardmodal_ajustments{
    padding: 5px;
    color: black;
}

.childModal_project{
    color: #121212;
font-family: Montserrat;
font-size: 16px;
font-style: normal;
font-weight: 600;
line-height: normal;
}
.childModal_typehead{
    color: #121212;
font-family: Montserrat;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: normal;
}

.childModal_type{
    color: #121212;
font-family: Montserrat;
font-size: 14px;
font-style: normal;
font-weight: 600;
line-height: normal;
}

.child_modalbedandBath{
    display: flex;
    justify-content: space-between;
    font-size: 12px;
}

.childModalAddress{
    color: #676767;
font-family: Montserrat;
font-size: 14px;
font-weight: 400;
}

.childModal_posted{
    color: #121212;
font-size: 14px;
font-weight: 600;
}

.chilsModal_card1{
    border: 2px solid #979797;
    padding: 10px;
    border-radius: 4px;
}

.card_linebordder{
    padding: 11px;
}

.bottom_pad{
    padding: 5px
}
.childModal_heading{
    font-size: 18px;
}
.allchildModalText{
    font-size: 12px;
}

