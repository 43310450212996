.Delas_allCards {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
}

.deals_loader {
  display: flex;
  justify-content: flex-start !important;
  align-items: center !important;
  width: 100%;
}

.deals_content {
  display: flex;
  justify-content: flex-start !important;
  align-items: center !important;
  width: 100% !important;
}


.slick-track {
  display: flex !important;
  align-items: center;
  justify-content: flex-start;
  margin: 0px !important;
  /* gap:20px; */
}

.find_dealsTagline {
  color: #121212;
  font-size: 20px;
  font-weight: 500;
  line-height: 30px;
  margin-top: 1rem;
}


@media(max-width:800px){
  .find_dealsTagline {
   font-size: 16px;
   line-height: 20px;
   margin-top: 0.8rem;
  }
}