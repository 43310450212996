.mapcard_img {
    position: relative;
    height: 180px;
    padding: 5px;
    width: 100%;

}


.card {
    max-width: calc(100%) !important;
    height: auto !important;
}

.refined_images_cards {
    height: fit-content !important;
    padding: 10px;
}

.popcard_maintext {
    height: 200px !important;
}


@media (max-width:800px) {
    .popcard_maintext {
        height: 215px !important;
    }
}