.Not_foundMain {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 4rem;
}

.Not_foundMain img {
  width: 723px;
  height: 96vh;
}

@media(max-width: 768px){
    .Not_foundMain img {
        width: 525px !important;
        height: 400px !important;
      }
}

@media(max-width: 525px){
    .Not_foundMain img {
        width: 350px !important;
        height: 320px !important;
      }
}

@media(max-width: 360px){
    .Not_foundMain img {
        width: 240px !important;
        height: 200px !important;
      }

    }
