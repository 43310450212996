.HowItWorks_Container {
  font-family: 'Montserrat';
  color: #000;
  text-align: center;
  margin-top: 2rem;
}


.HowItWorks_Container h1 {
  font-size: 64px;
  font-weight: 700;
  line-height: normal;
}

.HowItWorks_Process {
  position: relative;
  min-height: 1590px;
}

.HowItWorks_Process h4 {
  color: var(--primary, #0253A0);
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin: 1rem 0;
}

.HowItWorksSteps_content h3 {
  font-size: 32px;
  font-weight: 700;
  line-height: normal;
  margin: 1rem 0;
}

.HowItWorks_Des {
  color: var(--Gray, #676767);
  font-size: 20px;
  font-weight: 400;
  line-height: normal;
}

.itworks_desktop {
  display: none !important;
}

.HowItWorksLineFrame {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}


.worksTools_container {
  position: relative;
  min-height: 850px;
}

@media (max-width: 768px) {
  .HowItWorks_Container {
    margin-top: 1rem;
  }

  .HowItWorks_Container h1 {
    font-size: 32px;
  }

  .HowItWorks_Process h4 {
    font-size: 24px;
  }

  .HowItWorksSteps_content h3 {
    font-size: 16px !important;
    margin: 1.5rem 0 0.5rem 0;
  }

  .HowItWorks_Des {
    font-size: 14px;
  }

  .HowitWorks_MobImg {
    text-align: left !important;
  }

  .howItWorks_mobPadding {
    padding: 2rem 20px !important;
  }

  .howItWorks_mobPadding img {
    width: 72px;
    height: 72px;
  }

  .HowitWorks_MobLeftText {
    align-items: flex-start !important;
  }

  .itWorks_column_rver {
    flex-direction: column-reverse;
  }

  .itWorks_index {
    display: none !important;
  }

  .itworks_desktop {
    display: flex !important;
    margin-bottom: 0.80rem !important;
  }

  .HowItWorksLineFrame{
    left: 10px;
  }

  .HowItWorksLineFrame img{
    max-height: 1250px;
  }
  .mobile_itWorks_padding{
    padding: 20px 0 !important;
  }
  .HowItWorks_Process {
    min-height: 1320px;
  }

  .worksTools_container{
    min-height: 685px;
  }
  .tools_line img{
    max-height: 625px;
  }
}


@media(max-width:480px){
  .HowItWorks_Process {
    min-height: 1400px;
  }
  .HowItWorksLineFrame img{
    max-height: 1350px;
  }

  .worksTools_container{
    min-height: 700px;
  }
  .tools_line img{
    max-height: 685px;
  }
}