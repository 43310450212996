label {
  font-family: "Montserrat";
  font-size: 14px;
  font-weight: 400;
}

.pointer-on-hover {
  cursor: pointer;
}

.flot {
  float: right;
  padding-top: 7px;
  font-size: 14px;
  font-weight: 400;
  font-family: "Montserrat";
}

.td-none {
  text-decoration: none;
  color: #ffffff;
}

.imgclass1 {
  width: 150px !important;
  height: 120px !important;
}


.contactheading {
  font-weight: 700;
  font-size: 32px;
  padding-top: 30px;
}

.textalignment {
  display: flex;
  align-items: center;
  justify-content: center;
}

.LoginImage img {
  border-radius: 20px 0px 0px 20px;
  height: 900px;
  min-height: 100vh;
}



@media (max-width: 768px) {
  .textalignment {
    display: flex;
    align-items: center;
    justify-content: center;
  }


}

.adjustment {
  display: flex;
  flex-direction: column;
}

.font {
  font-family: "Montserrat" !important;
}

.fieldhead {
  font-weight: 500;
  font-size: 16px;
  padding-bottom: 5px !important;
  padding-top: 5px;
}

.fieldsettings {
  width: 386px;
  height: 40px;
}

.stflot1 {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  padding-right: 2px;
  font-size: 14px;
  font-weight: 500;
  text-decoration: none !important;
}

.stflot2 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  font-size: 14px;
  font-weight: 500;
  text-decoration: underline;
}

.justify {
  display: flex;
  padding-top: 12px;
  padding-bottom: 12px;
  align-items: center;
  justify-content: center;
}

.imagecentered {
  display: flex;
  align-items: center;
  justify-content: center;
}

.Remme {
  display: flex;
  justify-content: space-between;
}

.fpass {
  padding-top: 10px !important;
  padding-right: 4px;
  color: #02549e;
}

.fpass a:hover {
  text-decoration: underline;
}

.skip {
  display: none;
}

.login_supportingLine{
  color: var(--Gray-600, #475467);
font-family: Montserrat;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 24px;
}




@media (max-width: 992px) {
  .LoginImage {
    display: none;
  }

  .Contactimage {
    display: none;
  }
}

@media (max-width: 800px) {
  .cac {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .imagecentered {
    display: none;
  }

  .span-for-hide {
    display: none;
  }

  .dhac {
    display: none;
  }

  .contactheading {
    font-weight: 700;
    font-size: 32px;
    padding-top: 15px !important;
  }

  .skip {
    display: block;
    color: #737373;
    margin-top: 2rem !important;
    padding-bottom: 5rem !important;
  }

  .stflot2 {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    margin-top: 1rem !important;
    font-size: 14px;
    font-weight: 500;
    text-decoration: underline;
  }

  .Login_form {
    max-width: 396px !important;
    width: 100% !important;
  }
}