.imgclass1{
    width: 162px;
    height: 80px;
}
.mailimg{
    width: 89px;
    height: 89px;
   
}
.imageresizing{
    padding-top: 40px;
}
.alignCenter{
    display: flex;
    align-items: center;
    justify-content: center;
}
@media (max-width: 800px) {
    /* .padders{
        padding: 0rem 4rem 0rem 4rem !important;
    } */
    .resetheading {
        font-weight: 600;
        font-size: 16px;
        padding-top: 30px;
    }
    .centerer{
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }
    .textmer{
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 11px !important;
    }
    .Resetbutton {
        background-color: #0253A0;
        color: white;
        border: 0px;
        height: 40px;
        width: 100% !important;
        padding: 4px;
        border-radius: 4px;
        font-weight: 600;
        font-size: 16px;
        font-family: "Montserrat";
      }
    
}