.Article_cover {
  position: relative;
  background: black;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 99%;
  border-radius: 30px;
}

.Article_cover img {
  width: 100%;
  opacity: 0.8;
  border-radius: 30px;
}

.Article_coverContent {
  position: absolute;
}

.Article_coverContent h1 {
  color: #FFF;
  text-align: center;
  font-family: Montserrat;
  font-size: 64px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
}

.Article_Filter_andButton {
  max-width: 1280px;
  margin: 20px auto 0 auto;
  padding: 20px;
}

.Article_filter_section {
  width: 259px;
  gap: 16px;
}

.Article_cardsSection {
  width: calc(100% - 275px);
}

.Blogs_mainPara {
  display: flex;
  justify-content: center;
}

.Blogs_mainPara p {
  max-width: 1000px;
  color: #000;
  text-align: center;
  font-family: Montserrat;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}


.artile_Searching {
  width: 100%;
  background: #f5f5f5;
  border-radius: 6px;
  padding: 15px;
}

.artile_Searching input {
  width: calc(100% - 25px);
  height: 100%;
  border: none;
  outline: none;
  background-color: transparent;
  color: #7e7e7e;
}

.BlogPathBtn {
  width: 100%;
  border-radius: 6px;
  background: #f5f5f5;
  padding: 15px;
  color: #222;
  font-size: 16px;
  font-weight: 500;
  border: none;
  outline: none;
  font-family: Montserrat;
}

.Blog_ActiveBtn {
  background: #222;
  color: #fff;
}

.BlogPathBtn:hover {
  background: #222;
  color: #fff;
}

.AllArticleRead_cards {
  display: flex;
  justify-content: flex-start;
  gap: 15px;
  flex-wrap: wrap;
}

.ArticleCard {
  max-width: 308px;
  padding: 0 5px 5px 5px;
}

.ArticleCard:hover {
  transform: translateY(-1px);
  transition: transform 0.3s ease;
}


.ArticleMainCard_Image {
  height: 230px;
  max-width: 380px;
  width: 100%;
  border-radius: 20px;
}

.ArticleMainCard_Image img {
  width: 100%;
  height: 100%;
  border-radius: 20px;
}

.categoryAndRead {
  display: flex;
  justify-content: space-between;
  color: #666;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
  align-items: center;
  font-family: 'Montserrat';
}

.categoryAndRead h6 {
  margin: 0;
  color: #666 !important;
  font-weight: 500;
  font-size: 16px;
  line-height: 140%;
}

.ArticleCard_category {
  border-radius: 27px;
  background: #f5f5f5;
  padding: 8px;
}

.ArticleCard_Image {
  width: 100%;
  height: 172px;
  border-radius: 20px;
}

.ArticleCard_Image img {
  height: 100%;
  width: 100%;
  border-radius: 20px;
}

.ArticleCardTitleDes h3 {
  margin-top: .5rem;
  color: #222;
  font-size: 20px;
  font-weight: 700;
  line-height: 130%;
  font-family: 'Montserrat';
}


.ArticleCardTitleDes h3:hover {
 text-decoration: underline;
 cursor: pointer;
}

.ArticleCardTitleDes p {
  color: #7e7e7e;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
  /* 19.6px */
  margin: 0;
  font-family: 'Montserrat';
}


.Article_readMore {
  color: #222;
  font-family: 'Montserrat';
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 130%;
  /* 20.8px */
}

.main_article_cardHead__des {
  min-height: 145px;
}


.mbl_articleCard {
  display: none !important;
}

.mbl_ArticleSearchBar {
  display: none;
}

.mbl_ArticleBannerCard {
  display: none;
}


@media(max-width:968px) {

  .ArticleMCD_none {
    display: none;
  }

  .AllArticleRead_cards {
    margin-top: 0 !important;
    gap: 20px;
  }

  .ArticleCard {
    display: none;
  }

  .mbl_articleCard {
    display: flex !important;
    width: 100%;
  }

  .mbl_articleCard_content {
    width: calc(100% - 107px);
  }

  .mbl_articleCard_content h3 {
    color: var(--Black-222222, #222);
    font-family: 'Montserrat';
    font-size: 16px !important;
    font-style: normal;
    font-weight: 600;
    line-height: 130%;
    /* 20.8px */
  }

  .mbl_articleCard_content p {
    color: #7e7e7e;
    font-family: 'Montserrat';
    font-size: 12px !important;
    font-style: normal;
    font-weight: 500;
    line-height: 130%;
    margin-bottom: 4px !important;
  }

  .ArticleMblCard_Image {
    width: 97px;
    margin-left: 8px;
  }

  .ArticleMblCard_Image img {
    height: 100%;
    width: 100%;
    border-radius: 10px;
    object-fit: cover;
  }

  .articlembl_readTime {
    color: var(--Dark-Gray, #7E7E7E);
    font-family: Montserrat;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
    /* 16.8px */
  }
}


@media(max-width:768px) {

  .Article_cardsSection {
    width: 100%;
    margin-top: 1rem;
  }

  .AllArticleRead_cards {
    margin-top: 20px !important;
  }

  .Article_Filter_andButton {
    padding: 10px;
    margin-top: 10px;
  }

  .Article_filter_section {
    width: 100%;
    gap: 8px;
    flex-direction: row !important;
  }

  .BlogPathBtn {
    color: #222;
    border-radius: 36px;
    border: 1px solid #D9D9D9;
    background: #FFF;
    width: fit-content;
    padding: 10px;
    font-size: 14px;
  }

  .Blog_ActiveBtn {
    color: #fff;
    background-color: #222;
    border: none;
  }

  .Article_card_button {
    display: block !important;
  }

  .ArticleMSD_none {
    display: none !important;
  }

  .mbl_ArticleSearchBar {
    display: block;
    margin: 1rem auto 0 auto;
    width: 96%;
    border: 1px solid #222;
    border-radius: 31px;
    padding: 5px 10px;
  }

  .mbl_ArticleSearchBar input {
    border: none;
    outline: none;
    width: calc(100% - 30px);
    font-family: Montserrat;
    color: #222;
  }

  .mbl_ArticleSearchBar input::placeholder {
    font-family: Montserrat;
    color: #222;
  }

  .mbl_ArticleBannerCard {
    display: block;
    width: 95%;
    margin: 10px auto 0 auto;
  }

  .article_bannerCategory {
    background-color: #fff;
    width: fit-content;
    padding: 8px;
    position: absolute;
    top: 10px;
    left: 10px;
    border-radius: 27px;
  }

  .article_bannerCategory p {
    font-family: Montserrat;
    color: #7e7e7e;
    margin: 0 !important;

  }

  .Article_banner_content {
    font-family: Montserrat;
    padding-right: 10px;
    color: #fff;
    position: absolute;
    left: 10px;
    bottom: 10px;
  }

  .Article_banner_content h4 {
    font-size: 16px;
    font-weight: 700;
    line-height: 130%;
  }

  .Article_banner_content p {
    font-size: 12px;
    font-weight: 500;
    line-height: 140%;
    margin: 0 !important;
  }
}

@media(max-width:512px) {
  .mbl_articleCard_content p{
    display: none;
  }
}


@media(max-width:420px) {
  .BlogPathBtn {
    font-size: 12px;
  }

}