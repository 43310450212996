.ReponsiveWidth {
    width: unset;
}

.Mobile__Justigy__Start {
    align-items: start;
}


@media(max-width:800px){
    .fav_scrollLoader{
        width: 100% !important;
    }

}

@media(max-width: 786px) {
    .fav_marginTop{
        margin: 4rem 0 0 0 !important;
        left: 0 !important;
        width: 100% !important;
    }
}


@media(max-width: 768px) {
    .Mobile__Justigy__Start {
        align-items: center !important;

    }

    .mob_favHeadings h3 {
        margin-top: 20px !important;
        margin-bottom: 15px !important;
        font-size: 24px !important;
        text-align: center !important;
    }

    .mob_favHeadings p {
        font-size: 16px !important;
        text-align: center !important;
    }

    .mob_fav_card {
        display: flex !important;
        flex-wrap: wrap !important;
        justify-content: center !important;
        align-items: center !important;
        gap: 10px !important;
    }

    .ReponsiveWidth {
        width: 100%;
    }

}

@media(max-width: 512px) {
    .mob_fav_card {
        gap: 0px !important;
    }
}