.realtor_main_container {
    font-family: "Open Sans";
}


.color_Primary_Natural_blue {
    color: var(--Primary-Natural-blue, #007BFF) !important;
}


.color_Dark_Gray {
    color: var(--Dark-Gray, #7E7E7E) !important;
}

.color_Accent_Black {
    color: var(--Accent-Black, #282828) !important;
}

.realtor_profile_info p {
    color: var(--Accent-Dark-Gray, #4A4A4A);
    font-weight: 400;
    line-height: 150%;
    letter-spacing: 0.48px;
    margin-bottom: 3px;
}

.pro_realtor_profile {
    font-size: 20px;
}

.pro_realtor_profile {
    font-size: 20px;
}

.realtor_profile_info h2 {
    color: var(--Accent-Black, #282828);
    font-size: 49px;
    font-weight: 700;
    line-height: 120%;
}

.realtor_profile_Image img {
    width: 100%;
    height: 100%;
    border-radius: 16px;
}

.realtor_about_columns {
    column-count: 3;
}

.realtor_about_subHeading {
    color: var(--Black-222222, #222);
    font-size: 20px;
    font-weight: 700;
    line-height: 120%;
}

.realtor_profile_eduInfo {
    width: calc(33.33% - .5rem);
}


.realtor_Header_slider {
    max-width: 48rem;
    margin: auto;
    --slide-height: 19rem;
    --slide-spacing: 1rem;
    --slide-size: 100%;
    background-color: #F4F4F5;
    border-radius: 16px;
    height: 100%;
}

.realtor_Header_slider_viewPort {
    overflow: hidden;
    height: calc(100% - 85px);
}

.realtor_Header_slider_container {
    display: flex;
    touch-action: pan-y pinch-zoom;
    margin-left: calc(var(--slide-spacing) * -1);
}

.realtor_Header_slider_slide {
    transform: translate3d(0, 0, 0);
    flex: 0 0 var(--slide-size);
    min-width: 0;
    padding-left: var(--slide-spacing);
}

.realtor_Header_slider_controls {
    display: flex;
    justify-content: space-between;
    padding: 20px 10px;
}

.realtor_Header_slider_btn {
    display: flex;
    grid-template-columns: repeat(2, 1fr);
    gap: 12px;
    align-items: center;
}

.realtor_Header_slider_dots {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    align-items: center;
    margin-right: calc((2.6rem - 1.4rem) / 2 * -1);
}

.realtor_Header_slider_dot {
    -webkit-appearance: none;
    appearance: none;
    background-color: #E2E2E2;
    touch-action: manipulation;
    display: inline-flex;
    text-decoration: none;
    cursor: pointer;
    border: 0;
    padding: 0;
    margin: 0 4px 0 0;
    width: 12px;
    height: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
}

.realtor_Header_slider_dot:after {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    content: '';
}

.realtor_Header_slider_dot--selected:after {
    background-color: #000;
}

.realtor_slider_controlBtn {
    width: auto;
    height: auto;
    padding: 12px;
    position: relative;
}

.realtor_slider_prevBtn {
    left: 0;
}

.realtor_slider_nextBtn {
    right: 0;
}

.realtor_slider_controlBtn:hover {
    border-color: #000;
}




.realtor_slider_controlBtn:disabled,
.realtor_slider_controlBtn[disabled] {
    cursor: not-allowed;
    border: 1px solid rgb(215, 215, 215);
    display: block;
}


.realtor_slider_controlBtn:disabled svg path,
.realtor_slider_controlBtn[disabled] svg path {
    fill: rgb(215, 215, 215);
}

.realtor_slider_text h4 {
    color: var(--Accent-Black, #282828);
    font-size: 20px;
    font-weight: 400;
    line-height: 120%;
}

.realtor_slider_text h3 {
    color: var(--Primary-Natural-blue, #007BFF);
    font-size: 25px;
    font-weight: 700;
    line-height: 120%;
}

.Retailer_history_table {
    border-spacing: 0;
    border-collapse: separate;
    border-radius: 16px;
    border: 1px solid var(--Accent-Medium-Gray, #E2E2E2);

}

.Retailer_history_table thead tr th {
    border-bottom: 1px solid var(--Accent-Medium-Gray, #E2E2E2);
}

.Retailer_history_table tbody tr:not(:last-child) td {
    border-bottom: 1px solid var(--Accent-Medium-Gray, #E2E2E2);
}

.Retailer_history_table th {
    padding: 12px 10px;
    color: var(--Accent-Dark-Gray, #676767);
    font-size: 20px;
    font-weight: 700;
    line-height: 120%;
}

.Retailer_history_table tr {
    border-bottom: 1px solid #EAECF0;
}

.Retailer_history_table td {
    padding: 12px 10px;
    color: var(--Accent-Gray, #676767);
    font-size: 16px;
    font-weight: 400;
    line-height: 150%;
    letter-spacing: 0.48px;
}

.Retailer_history_table .history_average_price {
    color: var(--Accent-Black, #282828);
    font-weight: 700;
}

.realtor_duration_tabs {
    gap: 0px !important;
}

.realtor_duration_tabs .realtor_transaction_duration {
    border: none !important;
    color: #676767 !important;
    padding: 10px 5px !important;
    border-radius: 0px !important;
    min-width: fit-content !important;
}

.realtor_duration_tabs .Mui-selected {
    background-color: transparent !important;
    border-bottom: 1.5px solid #282828 !important;
    color: #282828 !important;
}


.realtor_articleCard img {
    width: 100%;
    border-radius: 20px;
    object-fit: cover;
}

.realtor_articleCard .postDate {
    color: var(--Primary-Natural-blue, #007BFF);
    font-size: 13px;
    font-weight: 400;
    line-height: 140%;
    margin: 10px 0px;
}

.realtor_articleCard .title {
    color: #101828;
    font-size: 20px;
    font-weight: 700;
    line-height: 120%;

}

.realtor_articleCard .description {
    color: var(--Accent-Black, #282828);
    font-size: 16px;
    font-weight: 400;
    line-height: 150%;
    letter-spacing: 0.48px;
}

.realtor_articleCard .chip {
    border-radius: 16px;
    margin-bottom: 0px;
    padding: 2px 10px;
    background-color: #F9F5FF;
    color: #007BFF;
}

.realtor_articleCard .read_chip {
    background-color: #EEF4FF;
    color: #0253A0;
}

.realtor_articleCard .category_chip {
    color: #C11574;
    background-color: #FDF2FA;
}


.realtor_articleSlider .embla__slide {
    width: 100%;
    max-width: 300px;
    min-width: 300px;
}

.realtor_neighbourSale_container {
    column-count: 2;
    column-gap: 10px;
}

.realtor_neighbourSale img {
    width: 100%;
    border-radius: 10px;
    object-fit: cover;
}


.realtor_neighbourSale .sales_inf {
    bottom: 10px;
    left: 3%;
    border-radius: 6px;
    border: 0.581px solid rgba(255, 255, 255, 0.50);
    background: rgba(255, 255, 255, 0.30);
    backdrop-filter: blur(6.975045204162598px);
    padding: 13.95px 11.625px;
    width: 94%;
}

.realtor_neighbourSale .sales_inf h5 {
    color: var(--Accent-White, #FFF);
    font-size: 20px;
    font-weight: 700;
    line-height: 120%;
}

.realtor_neighbourSale .sales_inf p {
    color: var(--Accent-White, #FFF);
    font-size: 16px;
    line-height: 120%;
    margin-bottom: 0px;
    margin-top: 5px;
}

.realtor_neighbourSale .small_image {
    height: 230px;
}

.realtor_neighbourSale .big_image {
    height: 416px;
}

@media(max-width: 768px) {

    .Retailer_history_table th {
        font-size: 18px !important;
    }

    .Retailer_history_table td {
        font-size: 14px !important;
    }

    .realtor_profile_eduInfo {
        width: calc(50% - .5rem)
    }

    .realtor_neighbourSale_container {
        column-count: 1;
    }

    .realtor_neighbourSale .sales_inf h5 {
        font-size: 18px;
    }

    .realtor_neighbourSale .sales_inf p {
        font-size: 14px;
    }
}


@media(max-width: 512px) {
    .realtor_profile_eduInfo {
        width: 100%;
        padding-bottom: 0px !important;
    }

    .realtor_slider_controlBtn {
        padding: 8px !important;
    }

    .realtor_slider_controlBtn svg {
        width: 20px !important;
        height: 20px !important;
    }

    .realtor_Header_slider_dot,
    .realtor_Header_slider_dot--selected:after {
        width: 10px !important;
        height: 10px !important;
    }

    .carousel_disabledBtn .realtor_slider_controlBtn:disabled,
    .carousel_disabledBtn .realtor_slider_controlBtn[disabled] {
        width: 38px !important;
        height: 38px !important;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0px !important;
    }
}