.AboutUsMain_container {
    font-family: "Montserrat";
}

.AboutUsMain_image {
    background: black;
}

.AboutUsMain_image img{
  min-height: 300px;
}

.AboutUs_HeadingContainer {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #fff;
    text-align: center;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 20px;
}

.AboutUs_heading {
    font-size: 16px;
    font-weight: 600;
}

.AboutUsDes_heading {
    font-size: 48px;
    font-weight: 600;
}

.AboutUs_des {
    font-size: 20px;
    font-weight: 400;
    max-width: 800px;
    line-height: 30px;
}

.AboutUs_content {
    padding: 4rem 20px;
    max-width: 850px;
    text-align: center;
}


.AboutUs_content h3 {
    color: #121212;
    font-size: 48px;
    font-weight: 600;
    line-height: 60px;
    /* 125% */
    letter-spacing: -0.96px;
}

.AboutUs_content p {
    color: #121212;
    font-size: 20px;
    font-weight: 400;
    line-height: 30px;
    /* 150% */
}


.aboutStatisticsHeading {
    color: #101828;
    font-size: 48px;
    font-weight: 600;
    line-height: 60px;
    /* 125% */
    letter-spacing: -0.96px;
}

.aboutTotalStatistics {
    text-align: center;
}

.aboutTotalStatistics h4 {
    color: #02549E;
    font-size: 60px;
    font-weight: 600;
    line-height: 72px;
    letter-spacing: -1.2px;
}

.aboutTotalStatistics h6 {
    color: #101828;
    font-size: 18px;
    font-weight: 500;
    line-height: 28px;
}

.aboutTotalStatistics p {
    color: #667085;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
}


@media(max-width:768px) {

    .AboutUsMain_image img{
        min-height: 156px;
      }

    .AboutUs_heading {
        display: none;
    }

    .AboutUs_des {
        display: none;
    }

    .AboutUsDes_heading {
        font-size: 24px !important;
    }

    .AboutUs_content h3 {
        font-size: 24px !important;
        line-height: 30px;
        letter-spacing: -0.24px;
    }

    .AboutUs_content p {
        font-size: 14px;
        font-weight: 400;
        line-height: 135%;
        /* 18.9px */
    }

    .aboutTotalStatistics h4 {
        font-size: 48px;
        line-height: 60px; /* 125% */
        letter-spacing: -0.96px;
    }

    .aboutStatisticsHeading {
        font-size: 24px !important;
        line-height: 30px; /* 125% */
        letter-spacing: -0.24px;
    }
    
}