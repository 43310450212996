.HomeMarkting_header {
  background: linear-gradient(0deg, #40c9ff 0%, #40c9ff 100%);
  width: 100%;
  height: 300px;
  position: relative;
}

.homeMarketing_Header_img {
  position: absolute;
  right: 0;
  height: 100%;
}

.marketing_heading {
  color: #000;
  font-style: normal;
  font-weight: 500 !important;
  line-height: normal;
}

.marketing_connect {
  text-align: center;
  width: 50%;
}

.choose_heading {
  color: #000;
  margin-top: 3rem;
  font-family: 'Montserrat';
  font-weight: bold;
}

.waste_time {
  color: #000;
  font-weight: 500 !important;
}

.faqs_heading {
  color: #000;
  font-family: Montserrat;
  font-weight: bold;
}

.finance_optionsHeading {
  color: #000;
  font-weight: bold;
}

.dollarIcon_img {
  height: 100px;
  width: 100px;
}

.marketing_Asking {
  color: #0253A0;
  font-style: normal;
  font-weight: 600 !important;
  line-height: normal;
}

.asking_query_des {
  color: #676767;
  font-size: 16px;
  margin-top: 1rem;
}

.Marketing_poneInput {
  padding: 6px 12px !important;
  border: 1px solid #bfbcbc !important;
}

.Marketing_poneInput:hover {
  border: 1px solid black !important;
}

.Marketing_poneInput:focus-within {
  border: 2px solid rgb(52, 123, 255) !important;
}


.faq_mobile_font {
  margin: 0 !important;
}

.faqMobile_des_font {
  margin: 0 !important;
}


.financeOption_dollarIconHeading {
  color: #000;
  font-weight: 700;
  font-size: 2rem;
  font-family: 'Montserrat';
}

.SeeMore_sellPropBtn {
  color: #fff;
  border-radius: 37px;
  background: #02549E;
  font-family: 'Montserrat';
  padding: 16px 20px;
  font-size: 16px;
  border: none;
  outline: none;
  margin-top: 1rem;
}

.SeeMore_sellPropBtn:hover {
  background: #024e91;
}

.query_contact_form {
  max-width: 70%;
  margin-left: 2rem;
}

.LocalOffice_des {
  max-width: 70%;
}


.WhoWillShowModalCheckBox {
  width: 24px !important;
  height: 24px !important;
  color: #D9D9D9 !important;
}

.WhoWillShowModalCheckBox_heading {
  color: #02549E;
  text-align: center;
  font-family: Montserrat;
  font-size: 32px;
  font-weight: 600;
  text-align: center;
}

.WhoWillShowModalCheckBox_font {
  color: #676767 !important;
  font-family: Montserrat;
  font-size: 14px;
  padding-left: 5px;
}

.WhoWillShowModalCheckBox_button {
  outline: none;
  border: none;
  padding: 15px;
  border-radius: 4px;
  background: #02549E;
  color: #FFF;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 700;
  width: 100%;
  margin-top: 1rem;
}

.WhoWillShowModalCheckBox_button:hover {
  background: #004f94;

}

.WhoWillShowModalCheckBox_input input {
  padding: 10px !important;
}

.WhoWillShowModalCheckBox_Phoneinput {
  padding: 7px;
}

@media (max-width: 1100px) {
  .query_contact_form {
    max-width: 100%;
    margin-left: 0px;
    width: 100%;
  }

  .LocalOffice_des {
    max-width: 100%;
    width: 100%;
  }
}

@media (max-width: 900px) {
  .marketing_mainHeading {
    margin: 0px !important;
  }
}

@media (max-width: 768px) {
  .HomeMarkting_header {
    height: 180px;
  }

  .marketing_Asking {
    font-size: 24px !important;
  }

  .homeMarketing_Header_img {
    width: 250px;
    height: 195px;
  }

  .marketing_mainHeading {
    margin: 0px !important;
    padding-left: 50px !important;
  }

  .marketing_mainHeading h1 {
    font-size: 32px !important;
    margin: 0px !important;
  }

  .marketing_connect {
    width: 80%;
  }

  .choose_heading {
    font-size: 32px;
    margin-bottom: 3rem !important;
  }

  .Faq_main {
    width: 75% !important;
  }

  .marketing_form {
    display: flex;
    flex-direction: column !important;
    gap: 10px !important;
    padding-left: 20px !important;
    padding-right: 20px !important;
  }

  .marketingform_inf {
    flex-direction: column !important;
  }

  .marketing_nameInput {
    width: 100% !important;
    padding: 0px !important;
  }

  .financeOption_dollarIconHeading {
    font-size: 24px !important;
  }

  .asking_query_des {
    margin-top: 0.5rem !important;
  }

  .Query_contact_img {
    min-height: auto !important;
    margin-top: 1rem !important;
  }

  .mobile_finance_option {
    display: none
  }

  .mob_pricing_cardNo_pad {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .WhoWillShowModalCheckBox_img_container {
    display: none;
  }

  .WhoWillShowModalCheckBox_heading {
    font-size: 24px !important;
  }

  .WhoWillShowModalCheckBox_font {
    font-size: 12px;
  }

  .WhoWillShowModalCheckBox_input input::placeholder {
    font-size: 12px !important;
  }

  .WhoWillShowModalCheckBox_input input {
    font-size: 12px !important;
  }

  .WhoWillShowModalCheckBox_Phoneinput {
    font-size: 12px !important;
  }

  .WhoWillShowModalCheckBox_LastNameinput {
    margin-top: 10px;
  }

  .offerAndNegotation_Modal {
    display: flex;
    flex-direction: column-reverse;
  }
}

@media (max-width: 600px) {
  .HomeMarkting_header {
    height: 112px;
  }

  .homeMarketing_Header_img {
    width: 162px;
    height: 115px;
  }

  .marketing_mainHeading {
    margin: 0px !important;
    padding-left: 50px !important;
  }

  .marketing_mainHeading h1 {
    font-size: 20px !important;
    margin: 0px !important;
  }

  .Faq_main {
    width: 85% !important;
  }

  .faq_mobile_font {
    font-size: 16px !important;
  }

  .faqMobile_des_font {
    font-size: 14px !important;
  }

  .SeeMore_sellPropBtn {
    font-size: 12px;
  }
}

@media (max-width: 400px) {
  .marketing_mainHeading {
    padding-left: 18px !important;
  }
}

@media(max-width:512px) {
  .offerNegotationsModalImg {
    max-height: 350px;
  }

}