.resetheading {
  font-weight: 600;
  font-size: 32px;
  padding-top: 30px;
}
.resettext {
  font-weight: 600;
  font-size: 15px;
}
.Resetbutton {
  background-color: #0253a0;
  color: white;
  border: 0px;
  height: 40px;
  width: 386px;
  padding: 4px;
  border-radius: 4px;
  font-weight: 600;
  font-size: 16px;
  font-family: "Montserrat";
}
.back2login {
  font-size: 14px;
  font-weight: 500;
  font-family: "Montserrat";
}

.textFieldmain {
  padding-top: 20px;
}

.fieldhead {
  padding-top: 20px;
}

.CreateButton {
  margin-top: 25px !important;
}

@media (max-width: 800px) {
  .reset_container {
    width: 346px !important;
  }
  .imagecentered {
    display: flex !important;
  }
  .reset_heading{
    font-size: 24px !important;
    margin-top: 2rem !important;
    text-align: center !important;
  }
  .reset_fieldSetting{
    width: 100% !important;
  }
}

@media (max-width: 525px) {
  .imagecentered img {
    width: 113px !important;
    height: 76px !important;
  }

  .reset_button{
    height: auto !important;
  }
}
