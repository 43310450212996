.topContainer {
  background-color: #02549e;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}


.contentHeading {
  color: #02549e;
  font-family: Montserrat;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-top: 2rem;
}

.contentPara {
  color: #000;
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 170%;
}

.contentList{
    list-style: disc;
}


@media (max-width: 800px) {

  .contentHeading {
    font-size: 22px;
    margin-top: 2rem !important;
  }

}
