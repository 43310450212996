@keyframes fadeIn {
    from {
        transform: translate(-50%, 100%);
    }

    to {
        transform: translate(-50%, -50%);
    }
}


@keyframes slideInMobile {
    from {
        transform: translate(-0%, 100%);
    }

    to {
        transform: translate(-0%, 0%);
    }
}


@keyframes fadeOut {
    from {
        transform: translate(-50%, 0%);
    }

    to {
        transform: translate(-50%, 100%);
    }
}

@keyframes fadeOutMobile {
    from {
        transform: translate(-0%, 0%);
    }

    to {
        transform: translate(-0%, 100%);
    }
}

@keyframes gradientBackground {
    0% {
        background: var(--Primary-Natural-blue, #007BFF);
        background-position: 100% 0;
    }

    25% {
        background: linear-gradient(90deg, #FFEAD1 0%, #CEE5FF 100%);
        background-position: 100% 0;
        color: #282828;
    }

    50% {
        background: linear-gradient(90deg, #FFEAD1 0%, #CEE5FF 100%);
        background-position: 0 0;
        color: #282828;
    }

    75% {
        background: var(--Primary-Natural-blue, #007BFF);
        color: var(--Accent-White, #FFF);
        background-position: 0 0;
    }

    100% {
        background: var(--Primary-Natural-blue, #007BFF);
        color: var(--Accent-White, #FFF);
        background-position: 100% 0;
    }
}


@keyframes fillAnimation {
    from {
        width: 0;
    }

    to {
        width: 100%;
    }
}


:root {
    --Primary-Natural-blue: #007BFF;
    --Primary-Light-blue: #E2F1FF;
}

.Propert_details_main_container {
    font-family: "Open Sans";
    padding-top: 20px;
}

.property_Details_heading {
    color: var(--Accent-Black, #282828);
    font-size: 25px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%;
    margin: 0px;
    /* 37.2px */
}

.property_amenities {
    color: var(--Accent-Black, #282828);
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    /* 22.4px */
}

.property_Highlights_details {
    color: var(--Accent-Dark-Gray, #676767);
    text-align: justify;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
}

.property_incetives_columns {
    column-count: 2;
}

.Property_pricingIconHeading {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 1.5rem;
}

.Mimico_pricing_subHeading {
    color: var(--Accent-Black, #282828);
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%;
    font-family: "Open Sans";
}

.Mimico_pricing_sub_Headings {
    color: var(--Accent-Black, #282828);
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%;
    font-family: "Open Sans";
}

.Mimico_pricing_subContent {
    color: var(--Accent-Dark-Gray, #676767);
    font-size: 16px;
    font-weight: 400;
    line-height: 150%;
    font-family: "Open Sans";
}

.preconstruction_complete_time {
    color: var(--Accent-Dark-Gray, #282828);
    font-size: 20px;
    font-weight: 400;
    line-height: 120%;
}

.preconstruction_complete_time strong {
    font-weight: 700;
}

.property_details_mainImage {
    position: relative;
    height: 74vh;
}

.property_details_mainImage img {
    border-radius: 12px;
}


.property_information_DetailsIcon {
    gap: 20px
}

.property_information_DetailsIcon p {
    display: flex;
    align-items: center;
    margin: 0;
    min-width: calc(33% - 20px);
}

.property_information_DetailsIcon p span {
    color: var(--Accent-Black, #282828);
    font-size: 16px;
    font-weight: 700;
    /* line-height: 140%; */
    padding-left: 8px;
    font-family: "Open Sans";
}


.property_details_main_information h1 {
    color: var(--Accent-Black, #282828);
    font-size: 49px;
    font-weight: 700;
    line-height: 120%;
    font-family: 'Open Sans';
}

.property_details_main_information h5 {
    color: var(--Accent-Black, #282828);
    font-size: 20px;
    font-weight: 400;
    line-height: 120%;
    font-family: 'Open Sans';
}

.property_details_main_information h5 span {
    text-decoration: underline;
}

.property_details_pricing h6 {
    color: var(--Accent-Gray, #676767);
    font-family: "Open Sans";
    font-size: 16px;
    font-weight: 600;
    line-height: 22px;
    margin: 10px 0 0 0;
}

.property_details_pricing h6 span {
    font-weight: 700;
    font-size: 20px;
}

.details_contactUs_des {
    color: var(--Accent-Black, #282828);
    font-family: "Open Sans";
    font-size: 16px;
    font-weight: 400;
    line-height: 150%;
}

.Property_detailsAddress h6 {
    color: var(--Accent-Gray, #676767);
    font-family: "Open Sans";
    font-size: 16px;
    font-weight: 500 !important;
    line-height: 150%;
    margin: 0px;
}


.propertyDetails_moreInfo {
    outline: none;
    border: none;
    padding: 10px 16px;
    border-radius: 10px;
    background: var(--Primary-Natural-blue, #007BFF);
    color: var(--Accent-White, #FFF);
    font-family: "Open Sans";
    font-size: 16px;
    font-weight: 500;
    line-height: 120%;
    width: fit-content;
}

.DetailsLight_bgBtn {
    color: var(--Primary-Natural-blue, #007BFF);
    background: var(--Primary-Light-blue, #E2F1FF);
}

.propertyDeatils_scrolldownBtn {
    outline: none;
    border: none;
    background-color: transparent;
    color: var(--Accent-Black, #282828);
    font-family: "Open Sans";
    font-size: 25px;
    font-weight: 400;
    line-height: 120%;
}


.Property_details_tabs .css-heg063-MuiTabs-flexContainer {
    justify-content: flex-start !important;
    gap: 20px !important;
}

.Property_details_tabs .css-k008qs {
    justify-content: flex-start !important;
    gap: 20px !important;
}

.Property_details_tabs .MuiTab-textColorInherit {
    background: var(--Accent-White, #FFF) !important;
    border-radius: 42px !important;
    border: 1px solid var(--Accent-Medium-Gray, #E2E2E2) !important;
    font-family: "Open Sans" !important;
    color: var(--Accent-Black, #282828) !important;
    font-size: 16px !important;
    font-weight: 600 !important;
    line-height: 120% !important;
    text-transform: capitalize !important;
    padding: 10px 16px !important;
    opacity: 1 !important;
    min-height: auto !important;
}

.Property_details_tabs .Mui-selected {
    font-family: "Open Sans" !important;
    color: var(--Accent-Black, #282828) !important;
    font-weight: 700 !important;
    border-radius: 42px !important;
    border: 1px solid var(--Accent-Medium-Gray, #E2E2E2) !important;
    background: var(--Accent-Light-Gray, #F4F4F5) !important;
    font-size: 16px !important;
}

.Property_details_tabs .MuiTabs-indicator {
    display: none !important;
}

.Property_details_tabs .css-145v6pe-MuiButtonBase-root-MuiTabScrollButton-root.Mui-disabled {
    display: none !important;
}

.Property_details_tabs .css-1q0ig8p.Mui-disabled {
    display: none !important;
}

.Property_details_tabs .MuiSvgIcon-root {
    font-size: 30px !important;
}

.PropertyDetails_Buy_reason {
    color: var(--Accent-Dark-Gray, #4A4A4A);
    text-align: justify;
    font-family: "Open Sans";
    font-size: 16px;
    font-weight: 400;
    line-height: 150%;
    letter-spacing: 0.48px;
}

.PropertyDetail_tabPanel .MuiBox-root {
    padding: 10px 0 !important;
}

.propertyDetail_homeValueCard {
    padding: 16px;
    border-radius: 10px;
    border: 1px solid var(--Accent-Medium-Gray, #E2E2E2);
}

.propertyDetail_homeValue_icon {
    width: fit-content;
    padding: 12px;
    border-radius: 50%;
    border: 8px solid;
    border-color: var(--Success-50, #ECFDF3);
    background: var(--Light-green, #D1FADF);
    height: fit-content;
}

.propertyDetail_homeValueCard h6 {
    color: var(--Accent-Black, #282828);
    font-family: "Open Sans";
    font-size: 16px;
    font-weight: 700;
    line-height: 120%;
}

.propertyDetail_homeValueCard p {
    color: var(--Accent-Dark-Gray, #4A4A4A);
    font-family: "Open Sans";
    font-size: 16px;
    font-weight: 400;
    line-height: 150%;
}

.propertyDetail_homeValueCard p strong {
    font-weight: 600;
}

.market_activityCardHeading {
    font-size: 20px !important;
}

.propert_detail_progress_text {
    color: var(--Accent-Dark-Gray, #676767);
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
}

.Detail_showMoreBtn {
    color: var(--Accent-Gray, #676767);
    font-family: "Open Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%;
    outline: none;
    border: none;
    background-color: transparent;
    position: relative;
    width: fit-content;
    display: flex;
    align-items: center;
    margin-left: auto;
}

.Property_details_faq_box {
    border-top: 1px solid var(--Accent-Medium-Gray, #E2E2E2) !important;
    background: var(--Accent-White, #FFF) !important;
    box-shadow: none !important;
    padding: 20px 0px !important;
    margin: 0 !important;
}

.Property_details_faq_box .css-1c35hjw-MuiPaper-root-MuiAccordion-root:first-of-type {
    border-radius: 0 !important;
}

.Property_details_faq_box:last-of-type {
    border-bottom: 1px solid var(--Accent-Medium-Gray, #E2E2E2) !important;
}


.Property_details_faq_box .MuiAccordionSummary-content {
    margin-bottom: 0px !important;
    margin-top: 0px !important;
}

.Property_details_faq_box .MuiAccordionSummary-root {
    min-height: auto !important;
    padding: 0 !important;
}

.Property_details_faq_box::before {
    background-color: transparent !important;
}

.Property_details_faq_box .MuiAccordionDetails-root {
    padding: 25px 0 0 !important;
}

.Property_details_faq_question {
    color: var(--Accent-Black, #282828) !important;
    font-family: "Open Sans" !important;
    font-size: 21px !important;
    font-weight: 400 !important;
    line-height: 120% !important;
}

.Property_details_faq_answer {
    color: var(--Accent-Dark-Gray, #4A4A4A) !important;
    font-family: "Open Sans" !important;
    font-size: 16px !important;
    font-weight: 400 !important;
    line-height: 150% !important;
    letter-spacing: .48px !important;
}

.Property_detail_more_icon {
    display: inline-block;
    transition: transform 0.2s ease-in-out;
}

.Property_detail_Less_icon {
    transform: rotate(180deg);
    padding-right: .25rem;
}

.property_floorPlan_Beds {
    padding: 4px 10px;
    display: flex;
    align-items: center;
    width: fit-content;
    border-radius: 16px;
    background: var(--Success-50, #ECFDF3);
    color: #027545;
    font-family: "Open Sans";
    font-size: 16px;
    font-weight: 400;
    line-height: 150%
}

.Property_floorPlans_NameDetails {
    gap: 8px;
}

.Property_floorPlans_NameDetails img {
    width: 48px;
    height: 48px;
}


.Property_floorPlans_NameDetails h6 {
    color: var(--Accent-Black, #282828);
    font-family: "Open Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%;
    margin-bottom: 2px;
}

.Property_floorPlans_NameDetails p {
    color: var(--Accent-Dark-Gray, #676767);
    font-family: "Open Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    text-align: start;
}

.property_floorPlan_price {
    color: var(--Accent-Black, #282828) !important;
    font-family: "Open Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%;
}


.Property_floorPlan_table {
    min-width: 650px;
}


.Property_floorPlan_table thead {
    border-bottom: 1px solid #E2E2E2;
    padding: .5rem;
    background: var(--Gray-25, #F4F4F5);
}


.Property_floorPlan_table th {
    text-align: start;
    padding: 10px;
    color: var(--Accent-Dark-Gray, #676767);
    font-family: "Open Sans";
    font-size: 16px;
    font-weight: 500;
    line-height: 150%;
}

.Property_floorPlan_table tr {
    border-bottom: 1px solid #EAECF0;
}

.Property_floorPlan_table td {
    padding: 16px 10px;
    text-align: start;
}

.prop_doenloadFloorPlan_btn {
    border-radius: 10px;
    padding: 10px 16px;
    font-size: 16px;
}

.Prop_detailsFloorPlan_selectBtnGroup {
    width: 100%;
    overflow: auto;
    scrollbar-width: none;
    -ms-overflow-style: none;
}

.Prop_detailsFloorPlan_selectBtnGroup::-webkit-scrollbar {
    display: none;
}


.Prop_detailsFloor_plan_selectBtn {
    outline: none;
    border: 1px solid var(--Accent-Medium-Gray, #E2E2E2);
    padding: 10px 16px;
    color: var(--Accent-Black, #282828);
    font-family: "Open Sans";
    font-size: 16px;
    font-weight: 600;
    line-height: 150%;
    background-color: transparent;
    border-radius: 10px 0px 0px 10px;
    border: 1px solid var(--Accent-Medium-Gray, #E2E2E2);
}

.Prop_detailsFloor_plan_selectBtn:hover {
    background: var(--Accent-Light-Gray, #F4F4F5);
    color: var(--Accent-Black, #282828);
    border: 1px solid var(--Accent-Medium-Gray, #E2E2E2);
}

.floor_plan_buildingSelectBtn {
    background: var(--Accent-Light-Gray, #F4F4F5);
    color: var(--Accent-Black, #282828);
    border: 1px solid var(--Accent-Medium-Gray, #E2E2E2);
}


.prop_details_getMoreInfo {
    font-family: "Open Sans";
}

.prop_details_getMoreInfo h6 {
    color: var(--Accent-Black, #282828);
    font-size: 25px;
    font-weight: 700;
    line-height: 120%;
}


.prop_details_getMoreInfo p {
    color: var(--Accent-Dark-Gray, #676767);
    font-size: 16px;
    font-weight: 400;
    line-height: 150%;
    margin-top: 1.2rem;
}

.prop_details_getMoreInfo button {
    border-radius: 10px;
    font-size: 16px;
    font-weight: 700;
    width: 100%;
    padding: 12px;
    margin-top: 1.3rem;
}

.PropertyDetail_comment {
    max-width: 480px;
    font-family: "Open Sans";
    width: 100%;
}

.PropertyDetail_comment h6 {
    color: var(--Accent-Black, #282828);
    font-size: 16px;
    font-weight: 400;
    line-height: 150%;
    margin: 0.3rem 0 0.9rem 0;
}


.PropertyDetail_comment button {
    border-radius: 10px;
    font-size: 16px;
    padding: 10px 16px;
}



.Details_SearchesLink  {
    color: var(--Accent-Dark-Gray, #4A4A4A);
    font-size: 16px;
    font-weight: 400;
    line-height: 140%;
    width: fit-content;
}


.Details_SearchesLink:hover {
    color: var(--Accent-Black, #282828);
    text-decoration: underline;
    cursor: pointer;
}

.propDetails_chartsStatistics {
    list-style: none;
    padding: 0;
    gap: 0px;
    column-count: 2;
    margin: 0;
}


.pie_middle_text {
    fill: var(--Accent-Dark-Gray, #676767);
    font-family: "Open Sans";
    font-size: 16px;
    font-weight: 400;
    line-height: 150%;
}

.pie_middle_total_text {
    fill: var(--Accent-Black, #282828);
    font-size: 20px;
}


.prop_represent_linkStatic {
    top: 125px;
    transition: all ease .4s;
}

.Prop_secHeaderTop {
    top: 0px;
    z-index: 1200;
    transition: all ease .4s
}

.Stick_headerOnly {
    top: 70px;
    transition: all ease .4s;

}

.Prop_header_static {
    top: 55px;
    z-index: 100;
    transition: all ease .4s;
}


.Demographics_container {
    display: flex;
    align-items: center;
}

.Propert_detailRepresentatorCard {
    height: fit-content !important;
    overflow-y: hidden !important;
    font-family: "Open Sans";
    max-height: fit-content !important;
}


.Propert_detailRepresentatorCard img {
    width: 78px;
    height: 78px;
    border-radius: 78px;
}

.Propert_detailRepresentatorCard h5 {
    color: var(--Accent-Black, #282828);
    font-size: 27px;
    font-weight: 700;
    line-height: 120%;
    margin: 0;
}

.Propert_detailRepresentatorCard p {
    color: var(--Accent-Dark-Gray, #4A4A4A);
    font-size: 16px;
    font-weight: 400;
    line-height: 150%;
    margin: 0;
    letter-spacing: 0.48px;
}

.Propert_detailRepresentatorCard button {
    border-radius: 10px;
    font-size: 18px;
    width: 100%;
    padding: 10px 16px;
}

.Propert_detailRepresentatorCard h6 {
    color: var(--Gray-900, #101828);
    font-family: "Open Sans";
    font-size: 20px;
    font-weight: 700;
    line-height: 120%;
}

.prop_notifyBtn {
    color: var(--Accent-Dark-Gray, #4A4A4A);
    background-color: #fff;
    padding: 10px 0px;
    border: none;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 0px !important;
    cursor: text;
}

.prop_notifyBtn span {
    cursor: pointer;
}


.DetailsIcon_greyBg {
    border-radius: 50%;
    background: var(--Accent-Light-Gray, #F4F4F5);
    padding: 6px;
}


.DetailsIcon_LightBg {
    background: var(--Primary-Light-blue, #E2F1FF) !important;
}

.DetailsIcon_LightBg:hover {
    background: var(--Primary-Light-blue, #E2F1FF);
}


.Prop_details_section_box {
    border-radius: 20px;
    border: 1px solid var(--Accent-Medium-Gray, #E2E2E2);
    background: var(--Accent-White, #FFF);
    padding: 20px;
    min-height: fit-content;
    /* max-height: calc(100vh - 275px); */
    overflow-y: auto;
}


.Prop_details_section_box::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 3px rgba(173, 173, 173, 0.3);
    border-radius: 2px;
    background-color: #F5F5F5;
}

.Prop_details_section_box::-webkit-scrollbar {
    width: 5px;
    background-color: #F5F5F5;
}

.Prop_details_section_box::-webkit-scrollbar-thumb {
    border-radius: 8px;
    -webkit-box-shadow: inset 0 0 4px rgba(116, 116, 116, 0.3);
    background-color: #b6b6b6;
}

.Prop_details_section_box ul {
    list-style: none;
    padding: 0;
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin: 0;
}

.Prop_details_section_box ul li {
    color: var(--Accent-Dark-Gray, #676767);
    text-align: right;
    font-family: "Open Sans";
    font-size: 20px;
    font-weight: 400;
    line-height: 120%;
    cursor: pointer;
}

.Prop_details_section_box ul li:hover {
    color: var(--Primary-Natural-blue, #007BFF);
    font-weight: 700;
}

.Prop_details_section_box ul li .active {
    color: var(--Primary-Natural-blue, #007BFF) !important;
    font-weight: 700 !important;
}

.propDetail_mortgageCalc button {
    outline: none;
    border: none;
    border-radius: 10px;
    background: var(--Primary-Natural-blue, #007BFF);
    padding: 10px 16px;
    color: var(--Accent-White, #FFF);
    font-size: 16px;
    font-weight: 600;
    line-height: 150%;
    font-family: "Open Sans";
}


.propDetail_mortgageCalc .calcNowBtn {
    border: 1px solid var(--Accent-Medium-Gray, #E2E2E2);
    background: var(--Accent-White, #FFF);
    color: var(--Primary-Natural-blue, #007BFF);
}

.Prop_detailMortgage_calc {
    font-family: "Open Sans";
}

.Prop_detailMortgage_calc label {
    color: var(--Accent-Black, #282828);
    font-size: 16px;
    font-weight: 600;
    line-height: 150%;
    margin-bottom: 4px;
    font-family: 'Open Sans';
}

.Prop_detailMortgage_calc input {
    border-radius: 10px;
    border: 1px solid var(--Accent-Medium-Gray, #E2E2E2);
    background: var(--Accent-White, #FFF);
    color: var(--Accent-Dark-Gray, #676767);
    font-family: "Open Sans";
    font-size: 16px;
    font-weight: 500;
    line-height: 150%;
    width: 100%;
    padding: 8px 12px;
}

.Prop_detailMortgage_calc input:focus {
    outline: none;
    border: 1px solid var(--Accent-Black, #282828);
}

.Prop_detailMortgage_calc .Mui-focused .MuiOutlinedInput-notchedOutline {
    border: 1px solid var(--Accent-Black, #282828) !important;
    color: var(--Accent-Black, #282828) !important;
}

.Prop_details_simpleChart_container .MuiSelect-select {
    padding: 0 !important;
    color: var(--Accent-Black, #282828) !important;
    font-family: "Open Sans" !important;
    font-size: 16px !important;
    font-weight: 600 !important;

}

.Prop_details_simpleChart_container .Mui-focused .MuiOutlinedInput-notchedOutline {
    border: 1px solid var(--Accent-Black, #282828) !important;
    color: var(--Accent-Black, #282828) !important;
}

.Prop_detailMortgage_calc .MuiSelect-select {
    padding: 0 !important;
    color: var(--Accent-Dark-Gray, #676767) !important;
    font-family: "Open Sans" !important;
    font-size: 16px !important;
    font-weight: 400 !important;
}


.Prop_detailMortgage_calc h5 {
    color: var(--Accent-Black, #282828);
    font-family: "Open Sans";
    font-size: 16px;
    font-weight: 600;
    line-height: 150%;
}

.Assignment_pricing h5 {
    color: var(--Accent-Gray, #676767);
    font-weight: 500;
}

.Prop_detailMortgage_calc h6 {
    color: var(--Primary-Natural-blue, #007BFF);
    font-family: "Open Sans";
    font-size: 20px;
    font-weight: 700;
    line-height: 120%;
}

.Assignment_pricing h6 {
    color: var(--Accent-Black, #282828);
}

.explore_propertyCard {
    position: relative;
    font-family: "Open Sans";
}

.explore_propertyCard img {
    width: 100px;
    height: 100px;
    border-radius: 10px;
    object-fit: cover;
}

.explore_propertyCard h5 {
    color: #000;
    font-size: 20px;
    font-weight: 700;
    line-height: 120%;
    margin: 6px 0 5px 0;
    width: calc(100% - 35px);
}

.explore_propertyCard h6 {
    color: #757575;
    font-family: "Open Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    margin: 0;
}

.explore_propertyCard h6 span {
    font-weight: 600;
}

.Explore_prop_rating {
    position: absolute;
    top: 0px;
    right: 10px;
    display: flex;
    align-items: center;
}

.Explore_prop_rating span {
    padding-left: 2px;
    color: #000;
    font-size: 16px;
    font-weight: 400;
    line-height: 150%;
}

.explore_propertyCard .prop_transitScore {
    gap: 10px;
    margin: 7px 0;
}

.explore_propertyCard .prop_transitScore p {
    color: var(--Brand-700, #6941C6);
    font-size: 12px;
    font-weight: 500;
    line-height: 18px;
    border-radius: 16px;
    background: var(--Brand-50, #F9F5FF);
    padding: 2px 8px;
    width: fit-content;
}


.Prop_nearByCard p {
    color: var(--Accent-Black, #282828);
    font-size: 20px;
    font-weight: 400;
    line-height: 120%;
    text-decoration: underline;
    margin: 15px 0 0 0;
}

.Prop_nearByCard img {
    border-radius: 16px;
    width: 100%;
    height: 150px;
    cursor: pointer;
}

.property_details_mainImage .heartShareMain {
    position: absolute;
    top: 10px;
    right: 12px;
    display: flex;
    gap: 12px
}

.PropertyHeader_Icon {
    background: var(--Accent-Light-Gray, #F4F4F5);
    border-radius: 50%;
    padding: 10px;
    display: flex;
    align-items: center;
    width: fit-content;
    cursor: pointer;
    height: fit-content;
}

.PropertyHeader_Icon:hover {
    background-color: #f0f0f0;
}



.property_details_mainImage .heartShare .MuiButtonBase-root {
    padding: 0px !important;
}

.prop_detailsBreadCrumbs {
    color: var(--Accent-Dark-Gray, #4A4A4A);
    font-family: "Open Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
}

.prop_detailsBreadCrumbs a {
    text-decoration: underline;
}

.prop_detailsBreadCrumbs a:hover {
    color: var(--Accent-Black, #282828);
}


.embla {
    /* max-width: 48rem; */
    margin: auto;
    --slide-height: 19rem;
    --slide-spacing: 1rem;
    --slide-size: 50%;
    position: relative;
}

.embla__viewport {
    overflow: hidden;
    /* padding-right: 1px; */
}

.embla__container {
    backface-visibility: hidden;
    display: flex;
    gap: 25px;
    touch-action: pan-y pinch-zoom;
}

.embla__slide {
    flex: 0 0 0;
    /* padding-left: 25px; */
}

.embla__slide:first-child {
    padding-left: 0px;
}

.gallery_embla__slide {
    flex: 0 0 100%;
    margin-top: 10px;
    display: flex;
    justify-content: center;
    height: calc(100vh - 100px);
}

.gallery_embla__slide img {
    width: 100%;
    height: 100%;
    max-width: 790px;
    border-radius: 8px;
    object-fit: scale-down;
}

.embla__button {
    -webkit-tap-highlight-color: rgba(49, 49, 49, 0.5);
    -webkit-appearance: none;
    appearance: none;
    background-color: transparent;
    touch-action: manipulation;
    display: inline-flex;
    text-decoration: none;
    cursor: pointer;
    padding: 5px;
    margin: 0;
    width: 27px;
    height: 27px;
    z-index: 1;
    border-radius: 50%;
    color: rgb(54, 49, 61);
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
    border: 1px solid rgb(215, 215, 215);
    box-sizing: border-box;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
}

.embla__button:disabled {
    display: none;
}

.embla__button__svg {
    width: 14px;
    height: 14px;
}


.embla__button--prev {
    left: 5px;
}

.embla__button--next {
    right: 5px;
}

.embla__slide .mapandListing_card {
    width: 280px;
}


.Prop_details_simpleChart_container {
    border-radius: 20px;
    border: 1px solid var(--Accent-Medium-Gray, #E2E2E2);
    padding: 20px 20px 15px 0px;
    width: 100%;
    height: fit-content;
}

.prop_price_show {
    color: var(--Accent-Black, #282828);
    font-family: "Open Sans";
    font-size: 31px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%;
    margin: 0;
}

.prop_price_show span {
    color: var(--Accent-Dark-Gray, #4A4A4A);
    font-family: "Open Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
}


.prop_show_upgrade {
    color: var(--green-500, #22C55E);
    font-family: "Open Sans";
    font-size: 16px;
    font-weight: 400;
    line-height: 150%;
}

.prop_show_downgrade {
    color: red;
    font-family: "Open Sans";
    font-size: 16px;
    font-weight: 400;
    line-height: 150%;
}


.Prop_details_simpleChart .recharts-cartesian-axis-tick-line {
    display: none;
}

.Prop_details_simpleChart .recharts-text {
    color: var(--Accent-Dark-Gray, #4A4A4A);
    text-align: center;
    font-size: 10px;
    font-weight: 500;
    line-height: 21px;
    font-family: Montserrat;
}

.Prop_details_simpleChart .recharts-cartesian-axis-line {
    display: none;
}

.Prop_lineChart_custom_tooltip {
    position: relative;
    border-radius: var(--spacing-2, 8px);
    border: 1px solid var(--gray-200, #E4E4E7);
    background: var(--base-white, #FFF);
    box-shadow: 0px 44px 44px -7px rgba(0, 0, 0, 0.08);
    white-space: nowrap;
    padding: 10px;
}

.Prop_lineChart_custom_tooltip_year {
    color: var(--Accent-Dark-Gray, #4A4A4A) !important;
    text-align: center;
    font-family: "Open Sans" !important;
    font-size: 12px !important;
    font-weight: 600 !important;
    line-height: 20px;
    margin: 0px;
}

.Prop_lineChart_custom_tooltip_price {
    color: var(--Accent-Black, #282828) !important;
    font-size: 14px !important;
    font-weight: 700 !important;
    line-height: 22px;
    margin: 0px;
    text-align: center;
}

.Prop_lineChart_custom_tooltip_Des {
    color: inherit;
    text-align: center;
    font-family: "Open Sans" !important;
    font-size: 13px !important;
    font-weight: 500 !important;
    line-height: 20px;
    margin: 0px;
}


.prop_twoChart {
    padding-top: 40px;
    padding-bottom: 25px;
}

.prop_twoChart .recharts-legend-wrapper {
    top: -15px !important;
}

.Prop_details_soldChart .recharts-cartesian-grid-vertical {
    display: none;
}

.Prop_details_soldChart .recharts-tooltip-cursor {
    display: none !important;
}

.prop_twoChart .recharts-legend-item {
    margin-right: 25px !important;
}

.prop_twoChart .recharts-legend-item-text {
    color: var(--Accent-Dark-Gray, #4A4A4A) !important;
    font-family: "Open Sans";
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    /* 142.857% */
}

.composed_chart_legend_text {
    color: var(--Accent-Dark-Gray, #4A4A4A) !important;
    font-family: "Open Sans";
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
}

.composed_chart_legend_cirlce {
    border-radius: 50%;
    width: 14px;
    height: 14px;
}

.Show_prop_graph_btn {
    outline: none;
    border-radius: 10px;
    border: 1px solid var(--Accent-Medium-Gray, #E2E2E2);
    padding: 10px;
    background-color: transparent;
}

.Prop_details_saleBox {
    border-radius: 10px;
    border: 1px solid var(--Accent-Medium-Gray, #E2E2E2);
    padding: 10px;
    font-family: "Open Sans";
}

.Prop_details_saleBox h6 {
    color: var(--Accent-Black, #282828);
    text-align: center;
    font-family: "Open Sans";
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
}

.property_Basic_details {
    font-family: "Open Sans";
}


.property_Basic_details h5 {
    color: var(--Accent-Black, #282828);
    font-size: 20px;
    font-weight: 700;
    line-height: 120%;
}

.property_Basic_details ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

.property_Basic_details ul li {
    color: var(--Accent-Gray, #676767);
    font-size: 16px;
    font-weight: 500;
    line-height: 150%;
    padding: 4px 0px;
}


.Prop_detail_infoModal {
    position: absolute !important;
    top: 50% !important;
    left: 50% !important;
    transform: translate(-50%, -50%);
    max-width: 773px !important;
    width: 100% !important;
    background-color: #ffff !important;
    border-radius: 20px !important;
    box-shadow: 0px 20px 24px -4px rgba(16, 24, 40, 0.08), 0px 8px 8px -4px rgba(16, 24, 40, 0.03) !important;
    padding: 20px !important;
    animation: fadeIn 0.3s ease-out;
    font-family: "Open Sans";
}

.funnels_modals{
    width: 450px !important;
}

.Prop_detail_infoModal_fadeOut {
    animation: fadeOut 0.3s ease-out;
}

.Prop_detail_infoModal .request_Info_input {
    outline: none;
    padding: 10px 14px;
    border-radius: 10px;
    border: 1px solid var(--Accent-Medium-Gray, #E2E2E2);
    background: var(--Accent-White, #FFF);
    color: var(--Accent-Dark-Gray, #4A4A4A);
    font-size: 16px;
    width: 100%;
}

.Prop_detail_infoModal .request_Info_input::placeholder {
    color: var(--Accent-Dark-Gray, #4A4A4A);
}

.Prop_detail_infoModal .request_Info_input:hover {
    border: 1px solid var(--Accent-Black, #282828);
    outline: none;
}

.Prop_detail_infoModal .request_Info_input:focus {
    border: 1px solid var(--Accent-Black, #282828);
    outline: none;
}

.Prop_detail_infoModal .request_pass_input:focus-within {
    border: 1px solid var(--Accent-Black, #282828);
    outline: none;
}

.Prop_commuteFocusBorder {
    border: 1px solid var(--Accent-Black, #282828) !important;
}


.Prop_detail_infoModal .signUp_numberInput {
    outline: none;
    padding: 9px 12px !important;
    border-radius: 10px;
    border: 1px solid var(--Accent-Medium-Gray, #E2E2E2);
    background: var(--Accent-White, #FFF);
    color: var(--Accent-Dark-Gray, #4A4A4A);
    width: 100%;
}

.Prop_detail_infoModal .signUp_numberInput:focus-within {
    border: 1px solid var(--Accent-Black, #282828) !important;
}

.Prop_detail_infoModal .signUp_numberInput .PhoneInputInput {
    color: var(--Accent-Dark-Gray, #4A4A4A);
    font-size: 16px;
}

.Prop_detail_infoModal .MuiFormLabel-root {
    color: var(--Accent-Black, #282828) !important;
    font-size: 16px !important;
    font-style: normal !important;
    font-weight: 600 !important;
    line-height: 150% !important;
}

.Prop_detail_infoModal button {
    border: none;
    outline: none;
    border-radius: 10px;
    background: var(--Primary-Natural-blue, #007BFF);
    padding: 10px 16px;
    color: var(--Accent-White, #FFF);
    text-align: center;
    font-size: 16px;
    font-weight: 400;
    line-height: 150%;
    width: 100%;
}

.Prop_detail_infoModal h6 {
    color: var(--Gray-900, #101828);
    font-family: "Open Sans";
    font-size: 20px;
    font-weight: 700;
    line-height: 120%;
}

.Prop_detail_infoModal p {
    color: var(--Gray-600, #475467);
    font-family: "Open Sans";
    font-size: 13px;
    font-weight: 500;
    line-height: 140%;
}


.PropDetails_HideScrollBar::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.PropDetails_HideScrollBar {
    -ms-overflow-style: none;
    scrollbar-width: none;
}


.PropDetailsGraph_scroll {
    overflow-x: auto;
}

.PropDetailsGraph_scroll::-webkit-scrollbar {
    background-color: #cccccc;
    border-radius: 6px;
    height: 6px;
}

.PropDetailsGraph_scroll::-webkit-scrollbar-thumb {
    background-color: #02549e;
    border-radius: 6px;
}


.Property_detailsMargin4 {
    margin-top: 4rem;
}

.Property_detailsMarginTop1 {
    margin-top: 1rem;
}


.Property_detailsWeb_hide {
    display: none !important;
}

.Property_detailsBtn_hide {
    display: none !important;
}


.FindNearBy_Btn {
    display: flex;
    align-items: center;
    padding: 10px 16px;
    border-radius: 10px;
    border: 1px solid var(--Accent-Medium-Gray, #E2E2E2);
    color: var(--Accent-Black, #282828);
    background-color: var(--Accent-White, #FFF);
    font-family: "Open Sans";
    font-size: 16px;
    line-height: 150%;
    gap: 10px;
    margin-top: 1.5rem;
    width: 100%;
    max-width: 510px;
}

.FindNearBy_Btn:hover {
    background-color: #f5f5f5;
}


.Find_nearByMobileMapImg {
    width: 100%;
    max-width: 510px;
    border-radius: 16px;
    border: 1px solid var(--Accent-Medium-Gray, #E2E2E2);
}


.Prop_detailsMobileFixedBtn {
    position: fixed;
    bottom: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    width: 100%;
    background-color: #FFF;
    padding: 10px 0px 14px 0px;
}

.Prop_detailsMobileFixedBtn button {
    font-size: 14px;
    width: calc(46%);
    border-radius: 10px;
    padding: 12px 8px;
}




.PropertyDetails_notes textarea {
    outline: none;
    border-radius: 10px;
    border: 1px solid var(--Accent-Medium-Gray, #E2E2E2);
    background: var(--Accent-White, #FFF);
    color: var(--Accent-Gray, #676767);
    font-family: "Open Sans";
    font-size: 14px;
    font-weight: 500;
    line-height: 150%;
    width: 100%;
    height: 120px;
    padding: 8px;
    resize: none;
    overflow-y: auto;
}

.PropertyDetails_notes textarea::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 3px rgba(173, 173, 173, 0.3);
    border-radius: 2px;
    background-color: #F5F5F5;
}

.PropertyDetails_notes textarea::-webkit-scrollbar {
    width: 5px;
    background-color: #F5F5F5;
}

.PropertyDetails_notes textarea::-webkit-scrollbar-thumb {
    border-radius: 8px;
    -webkit-box-shadow: inset 0 0 4px rgba(116, 116, 116, 0.3);
    background-color: #b6b6b6;
}

.PropertyDetails_notes textarea::placeholder {
    color: var(--Accent-Gray, #676767);
    font-family: "Open Sans";
    font-size: 14px;
    font-weight: 500;
    line-height: 150%;
}

.Prop_detailsMobileFixedBtn .NoteBtn {
    border-radius: 10px;
    border: 1px solid var(--Accent-Medium-Gray, #E2E2E2);
    background: var(--Accent-White, #FFF);
    color: #282828;
}

.Prop_detailsRatingCard p {
    color: var(--Accent-Gray, #676767);
    font-family: "Open Sans";
    font-size: 16px;
    font-weight: 500;
    line-height: 150%;
}

.Prop_detailsBlur {
    -webkit-filter: blur(3px);
    -moz-filter: blur(3px);
    -ms-filter: blur(3px);
    filter: blur(3px);
}

.Prop_loginReqBtn {
    outline: none;
    border: none;
    color: var(--Accent-White, #FFF);
    font-family: "Open Sans";
    font-size: 16px;
    font-weight: 400;
    line-height: 150%;
    border-radius: 10px;
    background: var(--Primary-Natural-blue, #007BFF);
    padding: 10px 16px;
}

.PropCancelNotes_btn {
    border-radius: 10px !important;
    border: 1px solid var(--Accent-Medium-Gray, #E2E2E2) !important;
    background: var(--Accent-White, #FFF) !important;
    color: #282828 !important;
}

.PropCancelNotes_btn:hover {
    background-color: #f5f5f5 !important;
}

.Research_noteHeading {
    color: var(--Accent-Black, #282828);
    font-family: "Open Sans";
    font-size: 20px;
    font-weight: 700;
    line-height: 120%;
}

.RatingMobile_modal {
    border: none;
    padding: 0px 16px;
}


.Prop_detailsValueModal {
    width: 360px !important;
}

.Prop_featuredBadge {
    position: absolute;
    right: 12px;
    bottom: 10px;
    border-radius: 36px;
    background: var(--Primary-Yellow, #F6941C);
    padding: 8px 16px;
    color: var(--Accent-White, #FFF);
    font-family: "Open Sans";
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%;
}

.Prop_details_FullDescription {
    max-height: auto;
}

.PropDetails_HideDescription {
    max-height: 130px;
    overflow: hidden;
}

.PropDetails_HideDescription::before {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0px;
    top: 0px;
    background: linear-gradient(transparent, white);
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-text-fill-color: transparent;
    pointer-events: none;
}

.Property_taxHistory_table tbody tr td span {
    color: #282828 !important
}

.Prop_keyFacts {
    font-family: "Open Sans";
    color: var(--Accent-Dark-Gray, #4A4A4A);
}

.Prop_keyFacts h5 {
    font-weight: 400 !important;
    line-height: 150%;
    font-size: 16px !important;
    width: 50% !important;
}

.Prop_keyFacts h6 {
    font-weight: 700 !important;
    line-height: 150%;
    width: 50% !important;
    font-size: 16px !important;
}

.mlsData_desContainer {
    column-count: 2;
    column-gap: 1.25rem;
    font-family: "Open Sans";
}

.Prop_mlsDataHeading {
    color: #282828;
    font-size: 1.125rem;
    font-weight: 600;
    line-height: 120%;
    margin-top: 0.5rem;
    display: flex;
    align-items: center;
    gap: 6px;
}

.PropMlsData_list {
    color: #4A4A4A;
    font-size: 1rem;
    padding-left: 1.3rem;
    list-style: disc !important;
}

.PropMlsData_list li {
    line-height: 150%;
}

.mls_data_desSubContainer {
    display: inline-block;
}

.floorPan_mainImage {
    height: 60vh;
}

.floorPan_mainImage img {
    border-radius: 8px;
    object-fit: cover;
    width: 100%;
}


.FloorPlan_main_information {
    color: var(--Accent-Black, #282828);
    font-family: "Open Sans";
}

.FloorPlan_main_information h1 {
    font-size: 40px;
    font-weight: 700;
    line-height: 120%;
}

.FloorPlan_main_information h4 {
    font-size: 39px;
    font-weight: 700;
    line-height: 120%;
}

.FloorPlan_main_information h5 {
    font-size: 31px;
    font-weight: 700;
    line-height: 120%;
}

.FloorPlan_main_information h6 {
    color: var(--Accent-Black, #282828);
    font-size: 20px;
    font-weight: 400;
    line-height: 120%;
    margin-top: 2rem;
}


.Prop_details_galleryContiner {
    display: flex;
    gap: 0.8rem;
    position: relative;
}

.Prop_details_galleryContiner img {
    width: 100%;
    height: 100%;
    border-radius: 20px;
    cursor: pointer;
}

.PropDetails_galleryMainImg {
    max-width: 66%;
    width: 100%;
    height: 50vh;
}

.PropDetails_gallerySubImgContainer {
    width: 33%;
    max-width: 33%;
    display: flex;
    flex-direction: column;
    gap: 0.7rem;
    height: 50vh;
}

.PropDetails_gallerySubImg {
    width: 100%;
    height: 48%;
}

.PropDetails_gallerySubImg2Container {
    display: flex;
    justify-content: space-between;
    height: 48%;
}

.PropDetails_gallerySubimg2 {
    width: 48.5%;
    height: 100%;
}

.PropDetails_allImgBtn {
    position: absolute;
    bottom: 0.7rem;
    right: 1rem;
    border-radius: 6px;
    outline: none;
    border: 1px solid var(--Black-222222, #222);
    background: var(--White, #FFF);
    gap: 0.525rem;
    padding: 0.625rem;
    color: var(--Black-222222, #222);
    font-family: "Open Sans";
    font-size: 13px;
    font-weight: 500;
    line-height: 140%;
}

.PropertyStatusBadge {
    color: var(--Primary-Blue, #0253A0);
    font-family: "Open Sans";
    font-size: 16px;
    font-weight: 500;
    line-height: 150%;
    border-radius: var(--spacing-6, 24px);
    background: var(--Supporting-color-Light-blue, #E2F1FF);
    padding: 4px 10px;
    width: fit-content;
    display: flex;
    align-items: center;
}

.PropertyStatusBadge span {
    display: inline-block;
    background-color: var(--Primary-Blue, #0253A0);
    width: 12px;
    height: 12px;
    border-radius: 50%;
    margin-right: 10px;
    vertical-align: middle;
}

.Prop_assignment_contentDetails .description {
    color: #4A4A4A;
    font-family: 'Open Sans';
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 170%;
}

.Prop_assignmentAddress {
    display: flex;
    flex-wrap: wrap;
    max-width: 100%;
    column-gap: 20px;
    row-gap: 4px;
}

.Prop_assignmentAddress p {
    color: var(--Accent-Dark-Gray, #4A4A4A);
    font-family: "Open Sans";
    font-size: 16px;
    line-height: 150%;
    margin: 0;
}

.Prop_assignmentAddress p strong {
    color: var(--Accent-Dark-Gray, #4A4A4A);
    font-weight: 700;
}

.Propertydeveloper_name {
    font-size: 20px !important;
}

.Prop_detail_GalleryModal {
    width: 100%;
    height: 100vh;
    background-color: #000;
    padding: 20px;
}

.prop_detailsTransitCard {
    border-radius: 10px;
    border: 1px solid var(--Accent-Medium-Gray, #E2E2E2);
    background: var(--Accent-White, #FFF);
    padding: 16px;
    font-family: "Open Sans";
}

.prop_detailsTransitCard h6 {
    color: var(--Accent-Black, #282828);
    font-size: 20px;
    font-weight: 700;
    line-height: 120%;
    /* 24px */
}

.prop_detailsTransitCard .TransirtCard_description {
    color: #7E7E7E;
    font-family: "Open Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
}

.prop_detailsTransitCard .TransirtCard_description {
    color: #676767;
    font-size: 16px;
    font-weight: 500;
    line-height: 150%;
}

.transit_driveDitance {
    color: #676767;
    font-size: 16px;
    font-weight: 700;
    line-height: 140%;
}

.transit_scroeTaken {
    color: var(--Accent-Black, #282828);
    font-size: 16px;
    font-weight: 700;
    line-height: 140%;
}

.transit_scroeOutOf {
    color: var(--Accent-Gray, #676767);
    font-size: 11px;
    font-weight: 700;
    line-height: 120%;
}

.PropDetails_gradientBtn {
    background: var(--Primary-Natural-blue, #007BFF);
    color: var(--Accent-White, #FFF);
    animation: gradientBackground 5s infinite;
}

.WatchNeighbourHood_inputs input[type='checkbox'] {
    appearance: none;
    width: 22px;
    height: 22px;
    background-color: #fff;
    border: 0.15em solid #E2E2E2;
    cursor: pointer;
    border-radius: 5px;
}

.WatchNeighbourHood_inputs input[type='checkbox']:checked {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: black;
    border: none;
    outline: none;
}

.WatchNeighbourHood_inputs input[type='checkbox']:checked:after {
    appearance: none;
    content: '✔';
    color: white;
}

.WatchNeighbourHood_inputs input[type='checkbox']:checked+span {
    color: #222;
    font-weight: 500;
}

.WatchNeighbourHood_inputs span {
    color: var(--Accent-Dark-Gray, #4A4A4A);
    font-family: "Open Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
}

.AgentQuery_modal h6 {
    font-size: 27px;
}

.AgentQuery_modal p {
    font-size: 16px;
    margin: 0;
}

.SignUp_modal {
    max-width: 992px !important;
}

.SignUp_modal p {
    font-size: 16px;
    margin: 0;
}


.SignUp_modalInformation {
    border-radius: 10px;
    background: var(--Accent-Light-Gray, #F4F4F5);
    padding: 35px 10px;
}

.SignUp_modalInputContainer {
    max-width: 368px;
}

.Login_modal {
    width: 468px !important;
}

.LoginModal_heading {
    font-size: 31px;
    font-weight: 700;
}

.AddCommute_WalkDistance {
    border-radius: 10px;
    border: 1px solid var(--Accent-Medium-Gray, #E2E2E2);
    background: var(--Accent-White, #FFF);
    padding: 10px;
    width: 23%;
    display: flex;
    gap: 10px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: var(--Accent-Black, #282828);
    font-family: "Open Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
    cursor: pointer;
}

.SelectedAddCommute_WalkDistance {
    background: var(--Primary-Natural-blue, #007BFF);
    color: var(--Accent-White, #FFF);
}


.ArriveDestination_commute {
    color: #000 !important;
    font-family: "Open Sans" !important;
    font-size: 16px !important;
    font-weight: 600 !important;
    line-height: 140% !important;
}

.ArriveDestination_commute span {
    font-size: 20px;
    font-weight: 700;
}

.PropDetail_addressSearchBox {
    position: absolute;
    top: 55px;
    z-index: 300;
}

.Property_minusIcon {
    background-color: var(--Primary-Light-blue) !important;
    border-radius: 50% !important;
    padding: 4px !important;
    font-size: 1.8rem !important;
    color: var(--Primary-Natural-blue) !important;
}

.Property_plusIcon {
    color: var(--Primary-Natural-blue) !important;
    font-size: 1.5rem !important;
}

.Property_minusWebGreyIcon {
    background: var(--Accent-Light-Gray, #F4F4F5) !important;
    border-radius: 50% !important;
    padding: 4px !important;
    font-size: 1.8rem !important;
    color: #4A4A4A !important;
}


.Property_plusWebGreyIcon {
    font-size: 1.5rem !important;
    color: #4A4A4A !important;
}

.Property_plusIcon:hover {
    background-color: var(--Primary-Light-blue) !important;
    border-radius: 50% !important;
    padding: 4px !important;
    font-size: 1.8rem !important;
}

.Property_plusWebGreyIcon:hover {
    background: var(--Accent-Light-Gray, #F4F4F5) !important;
    border-radius: 50% !important;
    padding: 4px !important;
    font-size: 1.8rem !important;
    color: #4A4A4A !important;
}

.Property_details_Web_Accordion {
    border-radius: 20px !important;
    border: none !important;
    background: var(--Accent-Light-Gray, #F4F4F5) !important;
    box-shadow: none !important;
    padding: 20px !important;

}

.Property_simpleAccordion {
    background-color: #fff !important;
    padding: 0px !important;
}

.Accordion_withBlueBackground {
    background: var(--Primary-Light-blue, #E2F1FF) !important;
}

.Property_details_Web_Accordion .MuiAccordionSummary-root {
    margin: 0px !important;
    min-height: auto !important;
    padding: 0px !important;
    align-items: flex-start !important;
}

.Property_details_Web_Accordion .MuiAccordionSummary-content {
    margin: 0px !important;
    min-height: auto !important;
    padding: 0px !important;
    align-items: flex-start !important;
}

.Property_details_Web_Accordion .Mui-expanded {
    margin: 0px !important;
    min-height: auto !important;
    padding: 0px !important;
}

.Property_details_Web_Accordion .MuiAccordionDetails-root {
    margin: 0px !important;
    min-height: auto !important;
    padding: 0px !important;
}

.Property_details_Web_Accordion .css-sh22l5-MuiButtonBase-root-MuiAccordionSummary-root {
    padding: 0px !important;
    min-height: auto !important;
    align-items: flex-start !important;
    gap: 10px !important;
}

.Property_details_Web_Accordion .css-o4b71y-MuiAccordionSummary-content {
    margin: 0px !important;
}

.Property_details_Web_Accordion .css-15v22id-MuiAccordionDetails-root {
    padding: 0px !important;
}

.Property_details_Web_Accordion .css-sh22l5-MuiButtonBase-root-MuiAccordionSummary-root.Mui-expanded {
    min-height: auto !important;
    padding: 0px !important;
    align-items: flex-start !important;
    gap: 20px !important
}

.DetailsAccordion_withborder {
    border: 1px solid var(--Accent-Medium-Gray, #E2E2E2) !important;
    background: var(--Accent-White, #FFF) !important;
}

.Prop_details_CompMargin {
    margin-top: 1.6rem;
}

.PropDetails_BorderNoneMargin {
    margin-top: 5rem;
}

.PropDetails_darkGrayBack {
    background-color: #E2E2E2 !important;
}

.population_heading {
    color: var(--Accent-Black, #282828);
    font-family: "Open Sans";
    font-size: 20px;
    font-weight: 700;
    line-height: 120%;
}

.Basic_detailsCategoryContainer {
    column-count: 3;
}


.BasicDetails_subCategoryContainer {
    margin-bottom: 0rem;
}

.Basic_details_subCategory {
    display: inline-block;
}

.Basic_details_Category {
    display: flex;
    align-items: center;
    margin-bottom: 0.5rem;
}


.Basic_details_Category h6 {
    margin: 0rem 0 0 0.6rem;
    color: var(--Accent-Black, #282828);
    font-family: "Open Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 160%;
    letter-spacing: 0.56px;
}

.BasicDetails_List {
    color: var(--Accent-Gray, #676767);
    font-family: "Open Sans";
    font-size: 16px;
    font-weight: 500;
    line-height: 160%;
    letter-spacing: 0.56px;
    padding-left: 25px;
}

.available_homeBtn {
    font-weight: 600;
    width: fit-content;
    margin-top: 0.5rem;
}

.Chart_listItems {
    width: calc(100% - 185px);
}

.MortgageBtn_container .Mortgage_tabContainer {
    display: flex;
    gap: 20px
}

.MortgageBtn_container button {
    border-radius: 42px;
    border: 1px solid var(--Accent-Medium-Gray, #E2E2E2);
    background-color: #fff;
    color: var(--Accent-Black, #282828);
    font-family: "Open Sans";
    font-size: 16px;
    font-weight: 600;
    line-height: 22px;
}


.MortgageBtn_container .Mortgage_selectedTabBtn {
    background: var(--Accent-Light-Gray, #F4F4F5);
}

.MortgageBtn_container .Mortage_contactBrokerBtn {
    background: var(--Primary-Natural-blue, #007BFF);
    color: var(--Accent-White, #FFF);

}

.DetailsBuilders_Box {
    position: absolute;
    left: 20px;
    top: 20px;
    padding: 10px;
    border-radius: 6px;
    border: 0.5px solid var(--Medium-Gray, #D9D9D9);
    background: var(--White, #FFF);
}

.DetailsBuilders_Box p {
    color: var(--Primary-Natural-blue, #007BFF);
    font-family: "Open Sans";
    font-size: 25px;
    font-weight: 700;
    line-height: 120%;
    text-align: center;
}


.Property_backSearch {
    color: var(--Accent-Dark-Gray, #4A4A4A);
    font-family: "Open Sans";
    font-size: 16px;
    font-weight: 600;
    line-height: 22px;
    display: flex;
    align-items: center;
    gap: 5px;
    cursor: pointer;
}


.keyFacs_Container {
    font-family: "Open Sans";
}

.key_factsTableHeading {
    background-color: var(--Accent-Light-Gray, #F4F4F5);
    padding: 10px 0px;
}

.key_factsTableHeading h3 {
    font-size: 13px;
    color: var(--Accent-Gray, #676767);
    font-size: 13px;
    font-weight: 500;
    line-height: 140%;
    margin: 0;
}

.keyFacs_Container h5 {
    color: #000;
    font-size: 16px;
    font-weight: 500;
    line-height: 150%;
    margin: 0;
    font-family: "Open Sans";
}

.keyFacs_Container h6 {
    color: var(--Accent-Gray, #676767);
    font-size: 16px;
    font-weight: 400;
    line-height: 150%;
    letter-spacing: 0.48px;
    margin: 0;
    font-family: "Open Sans";
}


.KeyFacts_border_bottom {
    padding: 16px 10px;
    border-bottom: 1px solid var(--Accent-Medium-Gray, #E2E2E2);
}

.KeyFacts_paddingTop {
    padding-top: 16px;
}

.Property_taxHistory_table th {
    font-size: 13px;
    color: var(--Accent-Gray, #676767) !important;
    font-size: 13px;
    font-weight: 500;
    line-height: 140%;
    margin: 0;
}

.Mbl_notifyBtn {
    border-radius: 10px;
    border: 1px solid var(--Accent-Medium-Gray, #E2E2E2);
    background: var(--Accent-White, #FFF);
    display: flex;
    padding: 16px;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    gap: 10px;
    width: calc(33% - 5px);
    color: var(--Accent-Dark-Gray, #4A4A4A);
    font-family: "Open Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 160%;
    letter-spacing: 0.56px;
    text-align: left;
}

.Mbl_notifyBtn_bluetbtn {
    background: var(--Primary-Natural-blue, #007BFF);
    color: #fff;
    border: none !important;
}

.transitCard_value {
    height: 65px;
}

.transitCard_value img {
    max-width: 100%;
    max-height: 100%;
}

.marketActivity_CardContainer {
    display: flex;
    flex-wrap: wrap;
    gap: 12px;
    margin-top: 1rem;
}

.marketActivity_CardContainer .propertyDetail_homeValueCard {
    width: calc(33.33% - 8px);
}


.custom_map_marker {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 36px;
    height: 36px;
    border: 1px solid var(--Accent-Black, #282828);
    background: var(--Accent-Light-Gray, #F4F4F5);
    border-radius: 50%;
    transition: all 0.3s ease-out;
}

.Map_cluster_hovered {
    width: 50px;
    height: 50px;
    z-index: 3;
}

.prop_details_BtnShow {
    z-index: 4;
}



@media (max-width: 1300px) {
    .Basic_detailsCategoryContainer {
        column-count: 2;
    }


}

@media (max-width: 1200px) {
    .Prop_header_static {
        top: 47px !important;
        transition: all ease .4s
    }

    .prop_represent_linkStatic {
        top: 117px
    }
}


@media (max-width: 992px) {

    .PropDetailsMob_lg_hide {
        display: none;
    }

    .PropDetailsMob_lg_show {
        display: inline-block !important;
    }

    .property_Basic_details {
        border-bottom: none;
    }

    .property_Basic_details .Mobile_col_border {
        border-bottom: 1px solid var(--Accent-Medium-Gray, #E2E2E2);
    }

    .PropDetails_ImageAndTextContainer {
        display: flex !important;
        flex-direction: column-reverse !important;
        gap: 20px !important;
        margin-top: 7px;
    }

    .DetailsBuilders_Box {
        top: -20px
    }

    .property_details_mainImage {
        height: 56vh;
    }

    .Prop_thubnail_img {
        height: 40vh;
    }

    .floorPan_mainImage {
        height: 50vh;
    }

    .prop_details_lgShow {
        display: inline-block !important;
    }

    .prop_details_BtnShow {
        display: flex !important;
    }

    .PropDetails_galleryMainImg {
        max-width: 100%;
        height: 60vh;
    }

    .SignUp_modal {
        max-width: 90% !important;
    }

    /* .property_information_DetailsIcon {
        margin: 0px !important;
    } */

    .PropertyDetiails_Icons {
        flex-direction: column-reverse !important;
        gap: 1.8rem;
        margin-top: 0px !important;
    }

}


@media (max-width: 800px) {
    .Property_details_tabs .MuiButtonBase-root {
        margin-bottom: 0px !important;
        margin-right: 0px !important;
    }

    .Property_details_tabs .css-heg063-MuiTabs-flexContainer {
        margin-left: 0px !important;
        gap: 12px !important;
    }

    .Property_details_tabs .css-k008qs {
        display: flex !important;
        gap: 10px !important
    }

    .PropertyDetail_tabPanel .MuiBox-root {
        background-color: transparent !important;
    }

    .embla__slide .cardmain_img {
        width: 270px !important;
    }

    .gallery_embla__slide img {
        width: 97%;
    }
}


@media (max-width: 768px) {

    .Propert_details_main_container {
        padding-left: 16px !important;
        padding-right: 16px !important;
    }


    .PropDetailsMob_md_show {
        display: inline-block;
    }

    .property_Details_heading {
        font-size: 20px;
    }

    .property_incetives_columns {
        column-count: 1;
    }


    .Prop_detailMortgage_calc .mbl_margin {
        margin-top: 1rem !important;
    }

    .Details_mbl_margin {
        margin-top: 1rem !important;

    }

    .population_heading {
        font-size: 18px !important;
    }

    .Prop_detail_infoModal {
        bottom: 0 !important;
        left: 0 !important;
        top: auto !important;
        transform: translate(0px, 0px);
        animation: slideInMobile 0.3s ease-out;
        padding: 20px 10px !important;
        border-bottom-left-radius: 0 !important;
        border-bottom-right-radius: 0 !important;
    }

    .funnels_modals {
        width: 100% !important;
    }

    .Prop_detail_infoModal_fadeOut {
        animation: fadeOutMobile 0.3s ease-out;
    }

    .PropertyDetails_Mbl_f14 {
        font-size: 14px !important;
    }

    .PropertyDetails_Mbl_f16 {
        font-size: 16px !important;
    }

    .PropertyDetails_Mbl_f18 {
        font-size: 18px !important;
    }

    .property_Basic_details ul li {
        font-size: 14px;
    }

    .property_Basic_details h5 {
        font-size: 18px;
    }

    .Mimico_pricing_subHeading {
        font-size: 18px !important;
    }

    .Chart_circle_icon {
        width: 16px !important;
        height: 16px !important;
    }

    .Property_details_faq_question {
        font-size: 18px !important;
        margin: 0px !important;
        font-weight: 700 !important;
    }

    .Property_details_faq_answer {
        font-size: 14px !important;
        margin: 0px !important;
    }

    .mbl_Xpadding0 {
        padding-left: 0px !important;
        padding-right: 0px !important;
    }

    .mbl_marginTop0 {
        margin-top: 0px !important;
    }

    .PropDetailsGraph_scroll {
        overflow-x: auto;
        padding-bottom: 20px;
    }


    .PropMobile_accordionContainer .css-1c35hjw-MuiPaper-root-MuiAccordion-root.Mui-expanded {
        margin: 0px !important;
    }


    .PropertyDetails_MobileAccordion {
        border-radius: 0px !important;
        border: none !important;
        border-top: 1px solid var(--Accent-Dark-Gray, #4A4A4A) !important;
        background: var(--Accent-White, #FFF) !important;
        box-shadow: none !important;
        padding: 16px 0px !important;
    }


    .PropertyDetails_MobileAccordion .css-sh22l5-MuiButtonBase-root-MuiAccordionSummary-root {
        padding: 0px !important;
        min-height: auto !important;
    }


    .PropertyDetails_MobileAccordion::before {
        background-color: transparent !important;
    }

    .PropertyDetails_MobileAccordionHeading {
        color: var(--Accent-Black, #282828) !important;
        font-family: "Open Sans" !important;
        font-size: 18px !important;
        font-weight: 700 !important;
        line-height: 120% !important;
    }

    .Property_detailsWeb_hide {
        display: block !important;
    }

    .MobileAccordion_BottomBorder {
        border-bottom: 1px solid var(--Accent-Dark-Gray, #4A4A4A) !important;
    }

    .PropDetails_textCenter {
        text-align: center !important;
    }

    .PropertyDetail_comment {
        max-width: 100%;
    }

    .property_information_DetailsIcon p {
        min-width: calc(50% - 10px);
    }

    .property_details_main_information h1 {
        font-size: 34px;
    }

    .property_details_main_information h5 {
        font-size: 14px;
    }

    .preconstruction_complete_time {
        text-align: left !important;
        font-size: 18px !important;
    }

    .Prop_details_saleBox {
        border: none;
        padding: 12px 0;
    }

    .PropDetailsMob_md_hide {
        display: none !important;
    }


    .Prop_details_saleBox h6 {
        font-size: 18px;
        text-align: left;
    }

    .Prop_detailsValueModal {
        top: 50% !important;
        left: 50% !important;
        transform: translate(-50%, -50%) !important;
        height: fit-content;
        border-bottom-left-radius: 20px !important;
        border-bottom-right-radius: 20px !important;
        padding: 16px !important;
    }

    .Prop_featuredBadge {
        font-size: 18px;
    }

    .mlsData_desContainer {
        column-count: 1;
    }

    .Prop_mlsDataHeading {
        font-size: 1rem;
    }

    .PropMlsData_list {
        color: #4A4A4A;
        font-size: 0.875rem;
        padding-left: 1.3rem;
    }

    .property_floorPlan_price {
        font-size: 14px !important;
    }

    .mls_data_desSubContainer {
        display: block;
    }

    .FloorPlan_main_information h1 {
        font-size: 34px;
    }

    .FloorPlan_main_information h4 {
        font-size: 29px;
    }

    .FloorPlan_main_information h6 {
        font-size: 18px;
        margin-top: 1.3rem;
    }

    .FloorPlan_main_information h5 {
        font-size: 27px;
    }


    .Assignment_main_information {
        flex-direction: column !important;
        justify-content: flex-start;
    }

    .Prop_assignment_contentDetails {
        max-width: 100%;
    }

    .PropertyStatusBadge {
        padding: 3px 10px;
    }

    .mobile_marginTop {
        margin-top: 15px;
    }

    .SignUp_modalInputContainer {
        max-width: 100%;
    }

    .Login_modal {
        width: 100% !important;
        padding: 20px 12px !important;
    }

    .LoginModal_heading {
        font-size: 25px !important;
    }

    .SignUp_modal {
        max-width: 100% !important;
        padding: 20px 12px !important;
    }

    .AddCommute_WalkDistance {
        font-size: 14px !important;
    }

    .ArriveDestination_commute {
        font-size: 14px !important;
    }

    .ArriveDestination_commute span {
        font-size: 16px !important;
    }

    .Prop_details_homeValueFlex {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        gap: 12px;
    }

    .Prop_details_homeValueFlex svg {
        width: 20px !important;
        height: 20px !important;
    }

    .mobile_signUpModal {
        max-height: 100vh;
        overflow-y: auto;
    }

    .Mobile_signUpGap {
        gap: 20px;
    }

    .Prop_details_CompMargin {
        margin: 0;
    }

    .PropDetails_BorderNoneMargin {
        margin: 0;
    }

    .Property_plusWebGreyIcon {
        color: var(--Primary-Natural-blue) !important;
    }

    .Property_plusWebGreyIcon:hover {
        color: var(--Primary-Natural-blue) !important;
        background-color: var(--Primary-Light-blue) !important;
    }

    .Property_minusWebGreyIcon {
        color: var(--Primary-Natural-blue) !important;
        background-color: var(--Primary-Light-blue) !important;
    }

    .Basic_details_Category h6 {
        font-size: 14px;
    }

    .BasicDetails_List {
        font-size: 14px;
    }

    .market_activityMobileCard {
        padding-left: 10px !important;
        padding-right: 0px !important;
    }

    .propertyDetail_homeValue_icon {
        padding: 10px;
        border: 6.65px solid;
        border-color: var(--Success-50, #ECFDF3);
    }

    .Property_pricingIconHeading {
        flex-direction: row;
        gap: 8px;
        align-items: center;
    }

    .Property_pricingIconHeading h5 {
        margin: 0 !important;
    }

    .Property_pricingMblPadding {
        padding-left: 40px;
    }

    .keyFacs_Container h5 {
        font-size: 14px;
    }

    .keyFacs_Container h6 {
        font-size: 14px;
    }

    .Prop_assignmentAddress p {
        margin-bottom: 0px;
    }

    .Prop_header_container {
        justify-content: center !important;
    }

    .MortgageBtn_container .Mortgage_tabContainer {
        display: flex;
        gap: 10px
    }

    .marketActivity_CardContainer .propertyDetail_homeValueCard {
        width: calc(50% - 6px);
    }


    .Prop_detailMortgage_calc h5 {
        font-size: 14px;
    }

    .Prop_detailMortgage_calc h6 {
        font-size: 14px;
    }

}

@media (max-width: 600px) {
    .propDetails_chartsStatistics {
        column-count: 1;
        margin-top: 1.2rem;
    }

    .Demographics_container {
        align-items: flex-start;
    }

}

@media (max-width: 525px) {
    .PropertyDetails_MobileAccordionHeading {
        margin-bottom: 0 !important;
    }

    .property_details_mainImage .heartShare .css-130dmu7-MuiTypography-root {
        margin-bottom: 0 !important;
    }

    .Share_featuresPopover p {
        margin-bottom: 0 !important;
    }

    .property_details_mainImage {
        height: 40vh;
    }

    /* .property_details_mainImage img {
        object-fit: cover;
    } */

    .floorPan_mainImage {
        height: 40vh;
    }

    .floorPan_mainImage img {
        object-fit: cover;
    }

    .PropDetails_galleryMainImg {
        height: 40vh;
    }

    .Basic_detailsCategoryContainer {
        column-count: 1;
    }

    .Chart_listItems {
        width: calc(100% - 165px);
    }

    .Mbl_notifyBtn {
        padding: 10px;
        font-size: 14px;
    }

    .gallery_embla__slide {
        height: calc(100vh - 150px);
    }

    .Propmbl_f11 {
        font-size: 11px !important;
    }

}

@media (max-width: 512px) {


    .embla__slide {
        padding-left: 2px !important;
        width: 100% !important;
        flex: 0 0 auto !important;
    }

    .embla__slide:first-child {
        padding-left: 0px !important;
    }

    .embla__slide .mapandListing_card {
        width: 100% !important;
    }

    .embla__slide .cardmain_img {
        width: 98% !important;
    }

}


@media (max-width: 375px) {
    .Hide_HeaderIcon {
        display: none
    }
}