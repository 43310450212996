.tool_ad_container {
    font-family: Montserrat;
    padding: 0;
    margin: 0;
}

.tool_ad_banner_container {
    padding: 1.25rem 0px;
    width: 100%;
}

.tool_ad_banner {
    max-width: 1300px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}


.toolTicker_container {
    max-width: 1050px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5rem 0px;
}

.tools_grey_container {
    background-color: #F5F5F5;
}

.toolAdtext_container {
    width: 50%;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
}

.toolAdImage_container {
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.toolAdImage_container img {
    max-width: 85%;
}

.tools_ad_heading {
    color: #121212;
    font-size: 32px;
    font-weight: 700;
    line-height: 42px;
    font-family: Montserrat;
}

.tools_ad_para {
    color: #7E7E7E;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 21px;
    font-family: Montserrat;
}

.tool_ad_btn {
    border: none;
    border-radius: 4px;
    background: #02549E;
    outline: none;
    cursor: pointer;
    color: #FFF;
    font-size: 14px;
    font-weight: 700;
    padding: 0.7rem 1.5rem;
}

.tools_ad_list {
    list-style: initial;
    padding-left: 20px !important;
}

@media(max-width: 800px) {
    .toolTicker_container{
        padding: 4rem 0;
    }

    .tool_ad_banner {
        flex-direction: column;
        gap: 20px;
    }

    .toolTicker_container {
        flex-direction: column;
    }

    .toolAdtext_container {
        width: 100%;
    }

    .toolAdImage_container {
        width: 100%;
    }

    .tools_ad_heading {
        font-size: 24px;
        line-height: 30px;
    }

    .tools_ad_para {
        font-size: 14px;
    }
}