.card-container {
  display: flex;
  justify-content: center;
  width: 100%;
  /* margin-left: 100px; */
  padding: 50px 0;
  /* max-width: 1140px; */
}

.card-shadow-design {
  box-shadow: #000 0px 8px 30px -17px;

}

.starter-btn-design {
  border: 1px solid black !important;
  background-color: #fafafa;
}

.modifyBtn {
  border-radius: 30px;
  padding: 5px 10px;
}


.card-title {
  margin-top: 32px;
  color: #02549e;
  font-size: small;
  font-family: "Montserrat";
}

.card-price {
  margin-top: 0px;
  color: #02549e;
}

.card-desc {
  padding: 8px 16px;
  color: #000;
  text-align: center;
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px;
}

.pricing-card {
  margin: 12px;
  height: fit-content;
  width: 430px;
  /* background: beige; */
  text-align: center;
  /* padding: 10px; */
}

.starter-bd {
  border-color: black;
  border: 2px solid;
}


.plan-details {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-bottom: 80px;
}

.plan-details h2 {
  color: #0253a0;
  font-family: Montserrat;
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  margin-bottom: 10px;
}

.heading {
  margin-bottom: 20px;
  color: #02549e;
}

.card-btn {
  /* margin-right: 40px; */
  width: 80%;
  border-radius: 4px;
  margin-top: 8px;
  height: 3.5rem;
  font-family: 'Montserrat';
  font-size: large;
  font-weight: 600;
  border: none
}


.features {
  /* color: rgb(2, 84, 158); */
  /* margin-right: 13px; */
  display: flex;
  margin-top: 32px;
  line-height: 14px;
  font-size: 14px;
  align-items: baseline;
}

.info-icon {
  margin-left: auto;
  margin-right: 17px;
  color: #9A9A9A;
}

.pricing_cardn {
  border-radius: 30px;
  border: 5px solid #121212;
  background: #FFF;
  box-shadow: 2px 2px 2px 2px rgba(0, 0, 0, 0.05);
}

.elite_cardn {
  border: none;
  box-shadow: 2px 2px 9px 2px rgba(0, 0, 0, 0.08);
}

.stater_cardn{
  border: none;
  background: #F5F5F5;
}

.choosePkg_btn {
  background-color: #121212;
  color: #fff;
}

.selectedPkg_btn {
  border: 2px solid #000;
  background-color: #fff;
  color: #000;
}

.features p {
  text-align: left !important;

}

.card-priceStarter {
  margin-top: 75px;
}

.card-pricePremium {
  margin-top: 50px;
  color: #0253A0;
}

@media (max-width:1150px) {


  .Full_tabs {
    display: none;
  }
}

@media (max-width:990px) {
  .pricing-card {
    /* height: 980px !important; */
    margin: 10px !important;
  }

}


@media (max-width: 930px) {
  .card-container {
    display: flex !important;
    flex-direction: column !important;
    width: 100% !important;
    padding: 0px 18px 0px 18px !important;
    margin-top: 2rem;
  }

  .pricing-card {
    margin: 0px 0px 25px 0px !important;
    width: 100% !important;
    padding: 0px !important;
    padding-bottom: 40px !important;
  }

}



@media (max-width: 820px) {
  .mob_tabcontainer {
    display: none !important;
  }

  /* .mob_plan {
    padding: 25px 15px 40px 20px !important;
  } */



  .card-priceStarter {
    margin-top: 0px !important;
  }

  .card-pricePremium {
    margin-top: 0px !important;
  }

  .card-price {
    margin-top: 0px !important;
  }

  .includes {
    margin-top: 35px !important;
  }

  .mob_head {
    display: none;
  }

  .mob_plan h2 {
    color: #1b0202;
    font-family: Montserrat;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%;
    text-align: center;
  }
}




/* @media (max-width: 525px) {
  .card-container {
    padding: 0px 15px 0px 20px !important;
  }

  .pricing-card {
    width: 96% !important;
  }

  .card-price {
    margin-top: 0px !important;
  }
}

@media (max-width: 320px) {
  .card-container {
    padding: 0px 15px 0px 15px !important;
  }

  .pricing-card {
    width: 96% !important;
  }

  .card-price {
    margin-top: 0px !important;
  }
} */