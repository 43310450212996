.finace_font {
  font-family: Montserrat;
  background: #fafafa;
}

.finance_container {
  background-color: #02549e;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.Finance_Heading {
  font-size: 64px;
  font-weight: 800;
  text-align: center;
  margin-top: 1.5rem;
}

.Finance_para {
  font-size: 16px;
  text-align: center;
  margin-top: 3rem;
}

.financepackage_btn {
  display: flex;
  padding: 16px;
  justify-content: center;
  align-items: center;
  border-radius: 28px;
  border: 1px solid #fff;
  background: #fff;
  color: #02549e;
  font-weight: bold;
}

.financepackage_btn:hover {
  background: #f79826;
  color: #fff;
  border: 1px solid #f79826;
}

.finaciet_img {
  display: flex;
  justify-content: center;
  align-items: center;
}

.finaciet_des {
  display: flex;
  justify-content: center;
  align-items: center;
}

.finaciet_des p {
  width: 50%;
  text-align: center;
  font-weight: 600;
  color: black;
}

.financiat_terms {
  padding-left: 1.5rem;
}

.financiat_terms h4 {
  color: #02549e;
  font-size: 48px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
}

.financiat_terms ol {
  color: #414656;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 250%;
}

.Finance_getFinancing {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #d9d9d9;
  margin-top: 3rem;
  padding-top: 20px;
  padding-bottom: 20px;
}

.Finance_getFinancing h2 {
  color: #02549e;
  font-size: 48px;
  font-style: normal;
  font-weight: 800;
  line-height: 170%;
  text-align: center;
}

.Finance_getFinancing p {
  color: #414656;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 170%;
  text-align: center;
}

.Finance_Cards {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 30px;
}

.FinanceCallCard {
  display: flex;
  border-radius: 20px;
  background: #fff;
  width: 40%;
  height: 562px;
  padding: 70px 20px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
}

.FinanceCallCard h6 {
  color: #000;
  text-align: center;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.FinanceCallCard p {
  color: #000;
  text-align: center;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.FinanceCallCard_btn {
  border-radius: 28px;
  border: 1px solid #02549e;
  background: #02549e;
  display: flex;
  padding: 16px;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 20px;
  font-weight: 600;
  gap: 10px;
}

.Finance_faq h2 {
  font-size: 48px;
  font-weight: 600;
  color: black;
}

@media (max-width: 930px) {
  .FinanceCallCard {
    width: 47%;
  }
}

@media (max-width: 768px) {
  .Finance_Heading {
    font-size: 24px;
    margin-top: 1rem;
  }

  .Finance_para {
    font-size: 16px;
    margin-top: 1.5rem;
  }

  .finaciet_img img {
    width: 250px;
  }

  .finaciet_des p {
    width: 80%;
  }

  .financiat_terms {
    margin-top: 3rem;
    padding-left: 1rem;
  }

  .financiat_terms h4 {
    font-size: 32px;
  }
  .financiat_terms ol {
    font-size: 16px;
    margin-top: 1rem;
  }

  .Finance_getFinancing h2 {
    font-size: 32px;
    line-height: 170%;
  }

  .Finance_getFinancing p {
    font-size: 16px;
  }

  .Finance_Cards {
    flex-direction: column;
    gap: 50px;
  }
  .FinanceCallCard {
    width: 65%;
    height: 450px;
  }
  .FinanceCallCard h6 {
    font-size: 18px;
  }
  .FinanceCallCard p {
    font-size: 14px;
  }
  .Finance_faq h2 {
    font-size: 32px;
    font-weight: 600;
    color: black;
  }
  .call_Img {
    width: 150px;
    height: 150px;
  }
  .FinanceCallCard_btn {
    font-size: 14px;
  }
}

@media (max-width: 600px) {
  .FinanceCallCard {
    width: 95%;
    height: 450px;
  }
}

@media (max-width: 512px) {
  .Finance_getFinancing p {
    font-size: 14px;
  }
}
