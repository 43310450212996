.MortgageAdvisor_details {
  background: #fafafa;
}

.MortgageAdvisor_Gradient {
  background: linear-gradient(
    92deg,
    #9bafd9 0%,
    #02549e 41.78%,
    #40c9ff 99.05%
  );
  width: 100%;
  height: 250px;
  position: relative;
}

.MortgageAdvisor_Details {
  position: absolute;
  top: 100px;
  left: 80px;
  text-align: center;
  font-family: Montserrat;
}

.MortgageAdvisor_Pic {
  width: 224px;
  height: 224px;
  border: 10px solid #fff;
  border-radius: 224px;
}

.MortgageAdvisor_Pic img {
  border-radius: 224px;
  width: 100%;
  height: 100%;
}
.Advisor_InfrmName {
  margin-top: 5px;
  font-style: normal;
  line-height: normal;
}

.Advisor_InfrmName h3 {
  color: #000;
  font-size: 2.25em;
  font-weight: 700;
}

.Advisor_InfrmLevel p {
  color: #676767;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin: 0px !important;
}

.Advisor_InfrmRate p {
  color: #676767;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin: 0px !important;
  margin-top: 0.4rem !important;
}

.Advisor_InfrmLanguage p {
  color: #676767;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin: 0px !important;
  margin-top: 0.4rem !important;
}

.mortgageDetails_Icons {
  margin-top: 0.8rem;
  display: flex;
  justify-content: center;
}

.mortgageDetCallIcon {
  background-color: #f1f1f1;
  width: 54px;
  height: 54px;
  border-radius: 54px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.mortgage_iconsdet {
  font-size: 30px;
  font-weight: bold !important;
  color: #02549e;
}

.MortgageDetailsSection {
  display: flex;
  margin-left: 20rem;
  margin-top: 4rem;
}

.advisorDetailsBox {
  width: 32%;
  margin-right: 1rem;
  background-color: #ffff;
  border-radius: 20px;
  padding: 20px 10px 20px 20px;
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  font-family: Montserrat;
}

.AdvisorBox_heading {
  color: #000;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.AdvisorBox_des {
  color: #676767;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-top: 0.8rem !important;
}

.Advisor_callSection {
  display: flex;
  justify-content: space-around;
  width: 100%;
}

.AdvisorCall_headings {
  width: 40%;
}

.AdvisorCall_Details {
  width: 60%;
}

.advisor_desHead {
  color: #000;
  font-family: Montserrat;
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.advisorDes_details {
  margin-top: 1.5rem;
  color: #676767;
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.advisorMap_des {
  display: flex;
  justify-content: flex-start;
}

.advisor_map {
  height: 391px;
  margin-left: 4rem;
  width: 42%;
}

.advisor_des {
  margin-left: 1.5rem;
  width: 42%;
}

.advisor_footer {
  margin-top: 10rem;
}

@media (max-width: 1200px) {
  .MortgageAdvisor_Gradient {
    height: 210px;
  }

  .MortgageAdvisor_Details {
    display: flex;
    left: 50px;
  }

  .advisor_allDet {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    text-align: left;
    margin-top: 3rem;
    margin-left: 1.5rem;
  }

  .MortgageAdvisor_Pic {
    width: 190px;
    height: 190px;
    border-radius: 190px;
  }

  .MortgageAdvisor_Pic img {
    border-radius: 190px;
  }

  .Advisor_InfrmName h3 {
    font-size: 24px !important;
    color: white;
  }

  .Advisor_InfrmLevel p {
    font-size: 16px;
    color: white;
  }

  .Advisor_InfrmRate p {
    margin-top: 1.2rem !important;
    font-size: 16px;
  }

  .Advisor_InfrmLanguage p {
    font-size: 12px;
  }

  .mortgageDetails_Icons {
    justify-content: flex-start !important;
  }

  .mortgageDetCallIcon {
    width: 40px;
    height: 40px;
    border-radius: 40px;
  }

  .mortgage_iconsdet {
    font-size: 24px;
  }

  .MortgageDetailsSection {
    margin-top: 9rem;
    justify-content: space-between;
    margin-left: 2rem !important;
    margin-right: 2rem;
  }

  .advisorDetailsBox {
    width: 31%;
    border-radius: 20px;
    padding: 15px 10px 15px 10px;
  }

  .AdvisorBox_heading {
    font-size: 24px !important;
  }

  .AdvisorBox_des {
    font-size: 16px;
  }
}

@media(max-width: 768px){
.advisorMap_des{
  display: none;
}
.MortgageDetailsSection {
  display: flex;
  flex-direction: column;
  }
.advisorDetailsBox {
  margin: 1rem 0px 1rem 0px;
  width: 100% !important;
  padding: 15px 10px 15px 20px;
}

}

@media(max-width: 540px){
  .MortgageAdvisor_Gradient {
    height: 130px;
  }

  .MortgageAdvisor_Details {
    display: flex;
    left: 20px;
    top: 60px;
  }

  .advisor_allDet {
    margin-top: 0rem;
    margin-left: 0.9rem;
  }

  .MortgageAdvisor_Pic {
    width: 114px;
    height: 114px;
    border-radius: 114px;
  }

  .MortgageAdvisor_Pic img {
    border-radius: 114px;
  }

  .advisor_footer{
    margin-top: 5rem;
  }
}

@media(max-width: 320px){
  .MortgageAdvisor_Gradient {
    height: 150px;
  }

  .MortgageAdvisor_Details {
    display: flex;
    left: 20px;
  }

  .advisor_allDet {
    margin-top: 0rem;
    margin-left: 0.8rem;
  }

  .MortgageAdvisor_Pic {
    width: 120px;
    height: 120px;
    border-radius: 120px;
  }

  .MortgageAdvisor_Pic img {
    border-radius: 120px;
  }

  .Advisor_InfrmName h3 {
    font-size: 24px !important;
    color: white;
  }

  .Advisor_InfrmLevel p {
    font-size: 16px;
    color: white;
  }

  .Advisor_InfrmRate p {
    margin-top: 0.8rem !important;
  }
  .AdvisorBox_des{
    font-size: 12px;
  }

}
