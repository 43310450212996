.savedsearch-container {

    display: flex;
    justify-content: space-between;
    min-height: 10vh;
    align-items: center;
}

/* CSS */
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    /* background-color: rgba(0, 0, 0, 0.5); Adjust the alpha value to control transparency */
    z-index: 9999;
    /* Ensure the overlay is above other content */
    backdrop-filter: blur(5px);
    /* Apply the blur effect to the overlay */
    pointer-events: none;
    /* Disable pointer events to prevent interaction with the overlay */
}


.searchtitle {
    font-weight: bold !important;
}

.filterbox-container {
    display: flex;
    width: 40%;
    justify-content: space-between;
}

.btnpadding {
    padding: 0 24px;
    background-color: white;
    border-radius: 8px;
    border: 1px solid #dedede;

}

.btnpadding:hover {
    background-color: orangered;
    color: white !important;
}

.purplebtn {
    background-color: #6c35a3;
    color: white;
    font-size: 12px;
}

.font13 {
    font-size: 13px;
}

@media (max-width: 768px) {

    .fontsmall {
        font-size: 12px;
    }

    .flexDrirection {
        flex-direction: column !important;
    }

    .savedsearch-container {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        color: #0253A0;
        /* margin-top: 20px; */

    }

    .filterbox-container {
        width: 100%;
    }

    form {
        width: 100%;
    }

    .searchbox {
        /* margin-top: 20px; */
        border-radius: 25px;
        height: 40px;
    }
}