.coming_soonMain {
    background: linear-gradient(90deg, #FFEAD0 0%, #CDE5FF 100%);
    min-height: 100vh;
    font-family: "Open Sans";
}

.comingSoon_container {
    width: 100%;
    max-width: 1350px;
    margin: 0px auto;
}

.comingSoonLogo {
    width: 323px;
    height: 52px;
}

.coming_soonMain h1 {
    color: var(--Text-primary, #000);
    font-size: 49px;
    font-weight: 700;
    line-height: 120%;
    /* 58.8px */
}

.coming_soonMain h2 {
    font-size: 49px;
    font-weight: 700;
    line-height: 120%;
    background: linear-gradient(90deg, #9A72F9 13.76%, #F6731F 41.27%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}


.coming_soonMain h3 {
    color: var(--Accent-Black, #282828);
    font-size: 20px;
    font-weight: 400;
    line-height: 120%;
}

.coming_soonTimer {
    padding: 3px;
    width: 25%;
    max-width: 130px;
    border-right: 2px solid #007BFF;
    color: #007BFF;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 3px;
}


.coming_soonTimer h6 {
    font-size: 25px;
    font-weight: 700;
    line-height: 120%;
    margin: 0;
}

.coming_soonTimer p {
    margin: 0;
    font-size: 13px;
    text-transform: uppercase;
}

.comingSoon_subscriber img {
    border-radius: 200px;
    border: 1.5px solid var(--White, #FFF);
    width: 40px;
    height: 40px;
}

.comingSoon_subscriber img:not(:first-child) {
    margin-left: -12px;
}

.comingSoon_subscriber .subscriberCount {
    border-radius: 200px;
    border: 1.5px solid var(--White, #FFF);
    width: 40px;
    height: 40px;
    background: var(--Primary-Light-blue, #E2F1FF);
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--Primary-Natural-blue, #007BFF);
    font-size: 15px;
    font-weight: 600;
    line-height: 24px;
    margin-left: -12px;
    text-transform: capitalize;
}

.comingSoon_thumbnail {
    width: 100%;
    position: relative;
}

.comingSoon_thumbnail img {
    border-radius: 20px;
    object-fit: cover;
    height: 700px;
}

.comingSoon_thumbnail button {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border: none;
    outline: none;
    background-color: rgba(0, 0, 0, 0.15);
    border-radius: 12px;
    padding: 5px 20px;
}

.video-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
}

.video-modal-overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.644);
}

.video-modal-content {
    position: relative;
    max-width: 800px;
    width: 90%;
    background: #000;
}

.video-player {
    width: 100%;
}

.comingSoon_closebutton {
    position: absolute;
    top: 10px;
    right: 10px;
    color: #fff;
    background: none;
    border: none;
    font-size: 24px;
    cursor: pointer;
}




.subscriber_message {
    position: fixed;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    animation: slide-up 2s ease-in-out forwards;
    font-size: 1rem;
    color: #fff;
    background-color: #222;
    padding: 8px 12px;
    border-radius: 20px;
}

.coming_soon_policy {
    color: var(--Accent-Dark-Gray, #4A4A4A);
    font-size: 13px;
    font-weight: 400;
    line-height: 140%;
    width: 100%;
    max-width: 520px;
}

.coming_soon_policy a {
    color: #007BFF !important;
    text-decoration: underline !important;
}

.comingSoon_SubcriberInfo {
    font-family: "Open Sans";
}

.comingSoon_SubcriberInfo h4 {
    color: var(--Text-primary, #000);
    font-size: 49px;
    font-weight: 700;
    line-height: 120%;
}

.comingSoon_SubcriberInfo h6 {
    color: var(--Accent-Black, #282828);
    font-family: "Open Sans";
    font-size: 25px;
    font-weight: 400;
    line-height: 120%;
}

.comingSoon_SubcriberInfo .subscriber_role {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    width: calc(100%/3 - 2rem);
    border-radius: 10px;
    background: var(--White, #FFF);
    box-shadow: 0px 20px 24px -4px rgba(16, 24, 40, 0.08), 0px 8px 8px -4px rgba(16, 24, 40, 0.03);
    padding: 24px;
}

.comingSoon_SubcriberInfo .subscriber_role h6 {
    color: var(--Gray-900, #101828);
    font-size: 18px;
    font-weight: 600;
    line-height: 28px;
    text-align: center;
}

.comingSoon_SubcriberInfo .subscriber_role p {
    color: var(--Gray-600, #475467);
    text-align: center;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
}

.comingSoon_thanks{
    min-height: calc(100vh - 150px);
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: center;
}



@keyframes slide-up {
    from {
        transform: translate(-50%, 100%);
        opacity: 0;
    }

    50% {
        opacity: 1;
        transform: translate(-50%, 0);
    }

    to {
        opacity: 0;
        transform: translate(-50%, -100%);
    }
}

@media(max-width: 990px) {
    .comingSoon_SubcriberInfo .subscriber_role {
        width: calc(100%/2 - 1.5rem);
    }
}

@media(max-width: 768px) {
    .comingSoonLogo {
        width: 150px;
        height: 25px;
    }

    .coming_soonTimer h6 {
        font-size: 22px;
    }

    .coming_soonTimer p {
        font-size: 11px;
    }

    .coming_soonMain h1 {
        font-size: 43px;
    }

    .coming_soonMain h2 {
        font-size: 43px;
    }

    .coming_soonMain h3 {
        font-size: 18px;
    }

    .comingSoon_thumbnail {
        margin-top: 10px;
    }

    .comingSoon_thumbnail img {
        height: 430px;
    }

    .comingSoon_SubcriberInfo .subscriber_role {
        width: 100%;
    }
}