.Advertise_para {
  text-align: center;
  color: #f4f9ff;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 170%;
}

.Advertisement_terms h4 {
  color: #02549e;
  text-align: center;
  font-size: 40px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
}

.Advertise_access {
  color: #414656;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 250%;
  margin-left: 7rem;
}

.Advertise_Email {
  color: #02549e !important;
  font-size: 32px !important;
  font-style: normal;
  font-weight: 800 !important;
  line-height: normal;
}


@media(max-width: 768px){
    .Advertise_para {
        font-size: 14px;
        line-height: 150%;
      }
      .Advertisement_terms h4 {
        font-size: 24px;
      }
      .Advertise_access {
        font-size: 16px;
        line-height: 200%;
        margin-left: 2rem;
      }
      .Advertise_Email {
        font-size: 24px !important;
      }
}

@media(max-width: 512px){
      .Advertise_Email {
        font-size: 18px !important;
      }
      .Advertise_access {
        margin-left: 1.5rem;
      }
      .Advertisement_terms h4 {
        font-size: 18px;
      }
      .Finance_getFinancing h2{
        font-size: 20px;
      }
}

@media(max-width: 412px){
    .Advertise_Email {
      font-size: 16px !important;
    }
}