.seller_inf_cardContainer {
    background-color: #F5F5F5;
    font-family: Montserrat;
    padding: 4rem 0;
}

.seller_inf_mainHeading {
    color: #02549E;
    font-size: 32px;
    font-weight: 700;
}

.seller_inf_des {
    color: #676767;
    max-width: 80%;
    text-align: center;
    margin: 10px auto;
    font-size: 20px;
    font-weight: 400;
    line-height: normal;
}

.selling_adv_cardCointainer {
    display: flex;
    align-items: center;
    gap: 20px;
}

.SellingAdv_card {
    background-color: #fff;
    padding: 1.5rem;
    max-width: calc(50% - 20px);
    border-radius: 12px;
    min-height: 314px;
}

.sellerAdv_cardIndex {
    width: 58px;
    height: 58px;
    background-color: #EBF2FF;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.sellerAdv_cardIndex p {
    color: #02549E;
    font-size: 32px;
    font-weight: 700;
    margin: 0;
    background-color: #D7E5FF;
    border-radius: 50%;
    text-align: center;
    width: 48px;
    height: 48px;
}

.sellerAdv_cardHeading {
    color: #000;
    font-size: 24px;
    font-weight: 700;
    margin: 1.5rem 0 1rem 0;
}

.sellerAdv_cardDes {
    color: var(--Gray, #676767);
    font-size: 20px;
    font-weight: 400;
    margin: 0;
}

.sellerSavingSlider {
    font-size: 1rem !important;
    font-weight: 600 !important;
    color: #F6941C;
    font-family: Montserrat !important;
}


.infSeller_ValueHeading {
    color: #000;
    text-align: center;
    font-size: 48px;
    font-weight: 700;
    line-height: 140%;
}

.infSeller_ValueQuestion {
    color: #000;
    font-size: 24px;
    font-weight: 500;
    margin-top: 3rem;
}

.infSeller_ValueDescription {
    color: #676767;
    font-size: 16px;
    font-weight: 400;
    margin-top: 1.5rem;
}

.SellerInf_DesSelling {
    color: #7E7E7E;
    font-family: Montserrat;
    margin-top: 1.5rem;
}

@media(max-width:1200px) {

    .sellerAdv_cardDes {
        font-size: 16px;
    }
}


@media(max-width:800px) {
    .seller_inf_cardContainer {
        margin-top: 2rem;
    }

    .seller_inf_mainHeading {
        font-size: 20px;
    }

    .seller_inf_des {
        max-width: 100%;
        font-size: 16px;
    }

    .SellingAdv_card {
        max-width: 100%;
        min-height: auto;
    }

    .sellerAdv_cardIndex {
        width: 42px;
        height: 42px;
    }

    .sellerAdv_cardIndex p {
        width: 30px;
        height: 30px;
        font-size: 20px;
        margin: 0 !important;
    }

    .sellerAdv_cardHeading {
        font-size: 16px;
    }

    .sellerAdv_cardDes {
        font-size: 14px;
    }

    .seller_savingContiner {
        flex-direction: column;
        gap: 3rem;
        padding-left: 20px !important;
        padding-right: 20px !important;
    }

    .sellerInfText_center {
        text-align: center !important;
    }

    .infSeller_ValueQuestion {
        font-size: 20px;
        margin-top: 1rem;
    }

    .infSeller_ValueHeading {
        font-size: 32px;
    }

    .selling_value_mobile_view {
        flex-direction: column-reverse !important;
    }

    .infSeller_ValueDescription {
        font-size: 14px;
        margin-top: 1rem;
    }

    .SellerInf_DesSelling {
        margin-top: 0.5rem;
    }
}