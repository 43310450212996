.ca-1{
    width: 15rem !important;
    height: 100%;
}
.t-w{
    width: 50% !important;
}
.ca-2{
    font-family: "Inter";
    font-weight: 600;
    font-size: 32px;
    color: #000000;
}
.ca-3{
    font-family: "Inter";
    font-weight: 600;
    font-size: 32px;
    color: #0253A0;
}
.ca-4{
    font-family: "Inter";
    font-weight: 600;
    font-size: 24px;
    color: #F8A23E;
}
.ca-5{
    font-family: "Inter";
    outline: none;
    border: none;
    background-color: #4361EE;
    font-size: 20px;
    font-weight:600;
    color: #FFFFFF;
    width: 166px;
    height: 46px;
    border-radius: 5px;
}
