.imgclass {
  width: 159px;
  height: 82px;
  border-radius: 12px;
  padding-left: 35px;
}
.pr-img {
  border-radius: 50%;
  border: 3px solid #02549e !important;
  width: 36px ;
  height: 36px ;
}
.navimgclass {
  height: 34px;
  /* width: 64px; */
  /* padding-left: 35px; */
}
.nv-img {
  padding-right: 1rem !important;
}
.pl-5 {
  padding-right: 3rem !important;
}
.major {
  padding-right: 2rem;
}
.img {
  margin-left: 40px;
}
.marginright {
  margin-right: 15px;
}
.margin {
  margin-left: 170px;
}
.bton {
  background-color: #02549e;
  color: white;
  margin-right: 40px;
  height: 34px;
}
.bton:hover {
  background-color: #02549e;
  color: white;
  margin-right: 40px;
  height: 34px;
}
.set {
  font-weight: bold;
}
.buttons {
  margin-right: -30px !important;
}

.navborder {
  border: 2px solid #c9ccc8;
  border-radius: 8px;
  width: 70px;
  height: 40px;
}
.centering {
  /* margin-right: 40px; */
  font-weight: 500;
  font-size: 16px;
  /* background-color: #4fb020; */
}
.mar {
  margin-right: 10px;
}
.navcolor {
  background-color: white;
}

.navborders {
  background: linear-gradient(45deg, hwb(33 11% 4%), blue);
  height: 3px;
  background-size: 200% 100%;
  animation: gradientAnimation 5s linear infinite;
}

.down-icon {
  margin-right: 2px;
  margin-left: 15px;
  color: grey;
  width: 24px;
  height: 20.7px;
}

.btn-link.dropdown-toggle::after {
  content: none;
}
@keyframes gradientAnimation {
  0% {
    background-position: 0% 50%;
  }
  100% {
    background-position: 100% 50%;
  }
}
