.MapandListing_container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.MapandListingcard_container {
  width: 51%;
  height: 820px;
  overflow-y: scroll;
}

.MapandListingcard_container::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 4px rgba(129, 129, 129, 0.3);
  border-radius: 6px;
  background-color: #F5F5F5;
}

.MapandListingcard_container::-webkit-scrollbar {
  width: 10px;
  background-color: #F5F5F5;
}

.MapandListingcard_container::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 4px rgba(116, 116, 116, 0.3);
  background-color: #b6b6b6;
}

.searchPage_cardsContainer {
  width: 600px;
  min-height: calc(100vh - 400px);
}

.MapandListing_map {
  width: 48%;
  height: 820px;
}


.searchPage_mapContainer {
  width: calc(100% - 620px);
  min-height: calc(100vh - 400px);
}



.mapListing_allcards {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 10px;
  width: 100%;
}

.mapListing_allcards> :last-child {
  margin-right: 0;
  /* or adjust as needed */
}

.onlyListing_cards {
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
}

.onlymap_show {
  width: 100%;
  height: 75vh;
}

.list_btnscroll {
  border-radius: 33px;
  background: #000;
  color: white;
  font-family: Montserrat;
  font-size: 16px;
  padding: 15px;
  position: fixed;
  z-index: 100;
  bottom: 15px;
  left: 35%;
  gap: 10px;
}

.list_btnscroll button {
  outline: none;
  border: none;
  background-color: transparent;
}

.No_found_centerDiv {
  height: 820px;
  display: flex;
  align-items: center;
}

@media (max-width: 1180px) {
  .MapandListingcard_container {
    width: 305px;
  }

  .MapandListing_map {
    width: calc(100% - 315px);
  }
}


@media (max-width: 512px) {
  .mapListing_allcards {
    gap: 0px;
  }

}