.Contactimage {
  width: 100%;
  height: 700px;
}

.contact_mobile {
  display: flex;
  align-items: center;
  justify-content: center;
}

.adjustment {
  display: flex;
  flex-direction: column;
}

.font {
  font-family: "Montserrat";
}

.contactheading {
  font-weight: 600;
  font-size: 32px;
  padding-bottom: 20px;
}

.fieldhead {
  font-weight: 500;
  font-size: 16px;
  padding-bottom: 10px;
  padding-top: 5px;
}

.fieldsettings {
  width: 386px;
  height: 40px;
}

.fieldsettings2 {
  width: 386px;
  height: 40px;
}

.contact_heading {
  font-weight: 700;
  font-size: 32px;
  padding-top: 30px;
}

.contact_thanksMain {
  font-family: "Montserrat";
  background-color: #fafafa;
  color: black;
}

.ContactRes_footer {
  margin-top: 7rem;
}

@media(max-width: 960) {
  .contact_mobile {
    margin-bottom: 60px !important;
  }
}


@media (max-width: 800px) {
  .contact_mobile {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    margin-bottom: 60px !important;
    margin-left: 0px !important;
  }

  .mob_con_Field {
    width: 386px !important;
  }
}


@media (max-width: 768px) {

  .contact_mobile {
    margin-left: 0px !important;
  }
}

@media (max-width: 512px) {


  .mob_con_Field {
    width: 346px !important;
  }

  .mobile_con_Submit {
    width: 346px !important;
  }
}

@media (max-width: 360px) {

  .mob_con_Field {
    width: 300px !important;
  }

  .mobile_con_Submit {
    width: 300px !important;
  }
}

@media (max-width: 280px) {

  .mob_con_Field {
    width: 230px !important;
  }

  .mobile_con_Submit {
    width: 230px !important;
  }
}