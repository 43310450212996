.child1 {
  /* background-color: red; */
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 40px;
}
.child2x {
  /* background-color: red; */
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 60px;
}
.childx1 {
  /* background-color: rgba(255, 255, 255, 0.87); */
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 25px;
  font-family: Montserrat;
}
.maindata {
  width: 100%;
  height: 25vh;
}
.posted {
  display: flex;
  flex-direction: row;
  height: 25px;
}
.price {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  height: 25px;
}

.redcolor {
  font-weight: 600;
  font-size: 20px;
  color: red;
  margin-right: 4px;
}
.regis {
  font-weight: 400;
  font-size: 15px;
}
.cname {
  font-size: 15px;
  font-weight: 600;
}
.cardgreen {
  font-weight: 700;
  font-size: 16px;
  color: #02549e;
}
.cardprice {
  font-weight: 600;
  font-size: 14px;
  color: #ca0024;
  margin-right: 4px;
  text-decoration: line-through;
}
.cardprice2 {
  font-weight: 600;
  font-size: 14px;
  color: #ca0024;
  margin-right: 4px;
}
.cardprice1 {
  font-weight: 600;
  font-size: 11px;
  color: #ca0024;
  margin-right: 4px;
  text-decoration: line-through;
}
.closing {
  color: #ca0024;
  font-size: 13px;
  font-weight: 400;
}

.cardpara2 {
  font-size: 16px;
  font-weight: 400;

  font-family: Montserrat;
}
.cardpara {
  color: #02549e;
  font-size: 16px;
  font-weight: 400;
  font-family: Montserrat;
}
.cardpr {
  font-weight: 600;
  font-size: 10px;
  color: black;
  /* margin-right: 4px; */
}
.dividercolor {
  color: grey;
  margin-right: 2px;
  margin-left: 2px;
}
.viewmore {
  color: #02549e;
  font-size: 12px;
  /* margin-bottom: 13px; */
  height: 10px;
}

.divider {
  color: grey;
  margin-right: 2px;
  margin-left: 2px;
  font-size: 14px;
}
.faviconcolorred {
  color: #ca0024;
}
.faviconcolorgreen {
  color: #02549e;
}
.btngrey {
  background-color: rgb(219, 219, 219);
}

.ImageOverlaysetting {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 12px;
  width: 100%;
  padding: 0 10px;
}

.ImageOverlaysetting svg {
  font-size: 18px;
  margin-right: 2px;
  margin-left: 8px;
}
.cardajustments {
  color: black;
  padding-bottom: 20px;
}
.cardlinebordder {
  padding: 8px;
}
.justifycenter {
  display: flex;
  align-items: center;
}
.bottompad {
  padding-bottom: 15px;
}
.bg_heading_color{
  background-color: #cccccc;
}
.card1 {
  border: 2px solid #cccccc;
  padding: 10px;
  border-radius: 4px;
}
.btan {
  width: 100%;
  display: flex;
  align-items: flex-start;
  padding-left: 2px;
  background-color: #b3cbe3;
  color: #02549e;
}
.btan:hover {
  background-color: #b3cbe3;
  color: #02549e;
}
.grey {
  color: #6b6b6b;
}
.turningred {
  color: #ca0024;
}
.paading {
  padding-left: 12px;
  padding-right: 12px;
}
.paading2 {
  /* padding-left: 12px; */
  padding-right: 12px;
}
.shadow {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}

.ButtonOverlaySetting {
  margin-right: 32px;
  margin-bottom: 400px;
}
.listwidth {
  width: 100%;
  padding: 0px;
}
.btndecor {
  padding: 3px 6px 3px 6px;
  /* height: 63px; */
  background-color: #ffffff;
  color: #02549e;
  font-weight: 700;
  font-size: 10px;
  border-radius: 100px;
}

.btndecors {
  padding: 4px 4x 4px 4px;
  width: 50px;
  /* height: 63px; */
  background-color: #ffffff;
  color: #02549e;
  font-weight: 700;
  font-size: 13px;
  border-radius: 100px;
}
.btndecors:hover {
  background-color: #ffffff;
  color: #02549e;
}
.btndecorss {
  padding: 4px 4x 4px 4px;
  width: 50px;
  /* height: 63px; */
  background-color: #ffffff;
  color: #ff0000;
  font-weight: 700;
  font-size: 13px;
  border-radius: 100px;
}
.justifications {
  display: flex;
  justify-content: flex-end;
}
.butnmargn {
  margin-left: 4px;
}
.justifyung {
  display: flex;
  align-items: center;
  /* justify-content: center; */
}
.cardparent {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.boxss {
  align-items: center;
  justify-content: center;
}

.dealdecor {
  padding: 2px 6px 2px 6px;
  background-color: #ca0024;
  color: #ffffff;
  font-weight: 700;
  font-size: 10px;
  border-radius: 100px;
  border: none;
  outline: none;
}

.iconmodification {
  margin-left: 20px;
  margin-right: 20px;
}

.cardsflexes {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-left: 0.8rem;
}

.cardimage {
  height: 180px;
  width: 150px;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
}
.cardname {
  font-weight: 600;
  font-size: 16px;
}
.cardtextdata {
  font-size: 14px;
  font-weight: 400;
  padding-top: 5px;
}

.agentCard_main {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  width: 100%;
}


.agent_card{
  border-radius: 12px;
  border: 0.5px solid #d9d9d9;
  background: #fff;
  width: 31%;
}


@media(max-width: 930px){
  .agent_card{
    width: 45%;
  }
}

@media(max-width: 768px){
  .agent_card{
    width: 380px;
  }
}

@media (max-width: 320px) {
  .cardimage {
    height: 86px;
    width: 86px;
    border-radius: 12px !important;
  }
  .agent_card{
    border: none;
    margin: 0px !important;
    margin-bottom:  25px !important;
  }
}