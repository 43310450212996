.UserAll_listingCard {
    padding: 1.25rem;
    border-radius: 12px;
    background: #F5F5F5;
    max-width: 423px;
    width: 100%;
    font-family: Montserrat;
    cursor: pointer;
}

.UserAll_listingCardDateChiip {
    color: #676767;
    font-size: 12px;
    font-weight: 600;
    line-height: normal;
    border-radius: 30px;
    background: #FFF;
    padding: 10px;
    width: fit-content;
}

.active_emailChip{
    color: rgb(0, 86, 36);
    background-color: rgb(132, 250, 181)
}

.inactive_emailchip{
    background-color: rgb(255, 77, 109);
    color: rgb(255, 255, 255);
}

.UserAll_listingCardUserName {
    color: #02549E;
    font-size: 24px;
    font-weight: 700;
    line-height: normal;
}

.UserAll_listingCardUserEmail {
    color: #676767;
    font-size: 14px;
    font-weight: 500;
}

.UserAll_listingCardStatusBtn {
    display: flex;
    flex-wrap: wrap;
    gap: 12px;
}

.UserAll_listingCardStatusBtn button {
    outline: none;
    border: none;
    font-size: 12px;
    font-weight: 600;
    line-height: normal;
    padding: 7px 10px;
    border-radius: 20px;

}

.UserAll_listingCardSearchStatusBtn {
    outline: none;
    border: 0.5px solid #000 !important;
    background: #FFF;
    font-size: 12px;
    font-weight: 600;
    line-height: normal;
    padding: 7px 10px;
    border-radius: 20px;
    color: #121212;
    font-size: 12px;
    font-weight: 600;
    line-height: normal;
}

.Single_allListingHeading {
    font-size: 20px;
    color: #676767;
    font-weight: 600;
}

.singleUserBreadCrumbs_arrow {
    font-size: 18px !important;
}

.singleUserBreadCrumbs_slash {
    display: none !important;
}


.UserSingleListing_statusBtn {
    border-radius: 6px;
    border: 1px solid #D9D9D9;
    background: #FFF;
    color: #D9D9D9;
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: -0.32px;
}

.UserSingleListing_statusBtn:hover {
    border: 1px solid #000;
    color: #000;
}

.single_userListingStatusBtn {
    width: 100%;
    overflow-x: auto;
    white-space: nowrap;
    flex-wrap: nowrap !important;
}

/* Hide the scrollbar for WebKit browsers (Chrome, Safari) */
.single_userListingStatusBtn::-webkit-scrollbar {
    display: none;
}

/* Hide the scrollbar for Firefox */
.single_userListingStatusBtn {
    scrollbar-width: thin;
    scrollbar-color: transparent transparent;
}

/* For IE and Edge */
.single_userListingStatusBtn {
    -ms-overflow-style: none;
}

.ActiveUserListing_card {
    gap: 16px;
}

.Listingselect_favViews {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: 45px;
    border-top: 1px solid #d9d9d9;
}

.listingFav_views {
    color: #666;
    font-size: 12px;
    font-weight: 500;
    display: flex;
    padding: 6px 6px;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    background: #D9D9D9;
}


.listing_selectBox {
    width: 2.2rem !important;
}

.listing_selectBox svg {
    font-size: 1.2rem !important;
}

.listing_selctedDiv label {
    margin-right: 10px !important;
}


.user_saveSearchCard {
    border-radius: 8px;
    border: 0.25px solid #d8d8d8;
    background: #FFF;
    box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.10), 0px 5px 12px 0px rgba(0, 0, 0, 0.10);
    padding: 20px 15px;
    position: relative;
}

.user_savesearchesCardContainer {
    gap: 20px;
}

.user_saveSearchesList {
    border-top: 1px solid #9A9A9A;
}

.user_saveSearchCard h3 {
    color: #0253A0;
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 0.6rem;
}



.user_saveSearchesList ul {
    display: flex;
    flex-wrap: wrap;
    color: #7E7E7E;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin: 0;
    padding: 0;
}

.user_saveSearchesList ul li {
    position: relative;
    margin-right: 15px;
}

.user_saveSearchesList ul li::before {
    content: '\2022';
    color: #D9D9D9;
    margin-right: 10px;
}

.user_saveSearchesList ul li:last-child {
    margin-right: 0;
}

.User_saveSearchListBtn {
    position: relative;
    display: flex;
    gap: 10px;
    justify-content: flex-end;
}

.User_saveSearchListBtn button {
    border: none;
    outline: none;
    padding: 10px 25px;
    border-radius: 5px;
    background: #0253A0;
    color: #fff;
    font-size: 16px;
    font-weight: 600;
    cursor: pointer;
}


.conv_forAdmin {
    border-top: 1px solid #d9d9d9;
}


.favSearchcon_Margin{
    margin-top: 40px;
}

@media(max-width: 1300px) {

    .con_icon_container {
        padding-inline: 0.2rem !important;
    }


}


@media(max-width: 1180px) {
    .UserAll_listingCard {
        max-width: 100%;
    }
    .favSearchcon_Margin{
        margin-top: 0px;
    }
}


@media(max-width: 800px) {
    .HideUserCard_MblDate {
        display: none !important;
    }

    .Listing_mainText {
        padding: 4px 8px 4px 8px !important;
    }

    .con_all_item_container {
        padding-left: 0.3rem !important;
    }

}

@media(max-width: 860px) {
    .userCon_mblBed {
        font-size: 10px !important;
    }

    .con_sender_image {
        display: none;
    }
}


@media(max-width: 786px) {
    .admin_conversation_container {
        max-width: 100% !important;
    }

    .admin_chattingConDiv{
        padding: 0 !important;
    }

    .senderAdmin_message{
        padding: 0 1rem !important;
    }
}


@media(max-width: 768px) {

    .Single_allListingHeading {
        color: #000;
        font-weight: 700;
        font-size: 24px;
    }

    .singleUserBreadCrumbs_arrow {
        display: none !important;
    }

    .singleUserBreadCrumbs_slash {
        display: block !important;
        margin: 0 5px;
    }

    .UserSingleListing_statusBtn {
        border: 1px solid #D9D9D9 !important;
    }

    .UserSingleListing_statusBtn:hover {
        border: 1px solid #000 !important;
    }

    .ActiveUserListing_card {
        justify-content: center;
    }

    .user_saveSearchCard h3 {
        font-size: 16px;
        margin-bottom: 0.4rem;
    }


    .user_saveSearchesList ul {
        font-size: 12px;
    }

    .User_saveSearchListBtn {
        justify-content: center;
        margin-top: 0.5rem;
    }

    .User_saveSearchListBtn button {
        font-size: 14px;
    }

    .user_savesearchesCardContainer {
        gap: 10px;
    }

}

@media(max-width:512px) {
    .ActiveUserListing_card {
        gap: 0px;
    }

    .userCon_mblBed {
        padding: 0 0.2rem !important;
    }
}