.MuiTabs-root .MuiButtonBase-root {
  background-color: #FFFFFF;
  color: black;
  font-family: Montserrat;
}


.css-k008qs {
  justify-content: center !important;
  align-items: center !important;
}

/* Change background color of selected tab */
.MuiTabs-root .Mui-selected {
  /* background-color: #02549E; */
  background-color: #f6f4f4;
  color: black;
  font-family: Montserrat;
}

.colorblack {
  color: black;
}

.bg {
  background-color: #f6f4f4;
}

.css-r8ijwl-MuiTabs-root {
  background-color: #f6f4f4;
}
.learn_moreBtnTabs{
  padding: 10px 15px;
  font-weight: 500;
  color: #fff;
  background-color: #02549e;
}

.learn_moreBtnTabs:hover{
  color: #fff !important;
  background-color: #02549e !important;
}