.steeper2-section {
    position: relative;
}

.steeper-line {
    position: absolute;
    /* top: 124.5px;
    left: 1595.5px; */
    right: 90px;
    border-right: 5px solid #02549e;
    box-sizing: border-box;
    width: 5px;
    height: 100%;
}

.steeper-icons {
    position: absolute;
    /* left: 150px; */
    top: 100px;
    right: 0;
    width: 72px;
    height: 444px;
    text-align: center;
    font-size: 32px;
    color: #d9d9d9;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 40px;
}

.steeper-icon {
    position: relative;
    width: 55px;
    height: 55px;
}

.steeper-icon-circle {
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 50%;
    background-color: #fff;
    box-sizing: border-box;
    width: 55px;
    height: 55px;
}

.steeper-icon-active {
    border: 4px solid #02549E;
    /* Set a transparent border */
}

.steeper-icon-label-active {
    color: #02549E;
}

.steeper-icon-inactive {
    border: 2px solid #d9d9d9;
}

.steeper-icon-label {
    position: absolute;
    top: 3px;
    left: 15px;
    font-weight: 600;
    display: inline-block;
    width: 23px;
    height: 22px;
}

.pollygon-steeper1 {
    right: 92px;
    border-style: solid;
    top: 20px;
    border-color: transparent #02549e transparent transparent;
    position: absolute;
    border-width: 5px 25px 15px 0px;
}


.pollygon-steeper2 {
    right: 92px;
    border-style: solid;
    top: 115px;
    border-color: transparent #02549e transparent transparent;
    position: absolute;
    border-width: 5px 25px 15px 0px;
}


.pollygon-steeper3 {
    right: 92px;
    border-style: solid;
    top: 210px;
    border-color: transparent #02549e transparent transparent;
    position: absolute;
    border-width: 5px 25px 15px 0px;
}


.pollygon-steeper4 {
    right: 92px;
    border-style: solid;
    top: 305px;
    border-color: transparent #02549e transparent transparent;
    position: absolute;
    border-width: 5px 25px 15px 0px;
}







input[type="checkbox"] {
    width: 15px;
    /* Adjust the width as needed */
    height: 15px;
    /* Adjust the height as needed */
}


.HighlightselectedPkg {
    border-radius: 8px;
    padding: 10px;
    background-color: #F5F5F5;
}

.highlightPkg {
    border: 3px solid;
    border-color: #02549e;

}

.fontdesign-pkg {
    font-weight: 700;
    color: #7E7E7E;
}

.imghw {
    height: 100px;
    width: 100px;
}

/* ----------------------- */




.dynamicFont12 {
    font-size: 12px;
}


.steeperWidth {
    width: 100px;
}

.margincard {
    margin-top: 3rem;
}

.getquote_card_padding {
    padding: 2rem;
}

.pkg_categorys {
    width: 48%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.pkg_daysn{
    width: 52%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.Add_pkgIcon{
    height: 35px;
    cursor: pointer;
}

.quotes_phone_input{
    border: 1px solid #e0e0e0 !important;
    padding: 5px 12px !important;
}

.pkg_totaln{
    font-size: 20px;
    font-weight: 600;
}

.selectedpkg_outline{
    border: 3px solid #0253A0;
}

@media(max-width:930px){
    .mob_pricing_carousel{
        display: none;
    }

    .mob_pricing_packages{
        width: 100% !important;
    }
}

@media (max-width:800px) {
    .pkg_categorysMob {
        display: flex;
        justify-content: space-between;
        width: 100%;
        align-items: center;
        margin-top: 0.3rem;
    }
}


@media (max-width:768px) {
    .steeperWidth {
        width: 80px !important;
    }

    .getquote_card_padding {
        padding: 2rem 1rem 4rem 1rem !important;
    }
}

@media (max-width:525px) {
    .fullwidthcolumn {
        flex-direction: column !important;
    }

    .steeperWidth {
        width: 70px !important;
    }

    .fontdesign-pkg {
        font-weight: 400 !important;
        color: #7E7E7E;
        font-size: 14px;
        margin-left: 8px !important;
    }

    .ticker_mobn{
        height: 35px !important;
    }

    .getquote_card_padding {
        padding: 2rem 1rem 4rem 1rem !important;
    }

    .fontmobile16 {
        font-size: 16px !important;
        margin: 0 !important;
    }

    .fontmobile20 {
        font-size: 22px !important;
    }

    .fontmobile14 {
        font-size: 14px !important;

    }


    .mobile_padding_input {
        padding: 0 !important;
    }

    .margincard {
        margin-top: 1rem;
    }

    .bottom {
        bottom: 0 !important;
    }

    .margin_bottom {
        margin-bottom: 1rem !important;
    }
    .Add_pkgIcon{
        height: 25px;
    }
    .pkg_totaln{
        font-size: 16px;
    }
}