.topContainer {
  background-color: #02549e;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}


.content_Heading1 {
  color: #02549e;
  font-family: Montserrat;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-top: 2rem;
}

.contentPara {
  color: #000;
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 170%;
}

.contentList {
  list-style: disc;
}

.HelpLink {
  text-decoration: underline;
  color: #000;
  font-family: Montserrat;
}

.ManageBtn {
  outline: none;
  border-radius: 20px;
  border: 1px solid #000;
  color: #000;
  font-family: Montserrat;
  display: flex;
  padding: 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  background-color: transparent;
}

@media (max-width: 800px) {

  .content_Heading1{
    font-size: 22px;
    margin-top: 2rem !important;
  }

}
