/* body {
  overflow-x: hidden;
} */
/* Justifications */
.aligncenter {
  display: flex !important;
  align-items: center !important;
}

.underLineName:hover {
  text-decoration: underline;
}

.All__Mails {
  font-size: 30px !important;
  font-weight: 900 !important;
}

.ID__E {
  background-color: #ffffff;
  border-radius: 100px;
  color: #02549e;
  font-weight: 600;
  font-size: 19px;
}

.ID__EG {
  background-color: #f1f1f1;
  border-radius: 100px;
  color: #02549e;
  font-weight: 600;
  font-size: 19px;
}

.ml-1 {
  margin-left: 1rem !important;
}

.dynamicFont14 {
  font-size: 14px !important;
}

.primary-color {
  color: #02549e !important;
}

.primary-bg {
  background-color: #02549e !important;
}

.primary-bg:hover {
  background-color: #02549e;
}

.table_Image {
  width: 25px;
  height: 25px;
}

.pad-r-0 {
  padding-right: 0px !important;
}

.redError {
  color: red !important;
}

.pl-08 {
  padding-left: 1px !important;
}

.pl-09 {
  padding-left: 2px !important;
}

.Prop____Image {
  width: 140px !important;
  height: 100px !important;
  border-radius: 12px;
  object-fit: fill;
}

.Car__Prop____Image {
  width: 100px;
  height: 70px;
  border-radius: 12px;
  /* object-fit: fill; */
}

/* .Car__Prop____Image {
  width: 7.5vw;
  height: 11vh;
  border-radius: 12px;
} */

.bear__Man {
  border-radius: 50% !important;
}

.textbox {
  height: 39px !important;
}

.MailPutting {
  /* padding: 8px; */
  border: none !important;
}

.text__VE {
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 500;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
  color: #676767;
}

.text__VES {
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
  color: #676767;
}

.dollar__Price {
  font-family: Montserrat;
  font-size: 22px;
  font-weight: 520;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
}

.dollar__Price__2 {
  font-family: Montserrat;
  font-size: 22px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
}

.bear__Man {
  height: 20px;
}

.grey_____BAck {
  background-color: #ffffff;
  border-radius: 17px;
  font-size: 14px;
  color: #676767;
  padding: 6px;
  font-family: Montserrat;
  font-size: 1vw;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: center;
}

.grey_____BAckm {
  background-color: #ffffff;
  border-radius: 17px;
  font-size: 14px;
  color: #676767;
  padding: 6px;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: center;
}

.delete__Separator {
  padding-left: 5rem;
}

.grey_____BAckS {
  background-color: #f1f1f1;
  border-radius: 17px;
  padding: 6px;
  font-family: Montserrat;
  font-size: 0.8vw;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: center;
}

.Grey__BOT {
  border-bottom: 1px solid #d9d9d9;
}

.TitleMenuWidth {
  width: 22% !important;
  height: 47px !important;
}

.del__TEXST {
  font-family: Montserrat;
  font-size: 1.3vw;
  cursor: pointer;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: right;
  color: #0253a0;
}

.del_m_TEXST {
  font-family: Montserrat;
  font-size: 16pxs;
  cursor: pointer;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: right;
  color: #0253a0;
}

.norm__Text {
  font-size: 14px;
  color: #676767;
}

.norm__Text2 {
  font-size: 20px;
  color: #02549e !important;
  font-weight: 500;
}

.norm__Text3 {
  font-size: 14px;
  color: #02549e !important;
  font-weight: 500;
}

.greyCAARD {
  background-color: #f5f5f5;
  width: 100% !important;
  padding: 0.7rem !important;
  border-radius: 12px;
}

.TimeAgoText {
  font-family: Montserrat;
  font-weight: 500;
  padding-right: 0.6rem !important;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: right;
  color: #676767;
}

.Grey__Border {
  display: none;
  border: 2px solid #cccccc;
  border-radius: 12px;
}

.hs-m1 {
  background-color: #ffffff;
  /* padding-left: 25px; */
}

.Conversation__Seting {
  overflow-x: hidden;
}

.hs-11 .css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root {
  padding: 0px !important;
  padding-left: 7px !important;
  font-size: 12px !important;
  padding-right: 7px !important;
  color: #ffffff;
}

.back_IS_BLUE {
  background-color: #02549e !important;
}

.Text_Of_Modal {
  color: #ffffff;
  font-family: Montserrat;
  font-size: 20px;
  font-weight: 600;
  line-height: 32px;
}

.Button__OF__MODA {
  background-color: #ffffff;
  color: #02549e;
  font-family: Montserrat;
  font-size: 20px;
  width: 9rem;
  font-weight: 600;
  line-height: 32px;
}

.Button__OF__MODA:hover {
  background-color: #ffffff;
  color: #02549e;
  font-family: Montserrat;
  font-size: 20px;
  font-weight: 600;
  line-height: 32px;
}

.lowerData {
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  color: #676767;
}

.mailER__MSF {
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  color: #676767;
}

.CardBOX {
  background-color: #f5f5f5;
  color: #000000;
  height: 10rem !important;
  border-radius: 8px;
  width: 37rem !important;
  margin-right: 1rem !important;
}

.cards__IN__ROW {
  overflow-x: scroll;
}

.cards__IN__ROW::-webkit-scrollbar {
  width: 1px;
  background-color: #02549e;
}

.cards__IN__ROW::-webkit-scrollbar-thumb {
  background-color: #02549e;
  width: 1px !important;
  border-radius: 6px;
}

.House__Image {
  width: 100px;
  height: 100px;
  border-radius: 10px;
}

.hs-11 .css-zun73v {
  padding: 0px !important;
  padding-left: 7px !important;
  font-size: 12px !important;
  padding-right: 7px !important;
  color: #ffffff;
}

.category_Button {
  height: 50px;
  font-size: 14px;
  background-color: #02549e;
  color: #ffffff;
}

.category_Button:hover {
  height: 50px;
  background-color: #02549e;
  color: #ffffff;
}


@media (max-width: 786px) {
  .my_messages_moble_heading {
    margin-top: 4rem !important;
  }

  .mobile_message_container {
    width: 100% !important;
    left: 0 !important;
    margin: 0px !important;
  }

  .messages_serach_filter {
    width: 100%;
    margin-top: 1rem;
  }

  .messages_Searchbar_form {
    width: 100%;
  }
}



@media (max-width: 768px) {
  .mob__Con__Margins {
    margin-top: 4rem !important;
  }

  .mob__Con__Margin {
    margin-top: 1rem !important;
  }

  .listing-containers {
    padding-inline: 1rem !important;
  }

  .mobile_message_container {
    margin: 0px !important;
  }
}

.cardpara {
  color: #02549e;
  font-size: 16px;
  font-weight: 400;
  font-family: Montserrat;
}

.hs-bor1 {
  border: 1px solid #cccccc;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
  border-top-right-radius: 12px;
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.hs-sb {
  background-color: #0253a0;
  border-radius: 1px;
  border-top-right-radius: 50px;
  border-bottom-right-radius: 50px;
}

.aptf {
  height: 20px !important;
}

.hs-m2 {
  background-color: #0253a0;
}

.hs-pl2 {
  padding-left: 1rem !important;
}

.hs-pll2 {
  padding-left: 4px;
}

.heartIcon {
  font-size: 1.7rem !important;
}

.heartIcon:hover {
  font-size: 1.7rem !important;
  color: #ca0024;
  cursor: pointer;
}

.outlined:hover {
  color: #ca0014;
  /* Change to the color of the filled heart icon */
  cursor: pointer;
}

.filled {
  color: #02549e;
  /* Color of the filled heart icon */
}

.outlined {
  color: #ca0014;
  /* Color of the outlined heart icon */
}

.hs-1 {
  background-color: #f6941c;
  color: #ffffff;
  display: inline;
  padding: 2px 2px 2px 6px;
  border-radius: 4px;
}

.hs-11 {
  background-color: #f6941c;
  color: #ffffff;
  display: inline-block;
  padding: 2px 2px 2px 6px;
  border-radius: 4px;
  margin-left: 4px;
  margin-bottom: 4px;
}

.hs-li {
  padding-bottom: 4px;
}

.pr-4 {
  padding-right: 6rem !important;
}

.hs-3 {
  background-color: #0253a0;
  color: #ffffff;
  font-family: "Montserrat";
  font-weight: 500;
  height: 2rem !important;
  font-size: 14px;
  /* border-radius: 10px; */
}

.hs-3:hover {
  background-color: #024788;
  color: #ffffff;
}

.hs-4 {
  background-color: #c4c4c4;
  color: #ffffff;
  font-family: "Montserrat";
  font-weight: 500;
  font-size: 14px;
  height: 2rem !important;
}

.bg_typeButton {
  background-color: #6c35a3;
  color: white;
}

.bg_typeButton:focus,
.bg_typeButton:hover {
  background-color: #6c35a3;
  color: white;
}

.hs-6 {
  font-weight: 600;
}

.hs-cs1 {
  background-color: #c4c4c4;
  font-weight: 600;
  color: #000000;
}

.hse-cs1 {
  background-color: #ffffff;
  border: 2px solid #02549e;
  font-weight: 600;
  color: #02549e;
  border-radius: 50px;
  height: 3rem;
}

.hse-cs1:hover {
  background-color: #ffffff;
  border: 2px solid #02549e;
  font-weight: 600;
  color: #02549e;
  border-radius: 16px;
  height: 3rem;
}

.hs-cs1:hover {
  background-color: #c4c4c4;
  font-weight: 600;
  color: #000000;
}

.hs-cs2 {
  background-color: #0253a0;
  font-weight: 600;
  color: #ffffff;
}

.topBorder {
  border-top: 3px solid #878787;
  width: 4rem !important;
  padding-bottom: 2rem !important;
}

.hs-cs2e {
  background-color: #0253a0;
  font-weight: 600;
  color: #ffffff;
  border-radius: 50px;
  height: 3rem;
}

.hs-cs2e:hover {
  background-color: #0253a0;
  font-weight: 600;
  color: #ffffff;
  border-radius: 16px;
  height: 3rem;
}

.hs-cs2:hover {
  background-color: #0253a0;
  font-weight: 600;
  color: #ffffff;
}

.hs-8 {
  display: flex;
  flex-direction: row;
}

.hs-4:hover {
  background-color: #b4b4b4;
  color: white;
}

.css-919eu4 {
  position: fixed;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  inset: 0px;
  background-color: rgba(0, 0, 0, 0.25) !important;
  -webkit-tap-highlight-color: transparent;
  z-index: -1;
}

.hs-2 {
  padding-left: 3px !important;
  padding-bottom: 2px !important;
  font-size: 1.2rem !important;
}

.hs-ud {
  padding-top: 15px !important;
  position: relative;
  top: -8px;
  background-color: white;
  padding-bottom: 10px !important;
  border: 1px solid #cccccc;
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
}

.hsd-p {
  padding-left: 0px !important;
  padding-right: 1.2rem !important;
}

.alignleft {
  display: flex;
  align-items: flex-start;
}

.alignright {
  display: flex;
  align-items: flex-end;
}

.webFont14 {
  font-size: 14px !important;
}

.justifycenter {
  display: flex;
  justify-content: center;
}

.justifyleft {
  display: flex;
  justify-content: flex-start;
}

.justifyright {
  display: flex;
  justify-content: flex-end;
  /* color: #ca0024; */
}

.App {
  text-align: center;
}

.alignright {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.floatright {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.align-center {
  display: flex;
  align-items: center;
}

.margin__Negitive {
  margin-top: -1.5rem !important;
  text-align: end;
}

.border_Section {
  border: 3px solid #80808040;
  border-radius: 4px;
}

.searchbor {
  border-right: 1px solid #cccccc;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

.offmarket {
  font-weight: 900;
  font-size: 40px;
}

.hiddenscroll {
  overflow-x: hidden;
}

hiddenscroll::-webkit-scrollbar {
  display: none;
  /* for Chrome, Safari, and Opera */
}

.mapandcard {
  /* margin-left: 12px; */
  margin-top: 10px;
  /* max-height: 140vh; */
  overflow: hidden;
}

.share__logos {
  width: 29px;
  height: 29px;
}

.share__logos:hover {
  cursor: pointer;
}

.googleapi {
  width: 100% !important;
  height: 100% !important;
}

.font {
  font-family: Montserrat;
}

.googlemapsapi {
  width: 100px;
  height: 600px;
}

.weightfont {
  font-size: 40px;
  font-weight: 700;
}

.lpsorting {
  display: flex;
  flex-direction: row;
  margin: 12px 25px;
  justify-content: space-between;
  font-family: Montserrat;
}

.floatright {
  float: right;
}

.lprice {
  font-weight: bolder;
  font-size: 24px;
  display: flex;
  flex-direction: row;
}

.results {
  font-weight: 500;
  font-size: 20px;
  margin-left: 8px;
  margin-top: 3px;
}

.marg {
  margin-right: 20px;
}

.sizing {
  height: 46px;
  width: 114px;
  float: right;
}

.ri8pad {
  padding-right: 45px;
}

.sizings {
  height: 40px;
  /* width: 80px; */
  margin-left: 15px;
  margin-top: 25px;
  margin-bottom: 25px;
  float: right;
}

.btngreycolor {
  background-color: rgb(207, 206, 206);
}

.sortbtn {
  display: flex;
  flex-direction: row;
}

.cardsmapping {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 9px;
}

.cardsmapper {
  display: grid;
  gap: 20px;
}

.dealsheading {
  font-weight: 600;
  font-size: 20px;
  padding-top: 40px;
  padding-bottom: 10px;
}

.maindataxx {
  width: 100%;
  height: 212px;
  padding-bottom: 10px !important;
}

.scroller {
  height: 97vh;
  overflow-y: scroll;
  display: flex;
  flex-wrap: wrap;
  /* width: min-content; */
}

.xscroll {
  overflow-x: scroll;
  overflow-y: hidden;
  display: flex;
  flex-wrap: nowrap;
}

.grid-container {
  display: grid;
  grid-template-columns: auto auto;
  grid-column-gap: 50px;
  grid-row-gap: 10px;
  height: 600px;
  overflow-y: scroll;
  /* overflow-x: scroll; */
}

.cardsmapping4 {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  row-gap: 20px;
}

.separation {
  border-right: 2px solid #cccccc;
}

.scrollsxe {
  height: 80vh;
  overflow-x: hidden;
  overflow-y: scroll;
}

.scrollsxe::-webkit-scrollbar {
  width: 2px;
  background-color: rgb(255, 255, 255);
}

.scrollsxe::-webkit-scrollbar-thumb {
  background-color: #929292;
  border-radius: 6px;
}

.scrollsxe2::-webkit-scrollbar {
  width: 2px;
  background-color: rgb(255, 255, 255);
}

.scrollsxe2::-webkit-scrollbar-thumb {
  background-color: #929292;
  border-radius: 6px;
}

.scrollsxe2 {
  height: 85vh;
  overflow-x: hidden;
  overflow-y: scroll;
}

.Convo_Sp {
  font-size: 6vw;
  color: #ff9d00;
  font-weight: 900;
}

.Click_SP {
  font-size: 3vw;
  color: #02549e;
  font-weight: 900;
}

.active-email {
  background-color: #f0efef !important;
  border-radius: 8px !important;
  padding-right: 0.5rem !important;
}

.giveMeEllipsis1 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  /* number of lines to show */
  line-height: 100%;
  /* fallback */
  max-height: X * N;
  /* fallback */
}

.googlemaps {
  height: 600px;
  width: 100%;
}

.googlemapspopup {
  height: 300px;
  width: 100%;
}

.map {
  width: 600px;
  height: 6000px;
}

.slider {
  padding-top: 20px;
}

.header {
  background-color: red;
}

/* .heading {
  margin: 12px;
} */
.textfooter {
  align-items: center;
  justify-content: center;
  background-color: #000000;
}

.diff {
  font-weight: bolder;
  font-size: 35px;
  font-family: sans-serif;
}

.diff1 {
  font-weight: 400;
  font-size: 20px;
}

.container {
  max-width: 1240px !important;
}

.coloring {
  background-color: white;
  font-family: Montserrat;
}

.assignments {
  border: 1px solid rgb(197, 197, 197);
  border-radius: 12px 12px 0px 0px;
  position: absolute;
  top: -49px;
  background-color: rgb(255, 255, 255);
}

.tabpanel {
  margin-right: 170px;
  margin-left: 170px;
}

.text-primary {
  color: #02549e !important;
}

.accor {
  margin-right: 150px;
  margin-left: 150px;
}

.fontsizer {
  font-size: 40px;
}

.font-weight-bold {
  font-weight: bolder !important;
  font-family: inherit !important;
}

.font-weight-bold {
  font-weight: bolder !important;
  font-family: inherit !important;
}

.searchicon {
  min-height: 14vh;
  background: #02549e;
  padding: auto;
  align-items: center;
  overflow: hidden;
  /* z-index: -99; */
  border-bottom-right-radius: 100px;
  border-top-right-radius: 100px;
}

.assignmentSearch {
  position: relative;
}

.assign {
  padding: 10px 30px 9.5px 30px;
  margin: 0 !important;
  color: #015aad;
  font-weight: 700;
  font-size: 118%;
  font-family: "Montserrat";
}

.icoonfont {
  font-size: 24px;
}

.leftpadbuilding {
  padding-left: 10px;
}

.btoncss {
  border: 1px solid #cccccc;
  border-radius: 7px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.btoncss button {
  border: none;
  background-color: transparent;
}

.btoncss:hover {
  border: 1px solid #cccccc;
  border-radius: 7px;
}

.btoncss2 {
  border: 1px solid #cccccc;
  border-radius: 4px;
  height: 58px;
}

.butoncss {
  border: 1px solid #cccccc;
  border-radius: 7px;
}

.butoncss:hover {
  border: 1px solid #cccccc;
  border-radius: 7px;
}

.button_arrow {
  width: 1.5rem;
  height: 1.5rem;
  color: #b0b0b0;
  margin-left: 0px !important;
  margin-right: 0px !important;
}

.flexingrow {
  display: flex;
  flex-direction: row;
}

.bookingbuttons {
  display: flex;
  flex-direction: row;
  margin-top: 12px;
}

.btnbed {
  border: 1px solid grey;
  width: 55px;
  height: 30px;
  margin-left: 6px;
  margin-bottom: 6px;
}

.btnbed2 {
  border: 1px solid grey;
  background-color: #02549e;
  color: white;
  width: 55px;
  height: 30px;
  margin-left: 6px;
  margin-bottom: 6px;
}

.btnbed2:hover {
  border: 1px solid grey;
  background-color: #02549e;
  color: white;
  width: 55px;
  height: 30px;
  margin-left: 6px;
  margin-bottom: 6px;
}

.btnbeds {
  border: 1px solid grey;
  width: 35px;
  margin-left: 6px;
  margin-bottom: 6px;
  height: 30px;
}

.Dash__Button {
  border: 1.5px solid #e5e7eb;
  height: 46px;
  width: 8vw !important;
  font-size: 14px;
  font-weight: 400;
  /* margin-left: 0.8rem; */
  color: #02549e;
}

.Dash__Button:hover {
  border: 1.5px solid #e5e7eb;
  height: 46px;
  font-weight: 400;
  width: 8vw !important;
  font-size: 14px;
  margin-left: 0.8rem;
  color: #02549e;
}

.Dash__Button2 {
  border: 1.5px solid #e5e7eb;
  border-radius: 0px;
  height: 46px;
  font-size: 14px;
  color: #9ca3af;
}

.MessagesFonts {
  font-family: Montserrat;
  font-weight: 700;
  line-height: 29px;
  letter-spacing: 0em;
  text-align: left;
}

.WeekRow {
  font-family: "Montserrat" !important;
  font-weight: 500;
  background-color: #ffffff !important;
}

.btnbeds:hover {
  border: 1px solid grey;
  width: 35px;
  margin-left: 6px;
  margin-bottom: 6px;
  height: 30px;
}

.btnbeds2 {
  border: 1px solid grey;
  background-color: #02549e;
  color: white;
  width: 35px;
  margin-left: 6px;
  margin-bottom: 6px;
  height: 30px;
}

.btnbeds2:hover {
  border: 1px solid grey;
  background-color: #02549e;
  color: white;
  width: 35px;
  margin-left: 6px;
  margin-bottom: 6px;
  height: 30px;
}

.btnbedzzz {
  border: 1px solid grey;
  width: 4rem;
  margin-left: 6px;
  margin-bottom: 6px;
  height: 30px;
}

.btnbedzzz:hover {
  border: 1px solid grey;
  width: 4rem;
  margin-left: 6px;
  margin-bottom: 6px;
  height: 30px;
}

.btnbedzzz2 {
  border: 1px solid grey;
  background-color: #02549e;
  color: white;
  width: 4rem;
  margin-left: 6px;
  margin-bottom: 6px;
  height: 30px;
}

.btnbedzzz2:hover {
  border: 1px solid grey;
  background-color: #02549e;
  color: white;
  width: 4rem;
  margin-left: 6px;
  margin-bottom: 6px;
  height: 30px;
}

.parkingbtns {
  margin-left: 40px;
}

.dynamic_margin_date {
  margin-top: 10px !important;
}

.bathbtns {
  margin-left: 12px;
}

.filtermargin {
  margin-left: 12px;
}

.btndiv {
  margin-left: 17px;
}

.pricecalculator {
  font-size: 35px;
  font-weight: 900;
  color: #0253a0;
}

.landTax__price {
  font-size: 28px;
  font-weight: 900;
  color: #0253a0;
}

.drawerbuttons {
  padding: 4px;
}

.rangepadding {
  /* padding-left: 40px; */
  padding-right: 8px;
}

.textindrawerfield {
  font-weight: 600;
  font-size: 14px;
  color: #7ea5cb;
}

.drawerbtoncss {
  border: 1px solid gray;
  border-radius: 7px;
  margin-right: 6px;
  margin-top: 6px;
}

.drawerbtoncss:hover {
  border: 1px solid gray;
  border-radius: 7px;
  margin-right: 6px;
  margin-top: 6px;
}

.heading__grey_backgroud {
  background-color: #80808040;
}

.drawerbtoncss2 {
  border: 1px solid #02549e;
  background-color: #02549e;
  color: white;
  border-radius: 7px;
  margin-right: 6px;
  margin-top: 6px;
}

.drawerbtoncss2:hover {
  border: 1px solid #02549e;
  background-color: #02549e;
  color: white;
  border-radius: 7px;
  margin-right: 6px;
  margin-top: 6px;
}

.sl2 {
  margin-left: 30px;
}

.sl1 {
  margin-left: 50px;
}

.rangepadding {
  padding-left: 8px;
}

.rslider {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.float {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.heartmargin {
  margin-right: 15px;
  /* margin-top: 6px; */
}

.AddIcon {
  font-size: 35px;
  color: #02549e;
}

.leftpad {
  padding-left: 4px;
}

.searchbtn {
  font-size: 1.8rem;
  padding: 6px;
  background-color: #02549e;
  color: white;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.btn-click {
  background-color: white;
  border: none;
  border-top-right-radius: 8px !important;
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
  height: -webkit-fill-available;
}

.price-plan-image {
  background: white;
  padding: 1rem;
  height: 100%;
  margin: 3rem;
  /* width: 20%; */
  border-radius: 50%;
}

.drawerdisp {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  position: sticky;
  top: 0;
  z-index: 2;
}

.closee {
  color: white;
}

.filter_para {
  margin: 0px !important;
}

.filter_buttonPara {
  padding-left: 12px !important;
}

.filter_heading {
  font-weight: 700;
}

.nonedecor {
  text-decoration: none;
  color: white;
}

.mainmargin {
  margin-right: 12px;
  margin-left: 12px;
  margin-top: 12px;
}

.googleapiss {
  height: 100% !important;
}

.width {
  width: 500px;
  height: 30px;
}

.barinput {
  border: 1px solid gray;
  border-radius: 7px;
  width: 350px;
}

.margins {
  margin-top: 15px;
  border-radius: 5px;
  border: 1px solid rgb(197, 197, 197);
  padding: 8px;
}

.borders {
  border: 1px solid rgb(197, 197, 197);
  align-items: center;
  /* padding: 3px; */
  width: 100%;
  border-radius: 1px;
  border-top-right-radius: 50px;
  border-bottom-right-radius: 50px;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.MapListImage {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 11px;
  width: 100%;
  padding: 0 10px;
}

.iamgeiconclass {
  font-size: 5px;
}

.fonting {
  font-size: 12px;
}

.css-i9fmh8-MuiBackdrop-root-MuiModal-backdrop {
  background-color: rgba(0, 0, 0, 15%) !important;
}

.css-1160xiw-MuiPaper-root-MuiDrawer-paper {
  box-shadow: none !important;
}

.mousepointer {
  cursor: pointer;
}

.Phone_input {
  border: 0.8px solid rgb(167, 167, 167);
  border-radius: 4px;
  font-weight: 400;
  font-size: 1rem;
  padding: 12px;
  position: relative;
}

.PhoneInputInput {
  border: none !important;
  outline: none !important;
  font-weight: 400;
  font-size: 1rem;
  color: #000000;
}

.PhoneInputCountryIcon {
  border: none !important;
  outline: none !important;
}

.pr-08 {
  padding-left: 18px;
}

.css-ztrqiq-MuiImageListItemBar-root {
  border-radius: 8px;
  background: linear-gradient(0deg,
      rgba(0, 0, 0, 0.72) 0%,
      rgba(0, 0, 0, 0.47) 34.93%,
      rgba(0, 0, 0, 0) 100%);
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.tab-width {
  width: 33.33% !important;
}

.tab-mode-filters {
  display: none;
}

.indexer {
  z-index: 1000;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.cursorpointer {
  width: 333px !important;
}

/* @media (max-width: 870px) and (min-width: 800px) {
  .cursorpointer {
    width: 310px !important;
  }
} */

.cursorpointer__Four {
  width: 280px !important;
}

.padder {
  padding-left: 50px;
}

.exp-fs {
  font-size: 21px !important;
}

.share_drawerIcon {
  background-color: #ffff;
  padding: 0px;
  border-radius: 25px;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

li {
  line-height: 2;
}

.carousel-image-container {
  height: 55vh !important;
}

.object-fit-cover {
  object-fit: cover;
  height: 100%;
  width: 100%;
}

.navimgclasser {
  height: 40px !important;
  width: 15rem !important;
  border-radius: 12px;
}

.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.scroller2 {
  overflow-y: scroll;
}

.mobile-sqft-show {
  display: none !important;
}



.cards4 {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  row-gap: 20px;
}

@media (max-width: 1200px) {
  .cards4 {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr !important;
    row-gap: 20px;
  }

  .Text_Of_Modal {
    color: #ffffff;
    font-family: Montserrat;
    font-size: 16px;
    font-weight: 600;
    line-height: 32px;
  }

  .Button__OF__MODA {
    width: 12rem !important;
  }

  .grey__background {
    background-color: #f6f6f6;
    border-radius: 12px !important;
    line-height: 28px;
    font-family: Montserrat;
  }

  .navbar_drawer_content .css-4t3x6l-MuiPaper-root-MuiDrawer-paper {
    width: 100% !important;
  }

  .profile__Image {
    width: 5rem !important;
    height: 5rem !important;
    border-radius: 50% !important;
  }
}

@media (max-width: 910px) {
  .cards4 {
    display: grid;
    grid-template-columns: 1fr 1fr !important;
    row-gap: 20px;
  }
}

@media (max-width: 630px) {
  .cards4 {
    display: grid;
    grid-template-columns: 1fr !important;
    row-gap: 20px;
  }

  .dynamic_margin_date {
    margin-top: -5px !important;
  }

  .dynamicFont14 {
    font-size: 12px !important;
  }
}

.btndecor:hover {
  height: 25px;
  background-color: #ffffff;
  color: #02549e;
  font-weight: 700;
  /* font-size: 11px; */
  border-radius: 100px;
  text-wrap: nowrap;
}

.purplebtn:hover {
  background-color: #6c35a3;
  color: white;
  font-size: 12px;
}

.scrollLoader {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  flex-direction: column;
  top: 0;
  left: 0;
  width: 100%;
  height: 100% !important;
  background: #1818180a;
  z-index: 1300;
}

.dealdecor {
  padding: 2px 6px 2px 6px;
  background-color: #ca0024;
  color: #ffffff;
  font-weight: 700;
  font-size: 10px;
  border-radius: 100px;
  border: none;
  outline: none;
}

.btndecor {
  padding: 3px 6px 3px 6px;
  /* height: 63px; */
  background-color: #ffffff;
  color: #02549e;
  font-weight: 700;
  font-size: 10px;
  border-radius: 100px;
}

.btndecors {
  padding: 4px 4x 4px 4px;
  width: 50px;
  /* height: 63px; */
  background-color: #ffffff;
  color: #02549e;
  font-weight: 700;
  font-size: 13px;
  border-radius: 100px;
}

.btndecors:hover {
  background-color: #ffffff;
  color: #02549e;
}

.btndecorss {
  padding: 4px 4x 4px 4px;
  width: 50px;
  /* height: 63px; */
  background-color: #ffffff;
  color: #ff0000;
  font-weight: 700;
  font-size: 13px;
  border-radius: 100px;
}

@media (max-width: 786px) {
  .MobilE_____Margins {
    margin-top: 6rem !important;
  }

  .ChatCardsre,
  .Person__Name {
    display: none !important;
  }

  .mobile_con_screen {
    margin-top: 4rem !important;
  }

  .Conversation__Seting {
    overflow-x: hidden;
    padding-top: 3rem !important;
  }
}

.mv-more-search {
  display: none !important;
}

.m-v-p {
  padding-bottom: 3rem;
}

.d-t-s-m-v,
.mv-icon,
.m-v-item-to-show {
  display: none !important;
}

.m-v-pad-h {
  padding-top: 1rem !important;
  padding-bottom: 0.5rem !important;
}

.f-d-c {
  flex-direction: column !important;
}

.css-1lnwpba-MuiModal-root-MuiDrawer-root .MuiDrawer-paper {
  width: 550px;
}

.prop_hide {
  display: none !important;
}

.off_marketlogo {
  width: 100%;
  padding-top: 10px;
  padding-bottom: 10px;
}

.off_marketlogo img {
  width: 376px;
  height: 57px;
}

.noUi-horizontal {
  height: 11px !important;
}

.noUi-target {
  box-shadow: none !important;
  background-color: #f1cead !important;
  border-radius: 12px !important;
  border: 0px !important;
}

.noUi-connect {
  background-color: #f6941c !important;
  border: 0.5px solid white !important;
  border-radius: 12px !important;
}

.noUi-handle {
  background-color: #f6941c !important;
  border-radius: 50% !important;
  box-shadow: none !important;
  border: 2px solid white !important;
  width: 22px !important;
  height: 22px !important;
  right: -6px !important;
}

.noUi-handle:after {
  display: none !important;
}

.noUi-handle:before {
  display: none !important;
}

.noUi-touch-area {
  cursor: pointer;
}

a.mapboxgl-ctrl-logo {
  display: none !important;
}

.mapboxgl-ctrl-attrib.mapboxgl-compact {
  display: none !important;
}

.slick-prev.slick-disabled:before,
.slick-next.slick-disabled:before {
  opacity: 0.25 !important;
}

.custom-slicker-arrow.disabled {
  opacity: 0.3 !important;
  cursor: not-allowed !important;
}

.custom-slicker-arrow-next {
  position: absolute;
  top: -16%;
  right: 5%;
}

.custom-slicker-arrow-prev {
  position: absolute;
  top: -16%;
  right: 8%;
}

.custom-slicker-arrow {
  font-size: 40px !important;
  color: black !important;
  opacity: 1 !important;
  cursor: pointer !important;
}

.MapListing_count {
  background-color: rgb(201, 201, 201);
  font-family: Montserrat;
  height: 35px;
  border-radius: 4px;
  padding-left: 10px;
  margin: 0px 4px;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 5px;
}

.MapListing_count p {
  margin: 0 !important;
  color: black !important;
}

.MapListingScroll_cards {
  height: 303px;
  overflow-y: auto;
  width: 314px;
  overflow-x: hidden;
  padding: 3px;
}


.MapListingScroll_cards::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 4px rgba(129, 129, 129, 0.3);
  border-radius: 6px;
  background-color: #F5F5F5;
}

.MapListingScroll_cards::-webkit-scrollbar {
  width: 6px;
  background-color: #F5F5F5;
}

.MapListingScroll_cards::-webkit-scrollbar-thumb {
  border-radius: 6px;
  -webkit-box-shadow: inset 0 0 4px rgba(116, 116, 116, 0.3);
  background-color: #b6b6b6;
}

.save_searchOnmap {
  display: none;
}

@media (max-width: 1100px) {
  .map_hide_inMob {
    display: none !important;
  }

  .heading_logo {
    display: none;
  }

  .printicon_hide {
    display: none;
  }

  .mbl_heartIcon {
    font-size: 1.4rem !important;
  }

  .mbl_heartIconcontainer {
    padding: 6px !important;
  }

  .mbl_ShareIcon button {
    padding: 6px !important;
  }

  .mbl_ShareIcon svg {
    font-size: 1.7rem !important;
  }

  .mbl_share_positon {
    top: 0px !important;
    right: 10px !important;
  }

  .mbl_heart_positon {
    top: 0px !important;
    right: 55px !important;
  }
}

/* @media (max-width: 920px) {
  .maindataxx {
    height: auto !important;
  }
} */

/* .drawerwidth {
  width: 100% !important;
} */

.mob__drawerwidth {
  width: 100%;
}



@media (max-width: 720px) {
  .cardsmapping {
    display: grid;
    grid-template-columns: 1fr !important;
  }

  .cursorpointer {
    width: 333px !important;
  }
}

@media (max-width: 925px) {
  .bedd__Button {
    width: 2rem !important;
  }
}

@media (max-width: 768px) {

  .carousel-control-next-icon,
  .carousel-control-prev-icon {
    display: none !important;
    background-color: rgb(0, 0, 0);
    border-radius: 12px;
    margin-right: -10rem !important;
    z-index: 1;
  }
}

.DrawerCarousel .carousel-control-prev-icon {
  background-color: transparent;
  margin: 0px;
}

.width__80 {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80% !important;
}

.x_PROJECTNAME {
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  color: #02549e;
}



@media (max-width: 800px) {

  .offmarket,
  .greencategory,
  .m-v-footer,
  .m-v-carousel-hide,
  .mv-ic-hid,
  .m-v-item-for-hide,
  .Skeleton-item-for-hide,
  .fav__assignments,
  .sign__UP_NHIDER {
    display: none !important;
  }

  .CardBOX {
    background-color: #f5f5f5;
    color: #000000;
    height: 8rem !important;
    border-radius: 8px;
    width: 36rem !important;
    margin-right: 1rem !important;
  }

  .d-t-s-m-v,
  .mv-icon,
  .m-v-d-text,
  .m-v-drawerdesign,
  .m-v-item-to-show,
  .Grey__Border {
    display: block !important;
  }

  .css-1lnwpba-MuiModal-root-MuiDrawer-root .MuiDrawer-paper {
    border-top-left-radius: 0px !important;
    width: 100% !important;
  }

  .ID__E {
    height: 20px !important;
    font-size: 10px !important;
  }

  .ID__EG {
    height: 20px !important;
    font-size: 10px !important;
  }

  .custom-slicker-arrow {
    display: none;
  }

  .settinger {
    padding-left: 20px !important;
  }

  .tab-width {
    width: 100% !important;
  }

  .MOB__SCROLLER {
    width: 350px !important;
    height: 475px !important;
    overflow-x: scroll;
  }

  .MOB__Scroller {
    overflow-x: scroll;
  }

  .w-set {
    padding-left: 1.2rem !important;
  }

  .css-6t7t6l-MuiPaper-root-MuiAppBar-root {
    background-color: #ffffff !important;
  }

  .NMANL {
    color: #02549e;
    font-size: 25px;
    font-weight: 700;
  }

  .left__PADding {
    padding-left: 2rem !important;
  }

  .pa___Left {
    padding-left: 2rem !important;
  }

  .Tab__ICon {
    width: 7rem !important;
    padding-bottom: 1rem !important;
  }

  .fixed__Width {
    width: 12rem !important;
  }

  .weight_900 {
    font-weight: 900 !important;
  }

  .mail__Font {
    font-size: 2rem !important;
  }

  .cursorpointer {
    width: 333px !important;
    flex-shrink: 0;
  }

  .css-wf16b5 {
    width: 100% !important;
  }

  .css-14bbsej {
    width: 100% !important;
  }

  .cardpara {
    margin-right: 2rem !important;
  }

  .Toggle______Button {
    position: fixed;
    margin-top: -38rem !important;
  }

  .Toggle______Button2 {
    position: fixed;
    margin-top: -21rem !important;
  }

  .cardprice {
    font-weight: 600;
    font-size: 11px !important;
    color: #ca0024;
    margin-right: 4px;
    text-decoration: line-through;
  }

  .buttonbtn:focus {
    background-color: #0253a0;
    color: white;
  }

  .m-v__Buttons {
    background-color: #ffff;
    border: 1px solid #0253a0;
    color: #0253a0;
    border-radius: 50px !important;
    padding: 7px 12px !important;
    font-size: 14px;
    height: fit-content;
    font-weight: 700;
  }

  .m-vfilterBtn {
    background-color: #0253a0;
    color: white;
    border-radius: 10px;
    padding: 3px 37px;
    font-weight: bold;
    font-size: 16px;
    outline: none;
    border: none;
  }

  .m_vsortbtn {
    background-color: #0253a0;
    color: white;
    border: 1px solid #0253a0;
    background-color: white;
    color: #0253a0;
    font-weight: 600;
    font-weight: bold;
    padding: 7px 42px;
  }

  .btn__Prop {
    width: 5rem !important;
    border-radius: 14px;
    /* position:static; */
    z-index: 1000;
  }

  .mob__ERR_Width {
    width: 18rem !important;
  }

  .m-v-Property__Heading {
    font-size: 2.3rem !important;
    font-weight: 800;
    color: #0253a0;
  }

  .drawer__API__Image {
    width: 90% !important;
  }

  .pl-2 {
    margin-left: 0.5rem !important;
  }

  .Mobile__Hide {
    display: none;
  }

  .navbar-toggler-icon {
    background-image: url("/src/images/toggle_logo.png") !important;
  }

  .Menu__Icon {
    font-weight: 900 !important;
    font-size: 1.7rem !important;
  }

  .bullet {
    font-size: 20px;
  }

  .pl-1 {
    padding-right: 0.3rem !important;
  }

  .mob__heading {
    font-size: 20px !important;
    color: #f78300;
    font-weight: 700 !important;
  }

  .MuiTabs-root .Mui-selected {
    /* background-color: #02549E; */
    background-color: black !important;
    color: white !important;
    font-family: Montserrat;
    border-radius: 20px !important;
  }

  .MuiTabs-root .MuiButtonBase-root {
    margin-right: 12px !important;
    margin-bottom: 2rem !important;
    border-radius: 20px !important;
  }

  .css-11yukd5-MuiTabs-indicator {
    position: absolute;
    height: 2px;
    bottom: 0;
    width: 100%;
    -webkit-transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    background-color: #ffffff !important;
  }

  .toggle__image {
    height: 1rem !important;
    width: 2rem !important;
  }

  .css-4t3x6l-MuiPaper-root-MuiDrawer-paper {
    width: 100% !important;
    /* height: 100% !important; */
  }

  .pr-img {
    border-radius: 50%;
    border: 3px solid #02549e !important;
    width: 1.5rem !important;
    height: 1.5rem !important;
  }

  .down-icon {
    margin: 0px !important;
  }

  .top__d {
    margin-top: 0.5rem !important;
    margin-right: 0.4rem !important;
  }

  .profile__Image {
    width: 5rem !important;
    height: 5rem !important;
    border-radius: 50% !important;
  }

  .top_d {
    /* margin-top: 0.5rem !important; */
    margin-right: 0.4rem !important;
  }

  .pl-5 {
    padding-right: 0rem !important;
  }

  .name__Heading {
    color: #02549e !important;
    font-size: 18px !important;
    font-weight: 700 !important;
  }

  .forward__arrow {
    font-size: 1.1rem !important;
  }

  .m-v__heart {
    width: 37px;
    height: 34px;
    margin-top: -8em;
    z-index: 1;
    background-color: white;
    border-radius: 50%;
    padding: 4px;
    box-shadow: 5px 5px 20px 5px #535353c0;
  }

  .m-v__hearts {
    width: 37px;
    height: 34px;
    margin-top: -9rem;
    z-index: 1;
    background-color: white;
    border-radius: 50%;
    padding: 4px;
    box-shadow: 5px 5px 20px 5px #535353c0;
  }

  .details__Text {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .pl__i {
    padding-right: 3rem !important;
  }

  .pl__2 {
    padding-left: 2rem !important;
  }

  .child2xs {
    padding-top: 8px;
    padding-bottom: 8px;
    height: 40px !important;
    border: 1px solid #e2e2e2 !important;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    border-top-right-radius: 0px;
  }

  .dated {
    font-size: 14px !important;
  }

  .m-v-table-head {
    font-size: 14px !important;
  }

  .m-v-table-head2 {
    width: 8rem !important;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    font-size: 14px !important;
  }

  .m-v-table-head23 {
    width: 8rem !important;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    word-break: break-all;
    font-size: 14px !important;
  }

  .css-heg063-MuiTabs-flexContainer {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin-left: 1rem !important;
  }

  .coloring {
    background-color: #f5f5f5 !important;
  }

  .css-19kzrtu {
    background-color: #f5f5f5 !important;
    border-radius: 20px !important;
  }

  .left__P {
    padding-left: 1rem !important;
  }

  .pr__2 {
    padding-right: 0.3rem !important;
  }

  .Detailsbutton {
    background-color: #02549e;
    color: white;
    border: 0px;
    height: 30px !important;
    width: 6rem !important;
    padding: 4px 10px 4px 10px;
    border-radius: 4px;
    font-weight: 600;
    font-size: 10px !important;
    font-family: "Montserrat";
  }

  .detButtonCursor {
    cursor: default !important;
  }

  .Borderbutton {
    background-color: #ffffff;
    color: rgb(0, 0, 0);
    border: 3px solid #cccccc;
    height: 31px !important;
    width: 8rem !important;
    padding: 4px;
    border-radius: 8px;
    font-weight: 600;
    font-size: 10px !important;
    font-family: "Montserrat";
  }

  .m-v-blue-color {
    color: #00469d !important;
  }

  .m-v-heading {
    font-size: 16px !important;
  }

  .m-v-pt-1 {
    padding-top: 1rem !important;
  }

  .m-v-icon-class {
    font-size: 15px !important;
  }

  .cardonpic {
    top: 10px !important;
  }

  .m-v-bold {
    font-weight: bolder !important;
  }

  .grey {
    font-size: 12px !important;
  }

  .turningred {
    font-size: 13px !important;
  }

  .m-v-drawerimages {
    display: flex;
    padding-top: 1rem !important;
    align-items: center;
    justify-content: center;
    width: 80%;
    overflow-x: scroll;
  }

  .DrawerCarousel .carousel-control-prev-icon {
    /* background-color: black !important; */
    margin: 0px;
    max-width: 15rem !important;
    object-fit: contain;
  }

  .detailsc {
    width: 99% !important;
  }

  .mv-i-clas {
    font-size: 1.8rem !important;
  }

  .DrawerBox {
    width: 100% !important;
  }

  .exp-fs {
    font-size: 17px !important;
    padding-left: 10px;
    font-weight: 900 !important;
  }

  .mv-i-clas2 {
    color: #02549e !important;
    font-weight: 900;
    font-size: 1.3rem !important;
  }

  .section-width {
    width: 100% !important;
  }

  .po-3 {
    padding: 1rem !important;
  }

  .m-v-d-text {
    color: #000000 !important;
    background-color: #f5f5f5;
    font-weight: 900;
    height: 50px !important;
    padding: 0.3rem !important;
  }

  .m-v-p {
    padding-bottom: 0rem !important;
  }

  .navbar-toggler {
    color: #02549e !important;
    border: 0px !important;
    padding: 0.5rem 0rem 0rem 0rem !important;
    display: none !important;
  }

  .SearchBox {
    top: 37px !important;
    width: 110% !important;
  }

  .navimgclasser {
    height: 40px !important;
    width: 15rem !important;
    border-radius: 12px;
  }

  .navimgclass {
    height: 40px !important;
    width: 40px !important;
    border-radius: 12px;
  }

  .headsettings {
    font-size: 9px !important;
    padding-top: 1rem !important;
    font-weight: 900 !important;
    color: #f79826 !important;
  }

  .blueColor {
    color: #02549e !important;
  }

  .m-v-c0n {
    display: flex !important;
    justify-content: space-between !important;
  }

  .mv-ic {
    font-size: 20px !important;
  }

  .p-imp,
  .mv-more-search {
    padding: 0rem 1.5rem 0rem 1.5rem !important;
  }

  .m-v-pad-h {
    padding-bottom: 0px !important;
    padding-top: 0.5rem !important;
  }

  .hs-bor1 {
    border-top-left-radius: 12px;
  }

  .hs-3,
  .hs-mc {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }

  .mv-more-search {
    display: block !important;
  }

  .ca-2,
  .ca-3 {
    font-size: 16px !important;
  }

  .ca-4 {
    font-size: 15px !important;
  }

  .ca-5 {
    font-family: "Inter";
    background-color: #4361ee;
    font-size: 12px !important;
    font-weight: 600;
    color: #ffffff;
    width: 80px;
    height: 30px;
    border-radius: 5px;
  }

  .ca-1 {
    width: 10rem !important;
  }

  .assignments,
  .m-v-map-hide,
  .sqft-hide,
  .buttons-hide,
  .m-v-s-h,
  .mv-si,
  .slider-hide,
  .m-v-img-to-hide,
  .low-pr-m-v {
    display: none !important;
  }

  .head-padding,
  .hs-d {
    padding: 0rem 2rem 0rem 2rem !important;
  }

  /* .cardsmapping {
    padding: 0rem 1rem 0rem 0rem !important;
  } */

  .searchbor {
    padding-top: 0.5rem !important;
    padding-bottom: 0rem !important;
    border-right: 0px !important;
  }

  .hs-ud {
    border: 0px !important;
  }

  .hs-bor1 {
    margin-bottom: 1rem !important;
  }


  .buttons {
    margin-right: 0px !important;
  }

  /* .cardsmapping {
    display: grid;
    grid-template-columns: 1fr !important;
  } */
  .m-v-c-w {
    width: 100% !important;
  }

  .maindataxx {
    padding-left: 0.8rem !important;
    height: 185px !important;
  }

  .SearchInput::placeholder {
    font-size: 14px;
    font-weight: 500;
    text-overflow: hidden;
    color: black;
  }

  .carousel-image-container {
    height: 36vh !important;
  }

  .hs-bor1 {
    border: 2px solid #cccccc !important;
  }

  .hs-mc {
    background-color: #ffffff;
    color: #02549e;
    border: 1px solid #02549e;
    font-family: "Montserrat";
    font-weight: 500;
    height: 2rem !important;
    font-size: 14px;
  }

  .map_hide_inMob {
    display: none !important;
  }

  .tab__Hidden {
    display: none !important;
  }

  .prop_hide {
    display: block !important;
  }
}

@media (max-width: 768px) {
  .box_1 {
    background-color: #02549e !important;
  }

  .whitener {
    color: #ffffff !important;
  }
}

@media (max-width: 430px) {
  .cursorpointer {
    width: 320px !important;
  }

  .mobileFont17 {
    font-size: 14px;
    font-family: Montserrat;
    font-weight: bold;
  }

  .mobileFont_16 {
    font-size: 13px;
  }

  .settinger {
    padding-left: 0 !important;
    padding-right: 0 !important;
    width: 90%;
  }

  .hs-2 {
    color: white;
  }

  .p-imp {
    padding: 0rem 0.8rem !important;
  }

  .mobileviewDisply {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .mobile_margin_Top4 {
    margin: 0 !important;
  }

  .mobile_margin_Top3 {
    margin: 0 !important;
  }

  .mobile_padding_Top0 {
    padding: 0 !important;
  }

  /* .mobile_Search_bg {
    background-color: whitesmoke;
  } */

  .head-padding {
    padding-top: 1.5rem !important;
  }

  /* .mobile_header_padTop {
    padding-top: 1.5rem !important;
  }

  .mobile_header_padBottom {
    /* padding-bottom: 1rem !important; */

  .mv-more-search {
    padding: 0 1rem !important;
  }

  .hs-3 {
    padding-left: 3rem !important;
    padding-right: 3rem !important;
    padding-top: 10px !important;
    padding-bottom: 30px !important;
  }

  .hs-bor1 {
    margin-bottom: 1rem !important;
    background-color: white !important;
  }

  .mv-i-clas {
    color: lightslategrey;
    width: 1.6rem !important;
    height: 1.6rem !important;
  }

  .css-3iu1z9 {
    font-size: 20px !important;
  }

  .SearchInput {
    padding-left: 0.5rem !important;
  }

  .head-padding {
    padding: 1.5rem 1.5rem 0 1.5rem !important;
  }

  .hs-d {
    padding: 0 1.5rem !important;
  }

  .fpass {
    font-size: 12px;
  }

  .remember_me {
    font-size: 14px;
  }
}

.mobile_margin_Top3 {
  margin-top: 1rem;
}

.mapboxgl-popup-content {
  padding: 5px 3px !important;
  height: auto !important;
}

@media (max-width: 1300px) {
  .mobile_margin_Top4 {
    margin-top: 1.5rem;
  }

  .fbton {
    background-color: #ffffff;
    color: #02549e;
    margin-right: 0px !important;
    width: 15rem !important;
    height: 40px;
    font-weight: 700;
    font-size: 16px;
  }
}

@media only screen and (min-width: 720px) and (max-width: 768px) {
  .cardsmapping {
    display: grid !important;
    grid-template-columns: 1fr 1fr !important;
  }

  .cursorpointer {
    width: 333px !important;
  }
}

@media only screen and (min-width: 800px) and (max-width: 1100px) {
  .cardsmapping {
    /* display: grid !important; */
    grid-template-columns: 1fr !important;
  }
}

/* Table Mode */
@media only screen and (min-width: 800px) and (max-width: 1200px) {

  .navbar-toggler,
  .m-v-img-to-hide,
  #navbarSupportedContent {
    display: none !important;
  }

  .item_show_at_12,
  .m-v-con,
  .d-t-s-m-v,
  .mv-icon {
    display: block !important;
  }

  .mv-i-clas {
    width: 2.5rem !important;
    height: 2.5rem !important;
  }

  .Menu__Icon {
    font-weight: 900 !important;
    font-size: 2.5rem !important;
  }

}

@media only screen and (min-width: 800px) and (max-width: 1100px) {

  .m-v-item-for-hide,
  .t-vh-s,
  .top__d,
  .top__D,
  .navbar-toggler,
  .m-v-img-to-hide,
  .m-v-carousel-hide,
  #navbarSupportedContent {
    display: none !important;
  }

  .drawerwidth {
    width: 100% !important;
  }

  .css-14bbsej {
    width: 100% !important;
  }

  .paddingMax {
    padding: 5rem !important;
  }

  .m-v-item-to-show,
  .m-v-con,
  .d-t-s-m-v,
  .mv-icon,
  .m-v-d-text,
  .tab-mode-filters,
  .fav__assignments {
    display: block !important;
  }

  .mv-i-clas2 {
    color: #02549e !important;
    font-weight: 900;
    font-size: 2rem !important;
  }

  .car0__Image {
    height: 20rem !important;
    max-width: 25rem !important;
    object-fit: cover;
  }

  .carousel-control-prev-icon {
    /* background-color: black !important; */
    /* margin-left: -10rem !important; */
    margin-top: 0rem !important;
  }

  .carousel-image-container {
    height: 36vh !important;
  }

  .m-v-d-text {
    color: #000000 !important;
    background-color: #f5f5f5;
    font-weight: 900;
    height: 50px !important;
    padding: 0.3rem !important;
  }

  .details__Text {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .tab__Hidden {
    display: none !important;
  }

  .top__Search {
    padding-top: 2rem !important;
  }

  .tab-mode-filters {
    border: 1px solid #cccccc;
  }

  .tab-width {
    width: 87% !important;
  }

  .searchbor {
    border-right: 0px solid #cccccc !important;
  }

  .mob__Borders {
    /* border: 1px solid #cccccc; */
    border-radius: 8px;
  }

  .mob__Padding {
    padding: 0.8rem !important;
  }

  .navimgclass {
    height: 82px !important;
    width: 100% !important;
  }

  .Cross__Top {
    font-size: 20px !important;
    margin-top: 2rem !important;
  }

  .profile__Image {
    width: 5rem !important;
    height: 5rem !important;
    border-radius: 50% !important;
  }

  .css-wf16b5 {
    width: 100% !important;
  }


  /* for drawer width */

  /* .css-1ab2xsx {
    width: 100% !important;
  } */

  .css-4t3x6l-MuiPaper-root-MuiDrawer-paper {
    width: 100% !important;
  }

  .hs-bor1 {
    border: 1px solid #cccccc;
    border-bottom-left-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
  }

  .left__0 {
    padding-left: 0.5rem !important;
  }

  .tab-search-width {
    width: 10% !important;
  }

  /* .cardsmapping {
    display: grid;
    grid-template-columns: 1fr 1fr !important;
  } */
  /* 4 Potrait Cards
   */
  .blueColor {
    color: #02549e !important;
  }

  .lprice {
    font-weight: bolder;
    font-size: 16px;
    display: flex;
    flex-direction: row;
  }

  .headsettings {
    font-size: 16px !important;
    padding-top: 2rem !important;
    font-weight: 900 !important;
    color: #f79826 !important;
  }

  .Menu__Icon {
    font-weight: 900 !important;
    font-size: 2.5rem !important;
  }

  .mv-i-clas {
    width: 2.5rem !important;
    height: 2.5rem !important;
  }

  .results {
    font-weight: 500;
    font-size: 12px;
    margin-left: 8px;
    margin-top: 3px;
  }
}

.icon__PAdfing {
  padding-inline: 1.5rem;
}

.innerCardPadding {
  padding-right: 0.5rem !important;
  padding-left: 1.2rem !important;
}

/* Tablet Responsivenes */
@media (max-width: 1350px) {
  .icon__PAdfing {
    padding-inline: 0.5rem !important;
  }

  .mail__USRE {
    font-size: 12px !important;
  }

  .chat__User {
    font-size: 16px !important;
  }
}

@media (max-width: 1150px) {
  .Prop____Image {
    width: 100px !important;
    height: 75px !important;
    border-radius: 12px;
    object-fit: fill;
  }
}

@media (max-width: 1000px) {
  .Messages_Ser {
    margin-left: 0rem !important;
    padding-right: 0px !important;
  }

  .mail__USRE {
    font-size: 10px !important;
  }

  .chat__User {
    font-size: 12px !important;
  }

  .MessagesFonts {
    font-size: 25px !important;
  }

  .text__VE {
    font-family: Montserrat;
    font-size: 8px;
    font-weight: 500;
    line-height: 17px;
    letter-spacing: 0em;
    text-align: left;
    color: #676767;
  }

  .Dash__Button {
    font-size: 10px !important;
  }

  .del__TEXST {
    width: 5rem !important;
  }
}

@media (max-width: 860px) {
  .grey_____BAck {
    display: none !important;
  }

  .Admin_portal_heading {
    font-size: 18px;
  }
}

@media (max-width: 800px) {

  .Del__Width,
  .main__Kedat {
    display: none !important;
  }

  .mob__ACTRE {
    padding-top: 1rem !important;
  }

  .SendFlyer {
    font-size: 14px !important;
  }

  .TimeAgoText {
    font-size: 10px !important;
    font-weight: 500;
  }



  .DeL__Text {
    font-size: 12px !important;
    font-weight: 600;
  }

  .dollar__Price {
    font-size: 16px !important;
  }

  .del__TEXST {
    width: 4rem !important;
  }

  .norm__Text2 {
    font-size: 12px !important;
  }

  .Prop____Image {
    width: 75px !important;
    height: 75px !important;
    object-fit: fill;
  }
}

.w-90 {
  width: 90% !important;
}

.backArrowAPP {
  color: #000000 !important;
}

.end_round {
  border-bottom-left-radius: 0px;
  border-top-left-radius: 0px;
  border-bottom-right-radius: 8px;
  border-top-right-radius: 8px;
  border-left: none !important;
  height: 45px !important;
  width: 6rem !important;
}

.end_round2 {
  border-bottom-left-radius: 8px;
  border-top-left-radius: 8px;
  border-bottom-right-radius: 0px;
  border-top-right-radius: 0px;
  height: 45px !important;
}

@media (max-width: 1400px) {
  .norm__Text2 {
    font-size: 12px;
  }

  .dollar__Price {
    font-size: 16px;
  }

  .dollar__Price__2,
  .ID__E {
    font-size: 13px;
  }

  .ID__EG {
    font-size: 13px;
  }
}

.Text__OF__MOda {
  font-weight: 500;
  color: #FFFFFF;
  font-size: 16px;
}