.SearchInput {
  padding-left: 0.5rem;
  border: none;
  width: 100%;
}

.SearchInput:focus {
  outline: none;
}

.SearchBox {
  position: absolute;
  top: 50px;
  z-index: 300;
  height: 287px !important;
  overflow-y: auto;
}


.SearchBox::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 4px rgba(129, 129, 129, 0.3);
  border-radius: 6px;
  background-color: #F5F5F5;
}

.SearchBox::-webkit-scrollbar {
  width: 6px;
  background-color: #F5F5F5;
}

.SearchBox::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 4px rgba(116, 116, 116, 0.219);
  background-color: #b6b6b6;
}

.recentSearch {
  border-bottom: 1px solid rgb(194, 194, 194);
  font-size: .9rem;
  color: grey;
}

.searchSuggestion:hover {
  cursor: pointer;
  background-color: rgb(246, 246, 246);
}

.active__search {
  cursor: pointer;
  background-color: rgb(246, 246, 246);
}

.search__heading {
  font-size: 13px;
  font-weight: 400;
  line-height: 15px;
}

.search__description {
  font-size: 10px;
  font-weight: 400;
  opacity: .7;
}

.map-container {
  height: 80vh;
  width: 100% !important;
}

.mortgage__input {
  width: 100%;
  /* height: 3.5rem; */
  border-radius: 0.25rem;
  border: 1px solid #D9D9D9;
  margin-bottom: 15px;
  padding: 10px;
  color: var(--primary, #0253A0);
  font-family: Montserrat;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.mortgage__input:focus {
  outline: none;
  border: 2px solid #1887f0;
}

.amorization__years {
  font-size: 1rem !important;
}

.detButtonCursor {
  cursor: default !important;
}


.SearchBox h5 {
  font-family: Montserrat;
  font-size: 16px !important;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  padding: .5rem .5rem 0 .5rem !important;
  margin-bottom: 3px;
}


.searchBox_searchingIcon {
  background-color: #02549e;
  border-radius: 50%;
  padding: 0px 3px 3px 3px;
}


.searchBox_searchingIcon svg {
  color: #fff !important;
  font-size: 20px !important;
}