.FeaturedListings__Headings {
    color: var(--primary, #0253A0);
    font-family: Montserrat;
    font-size: 2rem;
    font-weight: 700;
    line-height: normal;
}

.notifyFunnels_container {
    background: #C5D8E9;
    padding-top: 3.5rem;
    padding-bottom: 10rem;
    font-family: Montserrat;
}

.ourMission_container {
    position: relative;
    color: #121212;
    font-family: Montserrat;
    top: -5rem;
    z-index: 1;
    left: 0;
    width: 100%;
    padding: 40px;
    border-radius: 20px;
    border: 1px solid #D9D9D9;
    background: #98B9D8;
    box-shadow: 0px 16px 32px 0px rgba(14, 10, 36, 0.08);
}

.ourMission_container h5 {
    font-size: 2rem;
    font-weight: 700;
    line-height: 120%;
    color: #0253A0;
}

.ourMission_container p,
.ourMission_container ul li {
    font-size: 1.2rem;
    font-weight: 400;
    line-height: 150%;
}

.ourMission_container ul {
    list-style: disc;
    padding-left: 1.5rem;
    margin-bottom: 0px;
}


.ourMission_Img {
    height: 100%;
    border-radius: 30px;
    border: 6px solid #FFF;
    background-color: #000;
}

.ourMission_Img img {
    border-radius: 24px;
    object-fit: cover;
    opacity: .5;
}

.ourMission_play {
    background-color: #3B79B5;
    border: 0.4rem solid #FFF;
    border-radius: 100%;
    padding: 1.8rem;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    cursor: pointer;
}

.ourMission_play:hover {
    padding: 2rem;
}

.insight_and_trends {
    font-family: Montserrat;
}

.insight_and_trends h2 {
    font-size: 2rem;
    font-weight: 700;
    line-height: 120%;
    color: #0253A0;
}

.assignHome_contianer {
    background-color: #C5D8E9;
    font-family: Montserrat;
}

.assignHome_contianer h3 {
    color: #0253A0;
    text-align: center;
    font-size: 4rem;
    font-weight: 700;
    line-height: 120%;
}


.assignHome_contianer .subtitle {
    color: var(--Text-primary, #000);
    text-align: center;
    max-width: 800px;
    font-size: 1.2rem;
    line-height: 150%;
}


.assignHome_landCard {
    border-radius: 20px;
    background: var(--Color-Neutral-white, #FFF);
    box-shadow: 0px 16px 32px 0px rgba(0, 0, 0, 0.08);
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
}

.assignHome_landCardImg {
    width: 37%;
    height: 100%;
}

.assignHome_landCard img {
    border-radius: 20px 0px 0px 20px;
    object-fit: cover;
}


.assignHome_landCardContent {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: .6rem;
    width: 63%;
    padding: 1.3rem .8rem;
}

.assignHome_landCardContent .badge {
    border-radius: 50px;
    background: #0253A0;
    display: flex;
    padding: 4px 12px;
    justify-content: center;
    color: #FFFF;
    color: #FFF;
    font-size: 13px;
    font-weight: 600;
    line-height: 140%;
    width: fit-content;
}

.assignHome_landCardContent h5 {
    color: #0253A0;
    font-size: 2rem;
    font-weight: 700;
    line-height: 140%;
    margin: 0px;
}

.assignHome_landCardContent h6 {
    color: #0253A0;
    font-size: 1rem;
    font-weight: 600;
    line-height: 150%;
    margin: 0px;
}

.assignHome_landCardContent p {
    color: var(--valery-ca-dolphin, #676473);
    font-size: 1rem;
    font-weight: 400;
    line-height: 150%;
    margin: 0px;
}

.assignHome_PortCardImg {
    width: 100%;
    height: 350px;

}

.assignHome_PortCardImg img {
    border-radius: 20px 20px 0px 0px;
    object-fit: cover;
}

.assignHome_PortCardContent {
    width: 100%;
    display: flex;
    padding: 3rem 3.5rem;
    flex-direction: column;
    gap: .7rem;
}

.home_blogs {
    font-family: Montserrat;
}

.home_blogs h2 {
    color: #0253A0;
    font-size: 2rem;
    font-weight: 700;
    line-height: normal;
}

.home_blogs p {
    color: var(--Accent-Black, #282828);
    font-family: "Open Sans";
    font-size: 1rem;
    font-weight: 400;
    line-height: 150%;
    letter-spacing: 0.48px;
}


.home_articleCard img {
    width: 100%;
    border-radius: 20px;
    object-fit: cover;
}

.home_articleCard .postDate {
    color: var(--Primary-Natural-blue, #007BFF);
    font-size: 13px;
    font-weight: 400;
    line-height: 140%;
    margin: 10px 0px;
}

.home_articleCard .title {
    color: #101828;
    font-size: 20px;
    font-weight: 700;
    line-height: 120%;
    cursor: pointer;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    overflow: hidden;
    text-overflow: ellipsis;
}

.home_articleCard .title:hover {
    text-decoration: underline;
}

.home_articleCard .description {
    color: var(--Accent-Black, #282828);
    font-size: 16px;
    font-weight: 400;
    line-height: 150%;
    letter-spacing: 0.48px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    line-clamp: 3;
    overflow: hidden;
    text-overflow: ellipsis;
}

.home_articleMainCard .description {
    -webkit-line-clamp: 2;
    line-clamp: 2;
}


.home_articleCard .chip {
    border-radius: 16px;
    margin-bottom: 0px;
    padding: 2px 10px;
    background-color: #F9F5FF;
    color: #007BFF;
}

.home_articleCard .read_chip {
    background-color: #EEF4FF;
    color: #0253A0;
}

.home_articleCard .category_chip {
    color: #C11574;
    background-color: #FDF2FA;
}

.notifyFunnels_btn {
    width: 108px;
    height: 42px;
    background: var(--primary, #0253A0) !important;
}

.notifyFunnels_details {
    display: flex;
    flex-direction: column;
    gap: 1.4rem;
}


.notifyFunnels_details h4 {
    color: #0253A0;
    font-size: 3rem;
    font-weight: 600;
    line-height: 130%;
    letter-spacing: -1.44px;
    margin: 0px;
}

.notifyFunnels_details p {
    color: #676767;
    font-size: 1rem;
    font-weight: 400;
    line-height: 150%;
    margin: 0px;
}


.notifyFunnels_details ul {
    display: flex;
    flex-wrap: wrap;
    padding-left: 10px;
    row-gap: 1.5rem;
    margin-bottom: 0px;
}

.notifyFunnels_details ul li::marker {
    color: #6899C6;
    font-size: 1rem;
    content: "\25CF";
}


.notifyFunnels_details ul li {
    color: #676767;
    font-size: 1rem;
    font-weight: 500;
    width: 50%;
    line-height: 150%;
}

.notifyFunnels_details ul li a:hover {
   text-decoration: underline;
}


.marketing_graph {
    width: calc(50% - 8px);
}

.marketing_customer {
    border-radius: 20px;
    background: #FFF;
    backdrop-filter: blur(42px);
    padding: 0.5rem 1.5rem 2.5rem 1.5rem;
    width: calc(50% - 8px);
}


.marketing_customer h5 {
    color: #010205;
    font-size: 6rem;
    font-weight: 600;
    line-height: 150%;
    letter-spacing: -2.52px;
    margin: 0px;
}

.marketing_customer p {
    color: #5C5D5F;
    font-size: 1rem;
    font-weight: 500;
    line-height: 150%;
    /* 24px */
}

.marketing_customer .progress-bar {
    width: 100%;
    height: 10px;
    background-color: #d3d3d3;
    position: relative;
    overflow: hidden;
    border-radius: 10px;
}

.marketing_customer .progress-filled {
    width: 60%;
    height: 100%;
    background-color: black;
}


.assignmentSale_intro {
    border-radius: 20px;
    border: 1px solid #D9D9D9;
    background: #FFF;
    box-shadow: 0px 16px 32px 0px rgba(14, 10, 36, 0.08);
    padding: 2.5rem;
    font-family: Montserrat;

}

.assignmentSale_intro h3 {
    color: #0253A0;
    font-size: 2rem;
    font-weight: 700;
    line-height: 120%;
    margin-bottom: 1.3rem;
}

.assignmentSale_intro p {
    color: var(--Text-primary, #000);
    font-size: 1.2rem;
    font-weight: 400;
    line-height: 150%;
    margin-bottom: 1rem;
}


.assignmentSale_intro ul {
    list-style-type: disc;
    padding-left: 2rem;
    row-gap: 1.5rem;
}


.assignmentSale_intro ul li {
    color: var(--Text-primary, #000);
    font-size: 1rem;
    font-weight: 400;
    line-height: 150%;
}


.searchingSuggestion_container {
    border-radius: 20px;
    background: #E2F1FF;
    padding: 1.5rem 1.5rem 0.3rem 1.5rem;
}

.searchingSuggestion_container h2 {
    color: var(--Accent-Black, #282828);
    font-family: "Open Sans";
    font-size: 1.6rem;
    font-weight: 700;
    line-height: 120%;
}



.searchingSuggestion_container h6 {
    color: var(--Accent-Dark-Gray, #4A4A4A);
    font-family: "Open Sans";
    font-size: 1rem;
    font-weight: 400;
    line-height: 150%;
    letter-spacing: 0.48px;
}


.insighAndTrends_chart .recharts-cartesian-grid-vertical {
    display: block !important;
}

.insighAndTrends_chart .recharts-cartesian-grid-vertical line:nth-of-type(1) {
    display: none !important;
}


.notifyFunnels_container .trafficImg {
    margin-top: 1.5rem;
    height: 245px;
}


.notifyFunnels_container .trafficImg img {
    border-radius: 20px;
}

.assignmentSale_intro .avatar img {
    border-radius: 70px;
    border: 2px solid rgba(255, 255, 255, 0.50);
    width: 70px;
    height: 70px;
    object-fit: cover;
}



.assignmentSale_intro .SubscriberBgImg {
    min-height: 400px;
}

.assignmentSale_intro h4,
.assignmentSale_intro h5 {
    color: #FFF;
    font-size: 5rem;
    font-weight: 700;
    line-height: 150%;
    /* 126px */
    letter-spacing: -1.52px;
    margin-bottom: 0px;
}

.assignmentSale_intro span {
    color: #0253A0;
}

.assignmentSale_intro h5 {
    font-size: 4.5rem;
}

.assignmentSale_intro .project_completed {
    color: #FFF;
    font-size: 1.3rem;
    font-weight: 500;
    line-height: 170%;
    /* 32.3px */
}


.funnels_modals {
    min-width: 450px !important;
}

.funnels_modals h6 {
    font-family: Montserrat !important;
    color: var(--Gray-900, #101828);
    font-weight: 600;
}

.funnels_modals p {
    font-family: Montserrat !important;
    font-weight: 400 !important;
    color: var(--Accent-Gray, #676767);
}

.funnels_modals span {
    font-family: Montserrat !important;
}

.funnels_modals button {
    background: var(--primary, #0253A0) !important;
    width: 100%;
}

.listings_faqs .Property_details_faq_question {
    font-size: 16px !important;
}

.listings_faqs .Property_details_faq_answer {
    font-size: 14px !important;
}

.listings_faqs .Property_plusIcon {
    font-size: 20px !important;
}

.listings_faqs .Property_plusIcon:hover {
    font-size: 24px !important;
}

.listings_faqs .Property_minusIcon {
    font-size: 24px !important;
}

@media(max-width: 768px) {
    .FeaturedListings__Headings {
        font-size: 1rem;
    }

    .notifyFunnels_details h4 {
        font-size: 2rem;
    }

    .notifyFunnels_details p,
    .ourMission_container p,
    .ourMission_container ul li,
    .assignHome_contianer .subtitle,
    .assignHome_landCardContent h6,
    .assignHome_landCardContent p,
    .assignmentSale_intro p,
    .assignmentSale_intro ul li,
    .home_blogs p,
    .searchingSuggestion_container h6,
    .home_articleCard .description,
    .marketing_customer p {
        font-size: 0.8rem;
    }

    .notifyFunnels_details ul li {
        font-size: 0.8rem;
        width: 100%;
    }

    .notifyFunnels_details .MuiFormControl-root {
        width: 100% !important;
    }

    .notifyFunnels_btn {
        width: 100% !important;
        margin-top: 12px;
    }

    .ourMission_container {
        padding: 20px;
    }

    .ourMission_Img img {
        min-height: 382px;
    }


    .Prop_details_simpleChart_container {
        padding-bottom: 12px !important;
    }

    .assignHome_contianer {
        margin-top: 1.5rem;
    }

    .assignHome_contianer h3 {
        font-size: 2rem !important;
    }



    .assignHome_PortCardImg {
        height: 230px !important;
    }

    .assignHome_PortCardContent {
        padding: 1.5rem;
    }

    .assignHome_landCardContent h5 {
        font-size: 1.5rem;
    }



    .assignHome_landCard {
        flex-direction: column;
    }

    .assignHome_landCardImg {
        width: 100%;
        height: 230px !important;
    }

    .assignHome_landCard img {
        border-radius: 20px 20px 0px 0px;
    }

    .assignHome_landCardContent {
        width: 100% !important;
        padding: 1.5rem !important;
    }

    .assignHome_PortCard {
        margin-bottom: 25px;
    }

    .assignmentSale_intro {
        padding: 1.5rem;
    }

    .assignmentSale_intro h3 {
        font-size: 1.8rem !important;
    }

    .home_blogs h2 {
        margin-top: 1rem;
    }

    .home_blogs {
        margin-bottom: 1.5rem;
    }

    .searchingSuggestion_container {
        padding: 1.25rem 1.25rem .3rem 1.25rem;
    }

    .home_articleCard .title {
        font-size: 1.15rem;
    }

    .marketing_customer {
        padding: 0.5rem .8rem 1.8rem .8rem;
    }

    .marketing_customer h5 {
        font-size: 4rem;
    }

    .notifyFunnels_container .trafficImg {
        height: 180px
    }

    .notifyFunnels_container {
        padding-bottom: 8rem;
    }

    .notifyFunnels_graph {
        margin-top: 1.5rem;
    }

    .assignmentSale_intro h4,
    .assignmentSale_intro h5 {
        color: #FFF;
        font-size: 5rem;
        font-weight: 700;
        line-height: 150%;
        /* 126px */
        letter-spacing: -1.52px;
        margin-bottom: 0px;
    }

    .assignmentSale_intro .avatar img:nth-child(1) {
        display: none;
    }

    .assignmentSale_intro .avatar {
        bottom: 3% !important;
        gap: 7px !important;
    }

    .assignmentSale_intro .avatar img {
        width: 65px;
        height: 65px;
        border-radius: 65px;
    }

    .assignmentSale_intro .avatar h5 {
        font-size: 5rem !important;
    }
}

@media(max-width: 512px) {
    .assignHome_PortCardImg {
        height: 180px !important;
    }

    .assignHome_landCardImg {
        height: 180px !important;
    }

    .notifyFunnels_container .trafficImg {
        height: 140px
    }

    .marketing_graph {
        height: 265px !important;
    }

    .marketing_customer h5 {
        font-size: 3.5rem;
    }

    .funnels_modals {
        min-width: 100% !important;
    }
}