.googleapi {
  width: 40%;
  height: 100%;
}

googleapi::-webkit-scrollbar {
  display: none;
  /* for Chrome, Safari, and Opera */
}

.map-container {
  width: 40%;
  background-color: aqua;
}

.check {
  background-color: black;
}

.mymapcareds {
  width: 320x !important;
}

.modalscroller {
  height: 700px;
  overflow-y: scroll;
}

.mapboxgl-popup-close-button {
  background-color: #ca0024 !important;
  color: aliceblue !important;
  font-size: 1.2rem !important;
  border-bottom-left-radius: 8px !important;
  position: absolute;
  /* top: 8px; */

}

.mapboxgl-popup-close-button:hover {
  background-color: #ca0024 !important;
  color: aliceblue !important;
  font-size: 1.2rem !important;

}

.mapboxgl-popup {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  will-change: transform;
  pointer-events: none;
  z-index: 2;
}

.map_lineLoader {
  width: 100%;
  height: 4.8px;
  display: inline-block;
  position: relative;
  background: rgba(228, 96, 8, 0.315);
  overflow: hidden;
}

.map_lineLoader::after {
  content: '';
  width: 40%;
  height: 4.8px;
  background: #ec830a;
  position: absolute;
  top: 0;
  left: 0;
  box-sizing: border-box;
  animation: animloader 2s linear infinite;
}

.mapbox_popCard {
  width: 300px;
  cursor: pointer;
}

.map_drawDel_Btn {
  position: absolute;
  border: none;
  outline: none;
  background-color: #ffff;
  border-radius: 6px;
  font-weight: 600;
  color: #7e7e7e;
  font-family: 'Montserrat';
  font-size: 16px;
  padding: 8px 10px;
  cursor: pointer;
  z-index: 100;
}

.map_draw_Btn {
  right: 10px;
  top: 10px;
}

.map_deleteBtn {
  top: 20px;
  left: 50%;
  transform: translateX(-50%);
}

@keyframes animloader {
  0% {
    left: 0;
    transform: translateX(-100%);
  }

  100% {
    left: 100%;
    transform: translateX(0%);
  }
}