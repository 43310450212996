.topContainer {
  background-color: #02549e;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.topHeading {
  font-family: Montserrat;
  color: #fff;
  font-size: 64px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.topHeadingPara {
  color: #f4f9ff;
  text-align: center;
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 170%;
}

.content_Heading {
  color: #000;
  font-family: Montserrat;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 170%;
  margin-top: 2rem;
}

.contentPara {
  color: #000;
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 170%;
}


.content_contaner{
  padding-left: 18px;
  padding-right: 18px;
  margin-top: 3rem;
}

@media (max-width: 800px) {

  .topHeading {
    font-size: 24px !important;
    text-align: center !important;
    margin-bottom: 1.5rem !important;
  }

  .topHeadingPara {
    font-size: 14px !important;
    line-height: 130% !important;
    text-align: left !important;
  }

  .content_Heading {
    font-size: 22px !important;
    margin-top: 2rem !important;
  }

  .contentPara {
    font-size: 14px !important;
    line-height: normal !important;
  }
}